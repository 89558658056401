<template>
  <div>
    <h2 class="content-block">Daftar Agenda {{ title }}</h2>

    <filter-table-unit-bisnis
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
      v-if="permissions.unfilteredData"
    ></filter-table-unit-bisnis>

    <div class="content-block">
      <div class="button-container">
        <DxButton
          class="custom-btn"
          text="Buat Agenda"
          @click="addAppontment"
          type="default"
          icon="add"
          v-if="permissions.canCreate"
        />
        <DxButton
          class="custom-btn"
          @click="refresh"
          type="default"
          icon="fa fa-arrows-rotate"
        />
      </div>
      <DxScheduler
        class="dx-card wide-card"
        :ref="schedulerRefKey"
        :time-zone="'Asia/Jakarta'"
        :data-source="dataSource"
        :current-date="currentDate"
        :views="views"
        :start-day-hour="7"
        current-view="views"
        :start-date-expr="'start_date'"
        :end-date-expr="'end_date'"
        @appointment-form-opening="onAppointmentFormOpening"
        @appointment-click="onClickAppointment"
        appointment-template="AppointmentTemplateSlot"
        appointment-tooltip-template="AppointmentTooltipTemplateSlot"
      >
        <DxEditing
          :allow-deleting="false"
          :allow-adding="false"
          :allow-updating="false"
          :allow-resizing="false"
          :allow-dragging="false"
        />

        <!-- page per day -->
        <template #AppointmentTemplateSlot="{ data }">
          <div
            :class="{
              'appointment-done':
                data.targetedAppointmentData.status &&
                (data.targetedAppointmentData.sla == 'on time' ||
                  data.targetedAppointmentData.sla == null),
              'appointment-planning':
                !data.targetedAppointmentData.status &&
                data.targetedAppointmentData.audit_category == 'Terbuka',
              'appointment-closed':
                !data.targetedAppointmentData.status &&
                data.targetedAppointmentData.audit_category == 'Tertutup',
              'appointment-done-delay':
                data.targetedAppointmentData.status &&
                data.targetedAppointmentData.sla == 'delay',
            }"
          >
            {{ data.targetedAppointmentData.agenda_type }} |
            {{ data.targetedAppointmentData.event_name }} |
            {{ data.targetedAppointmentData.status ? "Done" : "Planning" }}
          </div>
        </template>
        <!-- end page per day -->

        <!-- page toolTip -->
        <template #AppointmentTooltipTemplateSlot="{ data }">
          <appointmentTooltip
            :tooltipTemplate="data.appointmentData"
            marker-color="#337ab7"
          ></appointmentTooltip>
        </template>
        <!-- end page toolTip -->
      </DxScheduler>
    </div>

    <!-- popup Detail -->
    <DxPopup
      width="50%"
      :show-title="true"
      title="Detail Agenda"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupDetail"
    >
      <template #content="{}">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details container-detail">
            <div class="dx-fieldset">
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Tipe Agenda</div>
                  <div class="dx-field-value">
                    : {{ detailData.agenda_type }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Nama Event</div>
                  <div class="dx-field-value">
                    : {{ detailData.event_name }}
                  </div>
                </div>
                <div class="dx-field" v-if="disableAgendaAudit">
                  <div class="dx-field-label">Tipe</div>
                  <div class="dx-field-value">
                    : {{ detailData.audit_type }}
                  </div>
                </div>
              </div>
              <div
                class="dx-card responsive-paddings"
                v-if="disableAgendaAudit"
              >
                <div class="dx-field">
                  <div class="dx-field-label">Unit Bisnis</div>
                  <div class="dx-field-value">
                    : {{ detailData.company_name }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Department</div>
                  <div class="dx-field-value">
                    : {{ detailData.department_name }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Section</div>
                  <div class="dx-field-value">
                    : {{ detailData.section_name }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Lokasi</div>
                  <div class="dx-field-value">
                    : {{ detailData.branch_name }}
                  </div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Metode</div>
                  <div class="dx-field-value">: {{ detailData.method }}</div>
                </div>
                <div class="dx-field" v-if="disableAgendaAudit">
                  <div class="dx-field-label">Kriteria</div>
                  <div class="dx-field-value">
                    : {{ detailData.audit_criteria }}
                  </div>
                </div>
                <div class="dx-field" v-if="disableAgendaAudit">
                  <div class="dx-field-label">Peserta</div>
                  <div class="dx-field-value">
                    : {{ detailData.participant }}
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Tanggal</div>
                  <div class="dx-field-value">
                    : {{ formatIsoDate(detailData.start_date) }} -
                    {{ formatIsoDate(detailData.end_date) }}
                  </div>
                </div>
                <div class="dx-field" v-if="disableAgendaAudit">
                  <div class="dx-field-label">SLA</div>
                  <div class="dx-field-value">
                    :
                    {{ detailData.sla ? detailData.sla : "-" }}
                  </div>
                </div>
                <div class="dx-field" v-if="disableAgendaAudit">
                  <div class="dx-field-label">Kategori Audit</div>
                  <div class="dx-field-value">
                    :
                    {{ detailData.audit_category }}
                  </div>
                </div>
                <div class="dx-field" v-if="disableAgendaAudit">
                  <div class="dx-field-label">Status</div>
                  <div class="dx-field-value">
                    :
                    <div
                      :class="
                        detailData.status ? 'status-done' : 'status-planning'
                      "
                    >
                      {{ detailData.status ? "Done" : "Planning" }}
                    </div>
                  </div>
                </div>
                <div class="dx-field" v-if="disableAgendaAudit">
                  <div class="dx-field-label">Total Tipe Temuan</div>
                  <div class="dx-field-value">
                    <DxList
                      :data-source="detailData.finding_type_count"
                      item-template="list-item"
                    >
                      <template #list-item="{ data }">
                        <p>- {{ data.name }} : {{ data.total }}</p>
                      </template>
                    </DxList>
                  </div>
                </div>
              </div>
              <div
                class="dx-card responsive-paddings"
                v-if="!disableAgendaAudit"
              >
                <div class="dx-field">
                  <div class="dx-field-label">Anggota Meeting</div>
                  <div class="dx-field-value">
                    <div v-if="detailData.all">: All Unit &amp; Department</div>
                    <DxList
                      v-else
                      :data-source="detailData.participant_meeting"
                      item-template="list-item"
                    >
                      <template #list-item="{ data, index }">
                        <p>
                          <span>{{ index + 1 }}. </span>
                          {{ data.company_name }} |
                          {{ data.department_name }}
                        </p>
                      </template>
                    </DxList>
                  </div>
                </div>
              </div>
              <DxButton
                class="customBtn"
                text="Hapus"
                type="default"
                icon="trash"
                @click="onDestroy"
                v-if="permissions.canDelete"
              ></DxButton>

              <DxButton
                class="customBtn"
                type="default"
                text="Edit"
                icon="fa fa-pen-to-square"
                @click="edit"
                v-if="
                  (detailData.status == false && permissions.canUpdate) ||
                  (detailData.agenda_type == 'Agenda Meeting' &&
                    permissions.canUpdate)
                "
              ></DxButton>
              <DxButton
                class="customBtn"
                text="Verifikasi"
                type="default"
                icon="fa fa-calendar-check"
                @click="onVerification()"
                v-if="detailData.status == false && permissions.canVerified"
              ></DxButton>
            </div>
          </div>
        </DxScrollView>
      </template>
    </DxPopup>

    <!-- popup Delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButton
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButton
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import DxScheduler, {
  DxSchedulerTypes,
  DxEditing,
} from "devextreme-vue/scheduler";
import DxButton from "devextreme-vue/button";
import appointmentTooltip from "@/components/audit/appointmentTooltip.vue";
import notify from "devextreme/ui/notify";
import router from "@/router";
import DxPopup from "devextreme-vue/popup";
import { mapGetters, mapActions } from "vuex";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import { DxList, DxItem } from "devextreme-vue/list";
import { DxScrollView } from "devextreme-vue/scroll-view";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";

export default {
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 999999,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            // company: vthis.company || "",
            department: vthis.department || "",
          });

          return await datax;
        },
      }),
    };
    return {
      dataSource: dataSource,
      schedulerRefKey: "scheduler",
      views: ["month", "week", "day"],
      currentDate: new Date(),
      detailData: {},
      popupDelete: false,
      popupDetail: false,
      company: "",
      department: "",
      title: "",
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
    };
  },
  props: {},
  computed: {
    scheduler: function () {
      return this.$refs[this.schedulerRefKey].instance;
    },
    ...mapGetters("branch", ["getAllBranch"]),
    ...mapGetters("section", ["getAllSection"]),
    ...mapGetters("department", ["getAllDepartment"]),
    ...mapGetters("authentication", ["getUserDetail"]),
    disableAgendaAudit() {
      if (this.detailData.agenda_type == "Agenda Audit") {
        return true;
      }
      return false;
    },
  },
  created() {
    const permission = this.$route.meta.permission;
    // check permission
    this.permissions.canCreate = (permission & 2) > 0;
    this.permissions.canUpdate = (permission & 4) > 0;
    this.permissions.canVerified = (permission & 8) > 0;
    this.permissions.canDelete = (permission & 16) > 0;
    this.permissions.unfilteredData = (permission & 32) > 0;
  },
  methods: {
    generateDepartment(params) {
      // const departments = params.split(",").map((item) => item.trim());
      console.log(params);
    },
    formatIsoDate(isoDate) {
      if (isoDate) {
        const dateObject = new Date(isoDate);

        // Mendapatkan komponen tanggal
        const day = dateObject.getDate().toString().padStart(2, "0");
        const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Bulan dimulai dari 0
        const year = dateObject.getFullYear();

        // Mendapatkan jam dan menit
        const hours = dateObject.getHours().toString().padStart(2, "0");
        const minutes = dateObject.getMinutes().toString().padStart(2, "0");

        // Mengembalikan format tanggal jam:menit
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      }
    },
    getCompany(value) {
      this.company = value;
      this.scheduler.getDataSource().reload();
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        const depart = this.getAllDepartment;
        const departName = depart.find(
          (depart) => depart.department_id == value
        );
        this.title = departName.department_name;
      } else {
        this.title = "";
      }
      this.scheduler.getDataSource().reload();
    },
    refresh() {
      this.scheduler.getDataSource().reload();
    },
    async onClickAppointment(e) {
      e.cancel = true;

      this.detailData = e.appointmentData;
      if (e.appointmentData.agenda_type == "Agenda Audit") {
        const dep = this.getAllDepartment.find(
          (res) => res.department_id == e.appointmentData.department_id
        );
        this.detailData.company_name = dep.company_name;
        this.detailData.department_name = dep.department_name;

        if (e.appointmentData.section_id) {
          const sec = this.getAllSection.find(
            (res) => res.section_id == e.appointmentData.section_id
          );
          this.detailData.section_name = sec.section_name;
        }

        const bran = this.getAllBranch.find(
          (res) => res.location_id == e.appointmentData.branch_id
        );
        this.detailData.branch_name = bran.location_name;

        const findingTypeCount = await this.$store.dispatch(
          "auditReport/getFindingTypeCount",
          `?audit_plan=${e.appointmentData.id}`
        );
        this.detailData.finding_type_count = findingTypeCount.data;
      }

      if (e.appointmentData.meeting_participants) {
        const array = e.appointmentData.meeting_participants;

        const departments = array.split(",").map((item) => item.trim());
        let data = [];
        departments.forEach((item) => {
          data.push(
            this.getAllDepartment.find((res) => res.department_id == item)
          );
        });
        this.detailData.participant_meeting = data;
      }

      this.popupDetail = true;
    },
    confirmDestroy() {
      this.$store
        .dispatch("auditPlan/destroyAuditPlan", this.detailData)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.scheduler.getDataSource().reload();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    onEdit() {
      this.$store
        .dispatch("auditPlan/editAuditPlan", this.detailData)
        .then((res) => {
          this.scheduler.getDataSource().reload();
          notify("Berhasil", "success", 2000);
        })
        .catch((e) => {
          console.log(e);
          notify(e.message, "error", 2000);
          return;
        });
    },
    onVerification() {
      if (!this.detailData.status) {
        const start = new Date(this.detailData.start_date);
        const end = new Date(this.detailData.end_date);
        const now = new Date();

        const startDateOnly = new Date(start.setHours(0, 0, 0, 0));
        const endDateOnly = new Date(end.setHours(0, 0, 0, 0));
        const nowDateOnly = new Date(now.setHours(0, 0, 0, 0));

        if (nowDateOnly < startDateOnly) {
          notify("Agenda belum waktunya diverifikasi", "warning", 2000);
          this.popupDetail = false;
          return;
        } else if (nowDateOnly <= endDateOnly) {
          this.detailData.status = true;
          this.detailData.sla = "on time";
          this.onEdit();
        } else {
          this.detailData.status = true;
          this.detailData.sla = "delay";
          this.onEdit();
        }
      } else {
        notify("Agenda sudah diverifikasi", "warning", 2000);
      }

      this.popupDetail = false;
    },
    edit() {
      this.popupDetail = false;
      this.$store.dispatch("auditPlan/setAuditPlan", this.detailData);
      router.push(`/agenda/form/${this.detailData.id}`);
    },
    onDestroy() {
      this.popupDetail = false;
      this.popupDelete = true;
      this.deleteData = this.detailData;
    },
    addAppontment() {
      router.push(`/agenda/form`);
    },
    onAppointmentFormOpening: function (e) {
      e.cancel = true;
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      if (!this.permissions.unfilteredData) {
        params.agenda_plan = this.getUserDetail.department_id || "-";
        params.branch = this.getUserDetail.location_id || "-";
        params.section = this.getUserDetail.section_id || "-";
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "auditPlan/getAuditPlan",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
      };

      return await datax.data;
    },
  },
  components: {
    DxScheduler,
    DxSchedulerTypes,
    DxButton,
    DxEditing,
    appointmentTooltip,
    DxPopup,
    FilterTableUnitBisnis,
    DxList,
    DxItem,
    DxScrollView,
  },
};
</script>
<style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}

.custom-btn {
  margin-right: 10px;
}
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.appointment-done {
  background-color: #8bc34a;
  color: white;
}
.appointment-done-delay {
  background-color: #8bc34a;
  color: red;
}
.appointment-planning {
  background-color: #ffff00;
  color: black;
}
.appointment-closed {
  background-color: #c4c4c4;
  color: white;
}

.status-done {
  background-color: #8bc34a;
  color: white;
  font-weight: bold;
  text-align: end;
  border-radius: 25px;
  padding: 5px;
  display: inline-block;
  white-space: nowrap;
}
.status-planning {
  background-color: #ffff00;
  color: black;
  font-weight: bold;
  text-align: end;
  border-radius: 25px;
  padding: 5px;
  display: inline-block;
  white-space: nowrap;
}
.dx-list-item-content {
  white-space: normal;
}
.dx-field-label {
  white-space: normal;
}
</style>
