<template>
  <div>
    <h2 class="content-block">
      <span v-if="auditee">Penerima / Auditi </span
      ><span v-if="auditor">Inisiator / Auditor </span
      ><span v-if="history">Riwayat </span
      ><span v-if="permissions.unfilteredData && auditor">Daftar </span>Capa
      Umum {{ title }}
    </h2>
    <filter-table-unit-bisnis
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
      v-if="permissions.unfilteredData"
    ></filter-table-unit-bisnis>
    <div
      class="content-block dx-card responsive-paddings"
      style="justify-content: center; display: flex"
      v-if="permissions.unfilteredData"
    >
      <DxRadioGroup
        :items="statusCapaOptions"
        value-expr="key"
        display-expr="name"
        v-model:value="valueStatusCapa"
        layout="horizontal"
        @valueChanged="handleRefresh()"
      >
      </DxRadioGroup>
    </div>
    <div
      class="content-block dx-card responsive-paddings"
      style="justify-content: center; display: flex"
      v-if="checkAuditee"
    >
      <DxRadioGroup
        :items="statusAuditeeOptions"
        value-expr="key"
        display-expr="name"
        v-model:value="valueStatusAuditeeCapa"
        layout="horizontal"
        @valueChanged="handleRefresh()"
      >
      </DxRadioGroup>
    </div>
    <br />

    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :search-value="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          v-if="permissions.canUpdate && (auditor || history)"
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="fieldChooser"
        />
        <DxButton
          hint="view"
          icon="fa fa-book-open-reader"
          @click="viewDetail"
        />
        <DxButton
          v-if="permissions.canVerified && auditor"
          hint="verifikasi auditor"
          icon="fa fa-square-check"
          @click="auditorVerif"
        />"
        <DxButton
          v-if="permissions.canVerified && auditee"
          hint="verifikasi auditee"
          icon="fa fa-check-to-slot"
          @click="auditeeApproval"
        />"
        <DxButton
          hint="hapus"
          icon="trash"
          @click="popupDestroy"
          v-if="permissions.canDelete && (auditor || history)"
        />
      </dx-column>
      <dx-column :width="150" data-field="document_number" caption="No Capa" />
      <dx-column
        data-field="capa_date"
        caption="Tanggal Capa"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="process_name"
        caption="Proses"
        width="400"
        cell-template="process-name"
      />
      <template #process-name="{ data }">
        <div class="wrap-text">
          {{ data.value }}
        </div>
      </template>
      <dx-column data-field="auditor.full_name" caption="Inisiator/Auditor" />
      <dx-column
        data-field="nc_source"
        caption="Sumber Non Conformance"
        :cell-template="ncSourceColumn"
        width="400"
      />
      <dx-column data-field="finding_type" caption="Jenis Temuan" />
      <dx-column data-field="auditee.full_name" caption="Penerima / Auditi" />
      <dx-column
        data-field="finding_status"
        caption="Status Temuan"
        cell-template="finding-status"
      />
      <template #finding-status="{ data }">
        <div
          :class="{
            skyBlue: data.value == 'Info',
            red: data.value == 'NC',
            yellow: data.value == 'Obs',
          }"
        >
          {{ data.value }}
        </div>
      </template>
      <dx-column
        data-field="capa_status"
        caption="Status Capa"
        cell-template="capa-status"
      />
      <template #capa-status="{ data }">
        <div
          :class="{
            green: data.value == 'Closed',
            yellow: data.value == 'Pending' || data.value == 'Checking',
            red: data.value == 'Open' || data.value == 'Dikembalikan',
          }"
        >
          {{ data.value }}
        </div>
      </template>

      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: capa }">
        <masterDetailCapa
          :auditor="auditor"
          :auditee="auditee"
          :history="history"
          :capaProps="capa.data"
          @refresh-capa="handleRefresh"
        ></masterDetailCapa>
      </template>
    </dx-data-grid>

    <!-- popup auditor verif -->
    <DxPopup
      width="50%"
      height="auto"
      :show-title="true"
      title="Verifikasi Laporan Capa Umum"
      :drag-enabled="false"
      v-model:visible="popupAuditorVerif"
    >
      <template #content="{}">
        <b>Pastikan laporan capa sudah dicek!</b>
        <div class="popup-property-details">
          <div>
            <form action="/verifAuditor" @submit.prevent="confirmVerifAuditor">
              <DxSelectBox
                class="custom-form"
                :data-source="statusOptions"
                label="Pilih Status"
                v-model="dataAuditorVerif.capa_status"
              >
                <DxValidator>
                  <DxRequiredRule message="Required" />
                </DxValidator>
              </DxSelectBox>
              <DxTextArea
                class="custom-form"
                label="Alasan"
                :height="90"
                :input-attr="{ 'aria-label': 'Notes' }"
                v-model:value="dataAuditorVerif.capa_notes"
              >
                <DxValidator>
                  <DxRequiredRule message="Required" />
                </DxValidator>
              </DxTextArea>
              <div class="justify-center">
                <DxButtonx
                  class="customBtn"
                  text="Simpan"
                  icon="save"
                  type="success"
                  :use-submit-behavior="true"
                />
                <DxButtonx
                  class="customBtn"
                  text="Batal"
                  icon="close"
                  type="danger"
                  @click="cancle"
                />
              </div>
            </form>
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup detail -->
    <DxPopup
      width="60%"
      height="80%"
      :show-title="true"
      title="Detail Laporan Audit"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupDetail"
    >
      <template #content="{}">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">
            <div class="dx-fieldset">
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Nomor Capa</div>
                  <div class="dx-field-value">
                    : {{ detailData.document_number }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal Laporan</div>
                  <div class="dx-field-value">: {{ detailData.capa_date }}</div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Proses</div>
                  <div class="dx-field-value">
                    : {{ detailData.process_name }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Inisiator / Auditor</div>
                  <div class="dx-field-value">
                    : {{ detailData.auditor.full_name }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Sumber Non Conformance</div>
                  <div class="dx-field-value">
                    :
                    {{
                      detailData.nc_source
                        ? detailData.nc_source
                        : detailData.nc_source_other
                    }}
                  </div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">
                    Detail Masalah /Rincian Temuan
                  </div>
                  <div
                    class="dx-field-value"
                    style="padding: 0px; margin: 0px"
                    v-html="detailData.finding_detail"
                  ></div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tipe Temuan</div>
                  <div class="dx-field-value">
                    : {{ detailData.finding_type }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Penerima / Auditi</div>
                  <div class="dx-field-value">
                    : {{ detailData.auditee.full_name }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Status Temuan</div>
                  <div class="dx-field-value">
                    :
                    <span
                      :class="{
                        skyBlue: detailData.finding_status == 'Info',
                        red: detailData.finding_status == 'NC',
                        yellow: detailData.finding_status == 'Obs',
                      }"
                      >{{ detailData.finding_status }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">
                    Tindankan Perbaikan Sementara / (Correction)
                  </div>
                  <div class="dx-field-value">
                    <DxList
                      :data-source="capaTemporalCorrection"
                      item-template="list-item"
                    >
                      <template #list-item="{ data, index }">
                        <div>
                          <span>{{ index + 1 }}. </span>{{ data.action }}
                        </div>
                      </template>
                    </DxList>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal Pelaksanaan</div>
                  <div class="dx-field-value">
                    : {{ detailData.temporal_correction_date }}
                  </div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">
                    Analisa Akar Masalah / (Root Cause Analysis)
                  </div>
                  <div class="dx-field-value">
                    <DxList
                      :data-source="capaRootCouse"
                      item-template="list-item"
                    >
                      <template #list-item="{ data, index }">
                        <div>
                          <span>{{ index + 1 }}. </span>{{ data.action }}
                        </div>
                      </template>
                    </DxList>
                  </div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">
                    Tindankan Perbaikan Permanen / (Correction Action)
                  </div>
                  <div class="dx-field-value">
                    <DxList
                      :data-source="capaCorrectionAction"
                      item-template="list-item"
                    >
                      <template #list-item="{ data, index }">
                        <div>
                          <span>{{ index + 1 }}. </span>{{ data.action }}
                        </div>
                      </template>
                    </DxList>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal Rencana CA</div>
                  <div class="dx-field-value">
                    : {{ detailData.start_correction_date }} s/d
                    {{ detailData.end_correction_date }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">PIC</div>
                  <div class="dx-field-value">
                    : {{ detailData.correction_pic }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Approval</div>
                  <div class="dx-field-value">
                    : {{ detailData.correction_sign }}
                  </div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div style="text-align: center; font-weight: bold; margin: 5px">
                  <span>Verifikasi</span>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Diverifikasi Oleh</div>
                  <div class="dx-field-value">: {{ detailData.verified }}</div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal</div>
                  <div class="dx-field-value">
                    : {{ detailData.verified_date }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Status Capa</div>
                  <div class="dx-field-value">
                    :
                    <span
                      :class="{
                        green: detailData.capa_status == 'Closed',
                        yellow:
                          detailData.capa_status == 'Pending' ||
                          detailData.capa_status == 'Checking',
                        red:
                          detailData.capa_status == 'Open' ||
                          detailData.capa_status == 'Dikembalikan',
                      }"
                      >{{ detailData.capa_status }}</span
                    >
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Catatan Capa</div>
                  <div class="dx-field-value">
                    : {{ detailData.capa_notes }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DxScrollView>
      </template>
    </DxPopup>

    <!-- popup verif approval -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :show-close-button="true"
      v-model:visible="popupApproval"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Pastikan laporan capa sudah dicek !</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="Terima"
              icon="check"
              type="success"
              @click="confirmApproval('Checking')"
            />
            <DxButtonx
              class="customBtn"
              text="tolak"
              icon="close"
              type="danger"
              @click="confirmApproval('Open')"
            />
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
        <script>
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
  DxColumnFixing,
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxList, DxItem } from "devextreme-vue/list";
import masterDetailCapa from "@/components/capa/masterDetailCapa.vue";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import DxRadioGroup from "devextreme-vue/radio-group";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
    DxScrollView,
    DxList,
    DxItem,
    masterDetailCapa,
    FilterTableUnitBisnis,
    DxSelectBox,
    DxTextArea,
    DxValidator,
    DxRequiredRule,
    DxRadioGroup,
    DxColumnFixing,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;
          const unfilteredData = vthis.permissions.unfilteredData;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            company: vthis.company || "",
            department: vthis.department || "",
            capa_status: unfilteredData ? vthis.valueStatusCapa : "",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "audit-checklist-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      btnAdd: null,
      popupDetail: false,
      popupDelete: false,
      popupVerif: false,
      popupAuditeeVerif: false,
      popupAuditorVerif: false,
      popupApproval: false,
      detailData: {},
      deleteData: {},
      verifData: {},
      capaTemporalCorrection: [],
      capaRootCouse: [],
      capaCorrectionAction: [],
      company: "",
      department: "",
      title: "",
      statusOptions: ["Dikembalikan", "Closed"],
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
      dataAuditorVerif: {},
      dataAuditeeVerif: {},
      dataApproval: {},
      valueStatusCapa: "Closed",
      statusCapaOptions: [
        { name: "Riwayat Daftar Capa", key: "Closed" },
        {
          name: "Daftar Capa Progress",
          key: "Open,Dikembalikan,Pending,Checking",
        },
      ],
      statusAuditeeOptions: [
        {
          name: "Auditee Saya",
          key: "my_auditee",
        },
        { name: "Verifikasi Auditee", key: "my_verified" },
      ],
      valueStatusAuditeeCapa: "my_auditee",
    };
  },
  created() {
    const permission = this.$route.meta.permission;
    // check permission
    this.permissions.canCreate = (permission & 2) > 0;
    this.permissions.canUpdate = (permission & 4) > 0;
    this.permissions.canVerified = (permission & 8) > 0;
    this.permissions.canDelete = (permission & 16) > 0;
    this.permissions.unfilteredData = (permission & 32) > 0;
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("auditPlan", ["getAuditPlan"]),
    ...mapGetters("department", ["getAllDepartment"]),
    ...mapGetters("authentication", ["getUserDetail"]),
    checkAuditee() {
      if (
        this.permissions.canVerified &&
        this.auditee &&
        !this.permissions.unfilteredData
      ) {
        return true;
      }
      return false;
    },
  },
  props: {
    auditee: {
      type: Boolean,
      default: false,
    },
    auditor: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleRefresh(params) {
      this.dataGrid.refresh();
    },
    dateColumn(cellInfo) {
      if (!cellInfo.value) {
        return "-";
      }
      const date = new Date(cellInfo.value);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "-" + mm + "-" + date.getFullYear();
    },
    ncSourceColumn(container, options) {
      if (options.data.nc_source) {
        return (container.innerHTML = `<div style="white-space: normal;">${options.data.nc_source}</div>`);
      } else {
        return (container.innerHTML = `<div style="white-space: normal;">${options.data.nc_source_other}</div>`);
      }
    },
    cancle() {
      this.dataAuditorVerif = {};
      this.popupAuditorVerif = false;
    },
    editCapa(params) {
      this.$store
        .dispatch("capa/editCapa", params)
        .then((res) => {
          notify("verifikasi success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
    },
    confirmApproval(value) {
      if (value == "Checking") {
        this.dataApproval.correction_sign = this.getUserDetail.full_name;
      }
      this.dataApproval.capa_status = value;

      this.editCapa(this.dataApproval);
      this.popupApproval = false;
    },
    auditeeApproval(params) {
      if (params.row.data.capa_status != "Pending") {
        notify("tunggu laporan capa di proses auditee", "warning", 2000);
        return;
      }
      this.popupApproval = true;
      this.dataApproval = params.row.data;
    },
    confirmVerifAuditor() {
      if (this.dataAuditorVerif.capa_status == "Dikembalikan") {
        this.dataAuditorVerif.temporal_correction_date = null;
        this.dataAuditorVerif.start_correction_date = null;
        this.dataAuditorVerif.end_correction_date = null;
        this.dataAuditorVerif.correction_pic = null;
        this.dataAuditorVerif.correction_sign = null;
      } else if (this.dataAuditorVerif.capa_status == "Closed") {
        this.dataAuditorVerif.verified = this.getUserDetail.full_name;
        this.dataAuditorVerif.verified_date = new Date();
      }
      this.editCapa(this.dataAuditorVerif);

      this.popupAuditorVerif = false;
    },
    auditorVerif(params) {
      if (params.row.data.capa_status != "Checking") {
        notify("tunggu laporan capa di proses auditee", "warning", 2000);
        return;
      }
      this.dataAuditorVerif = params.row.data;
      this.popupAuditorVerif = true;
    },
    getCompany(value) {
      this.company = value;
      this.dataGrid.refresh();
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        const depart = this.getAllDepartment;
        const departName = depart.find(
          (depart) => depart.department_id == value
        );
        this.title = departName.department_name;
      } else {
        this.title = "";
      }
      this.dataGrid.refresh();
    },
    viewDetail(params) {
      this.popupDetail = true;

      this.detailData = params.row.data;

      this.getRelationCapa(params.row.data.id);
    },
    async getRelationCapa(params) {
      const getCapaTemporalCorrection = await this.$store.dispatch(
        "capaTemporalCorrection/getCapaTemporalCorrection",
        `?limit=999&capa=${params}`
      );
      this.capaTemporalCorrection = getCapaTemporalCorrection.data;

      const getCapaRootCouse = await this.$store.dispatch(
        "capaRootCause/getCapaRootCause",
        `?limit=999&capa=${params}`
      );
      this.capaRootCouse = getCapaRootCouse.data;

      const getCapaCorrectionAction = await this.$store.dispatch(
        "capaCorrectionAction/getCapaCorrectionAction",
        `?limit=999&capa=${params}`
      );
      this.capaCorrectionAction = getCapaCorrectionAction.data;
    },
    dateColumn(cellInfo) {
      if (cellInfo.value == null) {
        return "-";
      }
      const date = new Date(cellInfo.value);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "-" + mm + "-" + date.getFullYear();
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    confirmDestroy() {
      this.$store
        .dispatch("auditChecklist/destroyAuditChecklist", this.deleteData)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteData = params.row.data;
    },
    fieldChooser(params) {
      this.$store.dispatch("capa/setCapa", params.row.data);
      router.push(`/capa/form/${params.row.key}`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      if (this.permissions.canCreate && (this.auditor || this.history)) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              router.push(`/capa/form`);
            },
          },
        });
      }
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      if (this.permissions.unfilteredData == false) {
        if (this.auditor) {
          params.my_auditor = this.getUserDetail.user_id;
          this.title = this.getUserDetail.full_name;
        } else if (this.auditee) {
          if (
            this.checkAuditee &&
            this.valueStatusAuditeeCapa == "my_verified"
          ) {
            params.my_verified = this.getUserDetail.department_id;
            this.title = this.getUserDetail.department_name;
          } else {
            params.my_auditee = this.getUserDetail.user_id;
            this.title = this.getUserDetail.full_name;
          }
        } else if (this.history) {
          params.my_history = this.getUserDetail.department_id;
          this.title = this.getUserDetail.department_name;
        }
      }

      let text = "?";

      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "capa/getCapa",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>
        <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}

.dx-list-item-content {
  white-space: normal;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.green {
  background-color: #8bc34a;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.red {
  background-color: red;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.yellow {
  background-color: #ffff00;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.array-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  white-space: normal;
  overflow-wrap: break-word;
}

.array-list li {
  margin: 0;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}
.img-custom {
  width: 50%;
  height: auto;
}
.img-custom:hover {
  width: 100%;
  height: auto;
}
.wrap-text {
  white-space: normal;
  overflow-wrap: break-word;
}
.img-center {
  justify-content: center;
  display: flex;
}
.skyBlue {
  background-color: #8db4e2;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.dx-field-label {
  white-space: normal;
}
</style>