<template>
  <div>
    <h2 class="content-block">Daftar Akun Tidak Aktif</h2>
    <tableAccount :noActive="true" :role="'suAdmin'"></tableAccount>
  </div>
</template>
        
  <script>
import tableAccount from "@/components/master/tableAccount.vue";

export default {
  data() {
    return {};
  },
  mounted() {},
  props: {},
  methods: {},
  components: {
    tableAccount,
  },
};
</script>