import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            docAccess: null,
            docAccessStatus: null
        }
    },
    mutations: {
        setDocAccess(state, payload) {
            state.docAccess = payload
        },
        setDocAccessStatus(state, payload) {
            state.docAccessStatus = payload
        },
    },
    actions: {
        setDocAccess({ commit }, params) {
            commit('setDocAccess', params)
        },
        setDocAccessStatus({ commit }, params) {
            commit('setDocAccessStatus', params)
        },
        async getDocAccess({ commit, state }, params) {
            return await Api()
                .get(`doc-access-rights`).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        // async getDocuments({ commit, state }, params) {
        //     return await Api()
        //         .get(`documents${params}`).then((res) => {
        //             return res.data
        //         }).catch((err) => {
        //             console.log(err.message);
        //         });
        // },
        // async getWithDocApproval({ commit, state }, params) {
        //     return await Api()
        //         .get(`documents/verif-document${params}`).then((res) => {
        //             return res.data
        //         }).catch((err) => {
        //             console.log(err.message);
        //         });
        // },
        // async getDocumentByDocType({ commit, state }, params) {
        //     console.log(params);
        //     return await Api()
        //         .get(`documents/doc-type${params}`).then((res) => {
        //             return res.data
        //         }).catch((err) => {
        //             console.log(err.message);
        //         });
        // },
        // async getRequestApprove({ }, params) {
        //     console.log(params);
        //     return await Api()
        //         .get(`request-documents/approve?page=${params.page}&limit=${params.perPage}&sorted_by=${params.sortedBy}&sort=${params.sort}&search=${params.filterValue}&applicant_id=${params.applicant_id}`).then((res) => {
        //             return res.data
        //         }).catch((err) => {
        //             console.log(err.message);
        //         });
        // },
        // async getRequestHistory({ }, params) {
        //     return await Api()
        //         .get(`request-documents/history?page=${params.page}&limit=${params.perPage}&sorted_by=${params.sortedBy}&sort=${params.sort}&search=${params.filterValue}&applicant_id=${params.applicant_id}`).then((res) => {
        //             return res.data
        //         }).catch((err) => {
        //             console.log(err.message);
        //         });
        // },
        async postDocAccess({ }, params) {
            const now = new Date();
            return await Api()
                .post("doc-access-rights", params,);
        },
        // async updateDocAccess({ }, params) {
        //     return await Api()
        //         .put(`documents/${params.id}`, params,);
        // },
    },
    getters: {
        getDocAccess(state) {
            return state.docAccess;
        },
        getDocAccessStatus(state) {
            return state.docAccessStatus;
        },
    }
}