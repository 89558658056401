import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            riskControlItem: null,
            riskControlItemStatus: null,
        }
    },
    mutations: {
        setRiskControlItem(state, payload) {
            state.riskControlItem = payload
        },
        setRiskControlItemStatus(state, payload) {
            state.riskControlItemStatus = payload
        },
    },
    actions: {
        setRiskControlItem({ commit }, params) {
            commit('setRiskControlItem', params)
        },
        setRiskControlItemStatus({ commit }, params) {
            commit('setRiskControlItemStatus', params)
        },
        async getRiskControlItem({ commit, state }, params) {
            return await Api().get(
                `risk-control-items${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRiskControlItem({ commit, state }, params) {
            return await Api().post(
                `risk-control-items`, params
            ).then((res) => {
                return res.data
            })
        },
        async editRiskControlItem({ commit, state }, params) {
            return await Api().put(
                `risk-control-items/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyRiskControlItem({ commit, state }, params) {
            return await Api().delete(
                `risk-control-items/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getRiskControlItem(state) {
            return state.riskControlItem;
        },
        getRiskControlItemStatus(state) {
            return state.riskControlItemStatus;
        },
    }
}