<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskControlItem" @submit.prevent="handleSubmit">
        <DxTextBox
          class="custom-form"
          label="Nama"
          v-model:value="item.name"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>

        <DxSelectBox
          class="custom-form"
          :data-source="typeOptions"
          label="Pilih Tipe"
          value-expr="name"
          display-expr="name"
          v-model="item.type"
          :is-required="true"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextArea
          class="custom-form"
          label="Deskripsi"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="item.description"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
        
  <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      submitButtonOptions: {
        text: "Submit the Form",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      item: {},
      typeOptions: [
        {
          name: "Pengendalian Risiko",
        },
        {
          name: "Keparahan Risiko",
        },
      ],
    };
  },
  mounted() {
    if (this.getRiskControlItemStatus.action == "Edit") {
      this.item = this.getRiskControlItem;
    }
  },
  emits: ["get-risk-control-item"],
  computed: {
    ...mapGetters("riskControlItem", [
      "getRiskControlItem",
      "getRiskControlItemStatus",
    ]),
  },
  methods: {
    handleSubmit(e) {
      this.loading = true;
      this.$emit("get-risk-control-item", this.item);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    FilterTableUnitBisnis,
    DxLoadIndicator,
  },
};
</script>
  
  <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>