<template>
  <form action="post-action" @submit.prevent="handleSubmit" :ref="formRef">
    <div class="fileuploader-container custom-form">
      <DxFileUploader
        :multiple="true"
        select-button-text="Pilih Dokumen"
        label-text="Unggah Dokumen Regulasi"
        :allowed-file-extensions="['.docx', '.doc', '.pdf']"
        :max-file-size="5500000"
        upload-mode="useForm"
        @value-changed="getFile"
        :validation-status="validationStatus"
        :validation-errors="validationErrors"
      >
        <DxValidator v-if="!edit">
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxFileUploader>
    </div>
    <div style="text-align: right; margin-bottom: 10px">
      *Maximum file size:
      <span>5 MB</span>
    </div>

    <DxButton
      v-if="!loading"
      text="Simpan Data"
      type="success"
      icon="save"
      :use-submit-behavior="true"
    />
  </form>
</template>
  <script>
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import DxTextArea from "devextreme-vue/text-area";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxDateBox from "devextreme-vue/date-box";
import { DxTextBox } from "devextreme-vue/text-box";
import { mapGetters, mapActions } from "vuex";

import axios from "axios";

export default {
  components: {
    DxButton,
    DxValidator,
    DxRequiredRule,
    DxTextArea,
    DxFileUploader,
    DxDateBox,
    DxTextBox,
  },
  data() {
    return {
      id: this.$route.params.id,
      documentFile: {},
      file: "",
      formRef: "form-document-file",
      loading: false,
      validationStatus: "valid",
      validationErrors: [],
      isValid: "",
    };
  },
  emits: ["get-regulation-doc"],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.getRegulationDocStatus.action == "Edit") {
      this.documentFile = this.getRegulationDoc;
    }
  },
  computed: {
    ...mapGetters("regulationList", ["getRegulationList"]),
    ...mapGetters("regulationDoc", [
      "getRegulationDocStatus",
      "getRegulationDoc",
    ]),
  },
  methods: {
    async handleSubmit() {
      if (this.getRegulationDocStatus.action == "Add") {
        this.documentFile.regulation_id = this.getRegulationList.id;
        this.documentFile.regulation_file = this.file;
      } else {
        if (this.file) {
          this.documentFile.regulation_file = this.file;
        }
        this.documentFile._method = "PUT";
      }
      this.loading = true;
      this.$emit("get-regulation-doc", this.documentFile);
    },
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];
      }
    },
    async getDocFile() {
      let results = await this.$store.dispatch(
        "regulationDoc/getRegulationDocument",
        {
          id: this.id,
        }
      );
      this.documentFile = results.data;
    },
  },
};
</script>
  
  <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
.main-block {
  max-width: 900px;
  min-width: 700px;
}
</style>