<template>
  <div>
    <DxTabPanel>
      <DxItem
        title="Tindakan Perbaikan Sementara"
        template="capa-temporal-correction"
      />
      <template #capa-temporal-correction>
        <tableCapaTemporalCorrection
          :auditor="auditor"
          :auditee="auditee"
          :history="history"
          :capa="capaProps"
        ></tableCapaTemporalCorrection>
      </template>

      <DxItem title="Analisa Akar Masalah" template="capa-root-cause" />
      <template #capa-root-cause>
        <tableCapaRootCause
          :auditor="auditor"
          :auditee="auditee"
          :history="history"
          :capa="capaProps"
        ></tableCapaRootCause>
      </template>

      <DxItem
        title="Tindakan Perbaikan Permanen"
        template="capa-correction-action"
      />
      <template #capa-correction-action>
        <tableCapaCorrectionAction
          :auditor="auditor"
          :auditee="auditee"
          :history="history"
          :capa="capaProps"
        ></tableCapaCorrectionAction>
      </template>
      <DxItem title="Form Perencanaan" template="form-date" />
      <template #form-date>
        <div class="table-left">
          <div class="content-block dx-card responsive-paddings">
            <form action="/capaRootCause" @submit.prevent="handleSubmit">
              <DxDateBox
                class="custom-form"
                label="Tanggal Pelaksanaan Perbaikan Sementara"
                :input-attr="{ 'aria-label': 'Date' }"
                type="date"
                :min="minDate"
                v-model:value="capa.temporal_correction_date"
                :read-only="checkForm"
              >
                <DxValidator>
                  <DxRequiredRule message="Required" />
                </DxValidator>
              </DxDateBox>

              <DxDateBox
                class="custom-form"
                label="Tanggal Mulai Rencana CA"
                :input-attr="{ 'aria-label': 'Date' }"
                type="date"
                :min="minDate"
                v-model:value="capa.start_correction_date"
                :read-only="checkForm"
              >
                <DxValidator>
                  <DxRequiredRule message="Required" />
                </DxValidator>
              </DxDateBox>

              <DxDateBox
                class="custom-form"
                label="Tanggal Akhir Rencana CA"
                :input-attr="{ 'aria-label': 'Date' }"
                type="date"
                :min="minDate"
                v-model:value="capa.end_correction_date"
                :read-only="checkForm"
              >
                <DxValidator>
                  <DxRequiredRule message="Required" />
                </DxValidator>
              </DxDateBox>

              <div v-if="permissions.canCreate && (auditee || history)">
                <DxButton
                  v-if="!loading"
                  text="Ajukan Capa"
                  type="success"
                  icon="save"
                  :use-submit-behavior="true"
                />
                <DxLoadIndicator v-else />
              </div>
            </form>
          </div>
        </div>
      </template>
    </DxTabPanel>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";
import DxDateBox from "devextreme-vue/date-box";
import tableCapaCorrectionAction from "@/components/capa/tableCapaCorrectionAction.vue";
import tableCapaTemporalCorrection from "@/components/capa/tableCapaTemporalCorrection.vue";
import tableCapaRootCause from "@/components/capa/tableCapaRootCause.vue";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

export default {
  components: {
    DxTabPanel,
    DxItem,
    DxDateBox,
    DxButton,
    tableCapaCorrectionAction,
    tableCapaTemporalCorrection,
    tableCapaRootCause,
    DxLoadIndicator,
    DxValidator,
    DxRequiredRule,
  },
  data() {
    return {
      capa: {},
      disableForm: true,
      loading: false,
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
      minDate: new Date(),
    };
  },
  computed: {
    ...mapGetters("authentication", ["getUserDetail"]),
    checkForm() {
      if (this.permissions.canCreate && (this.auditee || this.history)) {
        return false;
      }
      return true;
    },
  },
  created() {
    const permission = this.$route.meta.permission;
    // check permission
    this.permissions.canCreate = (permission & 2) > 0;
    this.permissions.canUpdate = (permission & 4) > 0;
    this.permissions.canVerified = (permission & 8) > 0;
    this.permissions.canDelete = (permission & 16) > 0;
    this.permissions.unfilteredData = (permission & 32) > 0;
  },
  mounted() {
    this.capa = this.capaProps;
    // console.log(this.auditee);
  },
  props: {
    history: {
      type: Boolean,
      default: false,
    },
    auditee: {
      type: Boolean,
      default: false,
    },
    auditor: {
      type: Boolean,
      default: false,
    },
    capaProps: {
      type: Object,
    },
  },
  emits: ["refresh-capa"],
  methods: {
    editCapa(params) {
      const vThis = this;
      this.$store
        .dispatch("capa/editCapa", params)
        .then((res) => {
          notify("simpan success, tunggu konfirmasi auditor", "success", 2000);
          vThis.$emit("refresh-capa", res.data);
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
    },
    async handleSubmit(e) {
      this.loading = true;
      this.capa.capa_status = "Pending";
      this.capa.correction_pic = this.getUserDetail.full_name;

      console.log(this.capa, "save");
      this.editCapa(this.capa);
    },
  },
};
</script>
<style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.table-left {
  width: 40%;
  margin: left;
}
</style>