<template>
  <div class="widget-container">
    <h2 class="content-block">
      {{
        getRegulationListStatus == "Add" ? "Membuat" : getRegulationListStatus
      }}
      Daftar Regulasi Baru
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <div class="content-block dx-card responsive-paddings">
      <form-regulation-list @get-regulation-list="saveData" />
    </div>
  </div>
</template>
  <script>
import DxButton from "devextreme-vue/button";
import formRegulationList from "@/components/regulation/formRegulationList.vue";
import notify from "devextreme/ui/notify";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formRegulationList,
    DxButton,
  },
  computed: {
    ...mapGetters("regulationList", ["getRegulationListStatus"]),
  },
  methods: {
    async saveData(params) {
      if (this.getRegulationListStatus == "Add") {
        this.$store
          .dispatch("regulationList/postRegulationLists", params)
          .then((res) => {
            this.$store.dispatch(
              "regulationList/setRegulationList",
              res.data.data
            );
            this.$store.dispatch("regulationDoc/setRegulationDocStatus", "Add");
            notify("berhasil", "success", 2000);
            router.push(`/super-admin/regulation-document/form`);
          })
          .catch((e) => {
            console.log(e);
            notify("Error", "error", 2000);
          });
      } else if (this.getRegulationListStatus == "Edit") {
        this.$store
          .dispatch("regulationList/updateRegulationLists", params)
          .then((res) => {
            notify("berhasil", "success", 2000);
            // harusnya ke form regulation doc
            router.push(`/super-admin/regulation/info`);
          })
          .catch((e) => {
            console.log(e);
            notify("Error", "error", 2000);
          });
      } else {
        notify("Error", "error", 2000);
        return;
      }
    },
  },
};
</script>