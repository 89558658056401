<template>
  <div>
    <h2 class="content-block">
      {{ getRiskIdentificationStatus.action == "Add" ? "Membuat" : "Edit" }}
      Identifikasi Risiko
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formRiskIdentification
      @get-identification="submit"
    ></formRiskIdentification>
  </div>
</template>
        <script>
import formRiskIdentification from "@/components/riskManagement/formRiskIdentification.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formRiskIdentification,
    DxButton,
  },
  computed: {
    ...mapGetters("riskIdentification", ["getRiskIdentificationStatus"]),
    direct() {
      if (this.getRiskIdentificationStatus.roles == "user") {
        router.push(`/user/risk-identification/info`);
      } else if (this.getRiskIdentificationStatus.roles == "suAdmin") {
        router.push(`/super-admin/risk-identification/info`);
      } else {
        console.log("error");
      }
    },
  },
  methods: {
    async submit(value) {
      const vThis = this;
      if (this.getRiskIdentificationStatus.action == "Add") {
        this.$store
          .dispatch("riskIdentification/postRiskIdentification", value)
          .then((res) => {
            vThis.direct;
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getRiskIdentificationStatus.action == "Edit") {
        this.$store
          .dispatch("riskIdentification/editRiskIdentification", value)
          .then((res) => {
            vThis.direct;
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>