import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            regulationDoc: null,
            regulationDocStatus: null,
        }
    },
    mutations: {
        setRegulationDoc(state, payload) {
            state.regulationDoc = payload
        },
        setRegulationDocStatus(state, payload) {
            state.regulationDocStatus = payload
        },
    },
    actions: {
        setRegulationDoc({ commit }, params) {
            commit('setRegulationDoc', params)
        },
        setRegulationDocStatus({ commit }, params) {
            commit('setRegulationDocStatus', params)
        },
        async getRegulationDocuments({ commit, state }, params) {
            return await Api().get(
                `regulation-documents${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getRegulationDocument({ commit, state }, params) {
            return await Api().get(
                `regulation-documents/${params.id}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRegulationDocuments({ }, params) {
            return await Api().post(
                `regulation-documents`, params, {
                headers: { "Content-Type": "multipart/form-data" },
            }
            )
        },
        async updateRegulationDocuments({ }, params) {
            return await Api().post(
                `regulation-documents/${params.id}`, params, {
                headers: { "Content-Type": "multipart/form-data" },
            }
            )
        },
        async destroyRegulationDocuments({ }, params) {
            return await Api().delete(
                `regulation-documents/${params.id}`
            )
        },
    },
    getters: {
        getRegulationDoc(state) {
            return state.regulationDoc;
        },
        getRegulationDocStatus(state) {
            return state.regulationDocStatus;
        },
    }
}