import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            capaTemporalCorrection: null,
            capaTemporalCorrectionStatus: null,
        }
    },
    mutations: {
        setCapaTemporalCorrection(state, payload) {
            state.capaTemporalCorrection = payload
        },
        setCapaTemporalCorrectionStatus(state, payload) {
            state.capaTemporalCorrectionStatus = payload
        },
    },
    actions: {
        setCapaTemporalCorrection({ commit }, params) {
            commit('setCapaTemporalCorrection', params)
        },
        setCapaTemporalCorrectionStatus({ commit }, params) {
            commit('setCapaTemporalCorrectionStatus', params)
        },
        async getCapaTemporalCorrection({ commit, state }, params) {
            return await Api().get(
                `capa-temporal-corrections${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postCapaTemporalCorrection({ commit, state }, params) {
            return await Api().post(
                `capa-temporal-corrections`, params
            ).then((res) => {
                return res.data
            })
        },
        async editCapaTemporalCorrection({ commit, state }, params) {
            return await Api().put(
                `capa-temporal-corrections/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyCapaTemporalCorrection({ commit, state }, params) {
            return await Api().delete(
                `capa-temporal-corrections/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getCapaTemporalCorrection(state) {
            return state.capaTemporalCorrection;
        },
        getCapaTemporalCorrectionStatus(state) {
            return state.capaTemporalCorrectionStatus;
        },
    }
}