import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            riskImpPlan: null,
            riskImpPlanStatus: null,
        }
    },
    mutations: {
        setRiskImpPlan(state, payload) {
            state.riskImpPlan = payload
        },
        setRiskImpPlanStatus(state, payload) {
            state.riskImpPlanStatus = payload
        },
    },
    actions: {
        setRiskImpPlan({ commit }, params) {
            commit('setRiskImpPlan', params)
        },
        setRiskImpPlanStatus({ commit }, params) {
            commit('setRiskImpPlanStatus', params)
        },
        async getRiskImpPlan({ commit, state }, params) {
            return await Api().get(
                `/risk-improvement-plans${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRiskImpPlan({ commit, state }, params) {
            return await Api().post(
                `/risk-improvement-plans`, params
            ).then((res) => {
                return res.data
            })
        },
        async editRiskImpPlan({ commit, state }, params) {
            return await Api().put(
                `/risk-improvement-plans/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyRiskImpPlan({ commit, state }, params) {
            return await Api().delete(
                `/risk-improvement-plans/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getRiskImpPlan(state) {
            return state.riskImpPlan;
        },
        getRiskImpPlanStatus(state) {
            return state.riskImpPlanStatus;
        },
    }
}