<template>
  <div>
    <div>
      <tableAuditFinding :roles="'admin'" :history="true"></tableAuditFinding>
    </div>
  </div>
</template>
      
  <script>
import tableAuditFinding from "@/components/audit/tableAuditFinding.vue";
import DxButton from "devextreme-vue/button";
import router from "@/router";
export default {
  components: {
    tableAuditFinding,
    DxButton,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>