<template>
  <div>
    <div>
      <tableRisk :role="'admin'"></tableRisk>
    </div>
  </div>
</template>
  
  <script>
import tableRisk from "@/components/riskManagement/tableRisk.vue";
import router from "@/router";
export default {
  components: {
    tableRisk,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>