<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskScore" @submit.prevent="handleSubmit">
        <div v-for="data in formScore" :key="data.id">
          <cardRiskControl
            :data="data"
            :edit-score="editScore"
            @set-score="getScore"
          ></cardRiskControl>
        </div>
        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
              
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import cardRiskControl from "@/components/riskManagement/cardRiskControl.vue";

export default {
  data() {
    return {
      submitButtonOptions: {
        text: "Submit the Form",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      score: [],
      formScore: [],
      editScore: {},
    };
  },
  mounted() {
    if (this.getRiskScoreStatus.action == "addControl") {
      this.formScore = this.getRiskControl;
    } else if (this.getRiskScoreStatus.action == "addSeverity") {
      this.formScore = this.getRiskSeverity;
    } else if (this.getRiskScoreStatus.action == "editControl") {
      this.formScore = this.getRiskControl;
      this.editScore = this.getRiskScore;
    } else if (this.getRiskScoreStatus.action == "editSeverity") {
      this.formScore = this.getRiskSeverity;
      this.editScore = this.getRiskScore;
    } else {
      console.log("empty");
    }
  },
  emits: ["get-risk-score"],
  computed: {
    ...mapGetters("riskIdentification", ["getRiskIdentification"]),
    ...mapGetters("riskScore", ["getRiskScoreStatus", "getRiskScore"]),
    ...mapGetters("riskControl", ["getRiskControl"]),
    ...mapGetters("riskSeverity", ["getRiskSeverity"]),
  },
  methods: {
    checkItem(params, msg) {
      if (params == 0) {
        this.loading = true;
        notify(`item ${msg} kosong, silahkan hubungi admin!!`, "error", 5000);
      }
    },
    async getSeverity() {
      const text = `/control-item?limit=10000`;
      const dataItem = await this.$store.dispatch(
        "riskSeverity/getRiskSeverity",
        text
      );
      this.formScore = dataItem.data;
      this.checkItem(dataItem.data, "tingkat keparahan");
    },
    async getControl() {
      const text = `/control-item?limit=10000`;
      const dataItem = await this.$store.dispatch(
        "riskControl/getRiskControl",
        text
      );
      this.formScore = dataItem.data;
      this.checkItem(dataItem.data, "pengendalian risiko");
    },

    getScore(value) {
      // check same risk control
      if (this.score.length > 0) {
        let filter = this.score.filter((x) => {
          return x.score_component_id != value.score_component_id;
        });
        this.score = filter;
      }
      this.score.push(value);
    },
    handleSubmit(e) {
      this.loading = true;
      this.$emit("get-risk-score", this.score);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
    cardRiskControl,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>