import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            picChecklist: null,
            picChecklistStatus: null,
        }
    },
    mutations: {
        setPicChecklist(state, payload) {
            state.picChecklist = payload
        },
        setPicChecklistStatus(state, payload) {
            state.picChecklistStatus = payload
        },
    },
    actions: {
        setPicChecklist({ commit }, params) {
            commit('setPicChecklist', params)
        },
        setPicChecklistStatus({ commit }, params) {
            commit('setPicChecklistStatus', params)
        },
        async getPicChecklist({ commit, state }, params) {
            return await Api().get(
                `pic-checklists${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postPicChecklist({ commit, state }, params) {
            return await Api().post(
                `pic-checklists`, params
            ).then((res) => {
                return res.data
            })
        },
        async editPicChecklist({ commit, state }, params) {
            return await Api().put(
                `pic-checklists/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyPicChecklist({ commit, state }, params) {
            return await Api().delete(
                `pic-checklists/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getPicChecklist(state) {
            return state.picChecklist;
        },
        getPicChecklistStatus(state) {
            return state.picChecklistStatus;
        },
    }
}