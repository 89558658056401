<template>
  <div>
    <tableNotifDocFile :roles="'admin'"></tableNotifDocFile>
  </div>
</template>
    <script>
import tableNotifDocFile from "@/components/documentManagement/tableNotifDocFile.vue";
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  components: {
    tableNotifDocFile,
  },
};
</script>