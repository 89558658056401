<template>
  <div>
    <h2 class="content-block">Daftar Dokumen Expired {{ title }}</h2>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          hint="redirect"
          icon="link"
          @click="redirect"
          v-if="roles == 'admin'"
        />
        <DxButton
          hint="ajukan permintaan"
          icon="exportselected"
          @click="request"
          v-if="roles == 'user'"
        />
      </dx-column>
      <dx-column data-field="revision_number" caption="Nomor Revisi" />
      <dx-column
        data-field="department_id"
        caption="Department"
        :customize-text="departmentName"
      />
      <dx-column
        data-field="end_date"
        caption="Tanggal Kadaluarsa"
        :customize-text="dateColumn"
      />
    </dx-data-grid>
  </div>
</template>
      <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  // DxPopup,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import DxPopup from "devextreme-vue/popup";
import router from "@/router";
import notify from "devextreme/ui/notify";
import { mapGetters, mapActions } from "vuex";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "created_at",
            sort: sort || "desc",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "doc-file-notif-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      btnAdd: null,
      popupAdd: false,
      title: "",
    };
  },
  mounted() {},
  props: ["roles"],
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("department", ["getAllDepartment"]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  methods: {
    async request(params) {
      // console.log(params.row.data);
      const text = `/${params.row.data.document_id}`;
      const getDoc = await this.$store.dispatch("document/getDocuments", text);

      this.$store.dispatch(
        "requestDocument/setRequestDocumentStatus",
        "Request"
      );
      let document = getDoc.data;
      document.request_reason = "Perubahan Dokumen Baru";

      this.$store.dispatch("requestDocument/setRequestDocument", document);
      router.push("/user/request-document/add");
    },
    dateColumn(cellInfo) {
      if (cellInfo.value == null) {
        return "-";
      }
      const date = new Date(cellInfo.value);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "-" + mm + "-" + date.getFullYear();
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    async redirect(params) {
      const doc = await this.$store.dispatch(
        "document/getDocuments",
        `/${params.row.data.document_id}`
      );

      this.$store.dispatch("document/setDocument", doc.data);
      router.push(`/admin/document/doc-file`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      if (this.roles == "user") {
        params.department = this.getUserDetail.department_id;
        this.title = this.getUserDetail.department_name;
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "docFile/getDocNotif",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>
      <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  margin: 2%;
}
.table-center {
  width: 50%;
  margin: auto;
}
.link-icon {
  text-decoration: none;
  font-size: 18px;
}
</style>