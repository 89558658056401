<template>
  <div>
    <div>
      <tableRiskIdentification :role="'suAdmin'"></tableRiskIdentification>
    </div>
  </div>
</template>
      
      <script>
import tableRiskIdentification from "@/components/riskManagement/tableRiskIdentification.vue";
import router from "@/router";
export default {
  components: {
    tableRiskIdentification,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>