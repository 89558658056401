<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditFaultType" @submit.prevent="handleSubmit">
        <DxTextBox
          class="custom-form"
          label="Nama Tipe Kesalahan"
          v-model:value="auditFaultType.name"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>

        <DxTextArea
          class="custom-form"
          label="Deskripsi"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="auditFaultType.desc"
        >
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
          
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditFaultType: {},
      readOnly: false,
    };
  },
  computed: {
    ...mapGetters("faultType", ["getFaultType", "getFaultTypeStatus"]),
  },
  mounted() {
    if (this.getFaultTypeStatus.action == "Edit") {
      this.auditFaultType = this.getFaultType;
    }
  },
  props: {
    roles: {
      type: String,
    },
  },
  emits: ["get-audit-fault-type"],
  methods: {
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-audit-fault-type", this.auditFaultType);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
          
          <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>