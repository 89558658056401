import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            improvementItemScore: null,
            improvementItemScoreStatus: null,
        }
    },
    mutations: {
        setImprovementItemScore(state, payload) {
            state.improvementItemScore = payload
        },
        setImprovementItemScoreStatus(state, payload) {
            state.improvementItemScoreStatus = payload
        },
    },
    actions: {
        setImprovementItemScore({ commit }, params) {
            commit('setImprovementItemScore', params)
        },
        setImprovementItemScoreStatus({ commit }, params) {
            commit('setImprovementItemScoreStatus', params)
        },
        async getImprovementItemScore({ commit, state }, params) {
            return await Api().get(
                `improvement-item-scores${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postImprovementItemScore({ commit, state }, params) {
            return await Api().post(
                `improvement-item-scores`, params
            ).then((res) => {
                return res.data
            })
        },
        async editImprovementItemScore({ commit, state }, params) {
            return await Api().put(
                `improvement-item-scores/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyImprovementItemScore({ commit, state }, params) {
            return await Api().delete(
                `improvement-item-scores/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getImprovementItemScore(state) {
            return state.improvementItemScore;
        },
        getImprovementItemScoreStatus(state) {
            return state.improvementItemScoreStatus;
        },
    }
}