<template>
  <div>
    <div>
      <tableRiskControlItem :role="'admin'"></tableRiskControlItem>
    </div>
  </div>
</template>
    
    <script>
import tableRiskControlItem from "@/components/riskManagement/tableRiskControlItem.vue";
import router from "@/router";
export default {
  components: {
    tableRiskControlItem,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>