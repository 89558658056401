<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskControlItem" @submit.prevent="handleSubmit">
        <DxNumberBox
          class="custom-form"
          label="Nilai"
          :min="0"
          :show-spin-buttons="true"
          :input-attr="{ 'aria-label': 'Min And Max' }"
          v-model:value="indicator.score"
        />
        <DxTextBox
          class="custom-form"
          label="Nama"
          v-model:value="indicator.name"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
          
    <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxNumberBox } from "devextreme-vue/number-box";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
// import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      submitButtonOptions: {
        text: "Submit the Form",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      indicator: {},
    };
  },
  mounted() {
    if (this.getRiskScoreIndicatorStatus.action == "Edit") {
      this.indicator = this.getRiskScoreIndicator;
    }
  },
  emits: ["get-risk-score-indicator"],
  computed: {
    ...mapGetters("riskControlItem", ["getRiskControlItem"]),
    ...mapGetters("riskScoreIndicator", [
      "getRiskScoreIndicator",
      "getRiskScoreIndicatorStatus",
    ]),
  },
  methods: {
    handleSubmit(e) {
      this.indicator.control_item_id = this.getRiskControlItem.id;
      this.loading = true;
      this.$emit("get-risk-score-indicator", this.indicator);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    // FilterTableUnitBisnis,
    DxNumberBox,
    DxLoadIndicator,
  },
};
</script>
    
    <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>