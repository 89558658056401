<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addTemplateDoc" @submit.prevent="handleSubmit">
        <DxTextBox
          class="custom-form"
          label="Nama"
          v-model:value="templateDoc.name"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <div class="fileuploader-container custom-form">
          <DxFileUploader
            select-button-text="Pilih Dokumen"
            label-text="upload file"
            :allowed-file-extensions="['.docx', '.doc', '.xlsx']"
            :max-file-size="1000000"
            upload-mode="useForm"
            @value-changed="getFile"
          >
            <DxValidator v-if="this.getTemplateDocStatus == 'Add'">
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxFileUploader>
        </div>
        <div style="text-align: right; margin-bottom: 10px">
          Maximum file size:
          <span>5 MB</span>.
        </div>
        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <!-- Loding belum berfungsi -->
      </form>
    </div>
  </div>
</template>
          
          <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import DxValidationGroup from "devextreme-vue/validation-group";

export default {
  data() {
    return {
      loading: false,
      unitBisnisOptions: [],
      departmentOptions: [],
      templateDoc: {},
      file: "",
      id: this.$route.params.id,
      readOnly: false,
    };
  },
  computed: {
    ...mapGetters("templateDocs", ["getTemplateDoc", "getTemplateDocStatus"]),
  },
  mounted() {
    this.unitBisnis();
    if (this.getTemplateDocStatus == "Edit") {
      this.templateDoc = this.getTemplateDoc;
    }
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  emits: ["get-template-doc"],
  methods: {
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];
      }
    },
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );
      this.departmentOptions = depart.data;
    },
    async detail() {
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        `/${this.id}`
      );
      this.readOnly = true;
      this.codeDepartment = depart.data;
      console.log(this.codeDepartment);
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getCodeUnit", "?");
      this.unitBisnisOptions = units.data;
    },
    async handleSubmit(e) {
      this.templateDoc.document = this.file;
      this.loading = true;
      this.$emit("get-template-doc", this.templateDoc);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    FilterTableUnitBisnis,
    DxFileUploader,
  },
};
</script>
          
          <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
</style>