import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            regulationImpl: null,
            regulationImplStatus: null,
        }
    },
    mutations: {
        setRegulationImpl(state, payload) {
            state.regulationImpl = payload
        },
        setRegulationImplStatus(state, payload) {
            state.regulationImplStatus = payload
        },
    },
    actions: {
        setRegulationImpl({ commit }, params) {
            commit('setRegulationImpl', params)
        },
        setRegulationImplStatus({ commit }, params) {
            commit('setRegulationImplStatus', params)
        },
        async getRegulationImplementations({ commit, state }, params) {
            return await Api().get(
                `regulation-implementations${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getRegulationImplementation({ }, params) {
            return await Api().get(
                `regulation-implementations/${params.id}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRegulationImplementations({ commit, state }, params) {
            return await Api().post(
                `regulation-implementations`
                , params, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        },
        async updateRegulationImplementations({ commit, state }, params) {
            return await Api().post(
                `regulation-implementations/${params.id}`
                , params, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        },
        async destroyRegulationImplementations({ commit, state }, params) {
            return await Api().delete(
                `regulation-implementations/${params.id}`)
        },
    },
    getters: {
        getRegulationImpl(state) {
            return state.regulationImpl;
        },
        getRegulationImplStatus(state) {
            return state.regulationImplStatus;
        }
    }
}