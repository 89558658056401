import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            improvementCorrectionPlan: null,
            improvementCorrectionPlanStatus: null,
        }
    },
    mutations: {
        setImprovementCorrectionPlan(state, payload) {
            state.improvementCorrectionPlan = payload
        },
        setImprovementCorrectionPlanStatus(state, payload) {
            state.improvementCorrectionPlanStatus = payload
        },
    },
    actions: {
        setImprovementCorrectionPlan({ commit }, params) {
            commit('setImprovementCorrectionPlan', params)
        },
        setImprovementCorrectionPlanStatus({ commit }, params) {
            commit('setImprovementCorrectionPlanStatus', params)
        },
        async getImprovementCorrectionPlan({ commit, state }, params) {
            return await Api().get(
                `improvement-correction-plans${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postImprovementCorrectionPlan({ commit, state }, params) {
            return await Api().post(
                `improvement-correction-plans`, params
            ).then((res) => {
                return res.data
            })
        },
        async editImprovementCorrectionPlan({ commit, state }, params) {
            return await Api().put(
                `improvement-correction-plans/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyImprovementCorrectionPlan({ commit, state }, params) {
            return await Api().delete(
                `improvement-correction-plans/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getImprovementCorrectionPlan(state) {
            return state.improvementCorrectionPlan;
        },
        getImprovementCorrectionPlanStatus(state) {
            return state.improvementCorrectionPlanStatus;
        },
    }
}