import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            result: [],
            detail: []
        }
    },
    mutations: {
        setData(state, payload) {
            state.result = payload
        },
        setDetail(state, payload) {
            state.detail = payload
        },
    },
    actions: {
        // async getDocumentType({ commit, state }, params) {
        //     return await Api()
        //         .get(`document-types/${params.id}`).then((res) => {
        //             commit('setDetail', res.data)
        //             return res.data
        //         }).catch((err) => {
        //             console.log(err.message);
        //         });
        // },
        async getDocumentTypes({ commit, state }, params) {
            return await Api()
                .get(`document-types${params}`).then((res) => {
                    commit('setData', res.data)
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        // async getDocumentByDocType({ commit, state }, params) {
        //     console.log(params);
        //     return await Api()
        //         .get(`documents/doc-type${params}`).then((res) => {
        //             return res.data
        //         }).catch((err) => {
        //             console.log(err.message);
        //         });
        // },
        // async getRequestApprove({ }, params) {
        //     console.log(params);
        //     return await Api()
        //         .get(`request-documents/approve?page=${params.page}&limit=${params.perPage}&sorted_by=${params.sortedBy}&sort=${params.sort}&search=${params.filterValue}&applicant_id=${params.applicant_id}`).then((res) => {
        //             return res.data
        //         }).catch((err) => {
        //             console.log(err.message);
        //         });
        // },
        // async getRequestHistory({ }, params) {
        //     return await Api()
        //         .get(`request-documents/history?page=${params.page}&limit=${params.perPage}&sorted_by=${params.sortedBy}&sort=${params.sort}&search=${params.filterValue}&applicant_id=${params.applicant_id}`).then((res) => {
        //             return res.data
        //         }).catch((err) => {
        //             console.log(err.message);
        //         });
        // },
        // async postDocument({ }, params) {
        //     const now = new Date();
        //     return await Api()
        //         .post("documents", params,);
        // },
        // async updateDocument({ }, params) {
        //     return await Api()
        //         .put(`documents/${params.id}`, params,);
        // },
    },
    getters: {
        getRegulations(state) {
            return state.result
        }
    }
}