import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            asset: null,
            assetStatus: null,
        }
    },
    mutations: {
        setAsset(state, payload) {
            state.asset = payload
        },
        setAssetStatus(state, payload) {
            state.assetStatus = payload
        },
        cleanAsset(state) {
            state.asset = null
        },
    },
    actions: {
        setAsset({ commit }, params) {
            commit('setAsset', params)
        },
        setAssetStatus({ commit }, params) {
            commit('setAssetStatus', params)
        },
        cleanAsset({ commit }) {
            commit('cleanAsset')
        },
        async getAsset({ commit, state }, params) {
            return await Api().get(
                `assets${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        // async getAsset({ commit, state }, params) {
        //     return await Api()
        //         .get(`assets/${params.id}`).then((res) => {
        //             commit('setData', res.data)
        //             return res.data
        //         }).catch((err) => {
        //             console.log(err.message);
        //         });
        // },
        async postAsset({ }, params) {
            return await Api()
                .post('assets', params, { headers: { "Content-Type": "multipart/form-data" }, }).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async updateAsset({ }, params) {
            return await Api()
                .post(`assets/${params.id}`, params, { headers: { "Content-Type": "multipart/form-data" }, }).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
    },
    getters: {
        getAsset(state) {
            return state.asset;
        },
        getAssetStatus(state) {
            return state.assetStatus;
        },
    }
}