<template>
  <div>
    <h2 class="content-block">
      {{ getCodeUnitStatus == "Add" ? "Membuat" : "Edit" }} Kode Unit Bisnis
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formCompanyCode @get-unit-code="submit"></formCompanyCode>
  </div>
</template>
<script>
import formCompanyCode from "@/components/master/formCompanyCode.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formCompanyCode,
    DxButton,
  },
  computed: {
    ...mapGetters("unitBisnis", ["getCodeUnitStatus"]),
  },
  methods: {
    async submit(value) {
      if (this.getCodeUnitStatus == "Add") {
        this.$store
          .dispatch("unitBisnis/postUnits", value)
          .then((res) => {
            router.push(`/admin/code/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getCodeUnitStatus == "Edit") {
        this.$store
          .dispatch("unitBisnis/editUnits", value)
          .then((res) => {
            router.push(`/admin/code/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>