<template>
  <div>
    <form action="/addRegulationList" @submit.prevent="handleSubmit">
      <DxSelectBox
        class="custom-form"
        :data-source="requirementTypeOption"
        label="Pilih Tipe Kebutuhan"
        value-expr="name"
        display-expr="name"
        v-model="regulationImpl.requirement_type"
        :is-required="true"
      >
        <DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxSelectBox>

      <DxTextBox
        class="custom-form"
        label="Detail"
        v-model:value="regulationImpl.requirement_detail"
        :is-required="true"
        ><DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextBox>

      <div class="fileuploader-container custom-form">
        <DxFileUploader
          select-button-text="Pilih File"
          label-text="Unggah File"
          :max-file-size="5000000"
          upload-mode="useForm"
          @value-changed="getFile"
        >
          <!-- <DxValidator v-if="getRegulationImplStatus == 'Add'">
            <DxRequiredRule message="Required" />
          </DxValidator> -->
        </DxFileUploader>
        <!-- upload-mode="useForm" -->
      </div>
      <div style="text-align: right; margin-bottom: 10px">
        *Maximum file size:
        <span>5 MB</span>.
      </div>

      <DxButton
        v-if="!loading"
        text="Simpan Data"
        type="success"
        icon="save"
        :use-submit-behavior="true"
        style="margin: 1%"
      />
    </form>
  </div>
</template>
        
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      department_id: "22e3ac63-255a-4e3e-8853-a361e4b13a8d",
      requirementTypeOption: [
        {
          name: "Proses Area",
        },
        {
          name: "Fasilitas",
        },
        {
          name: "Dokumen Kerja",
        },
      ],
      detail: this.$route.params.id,
      submitButtonOptions: {
        text: "Submit the Form",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      regulationImpl: {},
      file: "",
    };
  },
  mounted() {
    if (this.getRegulationImplStatus.action == "Edit") {
      this.regulationImpl = this.getRegulationImpl;
    }
  },
  computed: {
    ...mapGetters("regulationDetail", ["getRegulationDetail"]),
    ...mapGetters("regulationImpl", [
      "getRegulationImpl",
      "getRegulationImplStatus",
    ]),
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    detailId: {
      type: String,
      // default: false,
    },
  },
  emits: ["get-regulation-impl", "cancle"],
  methods: {
    cancle() {
      this.$emit("cancle", false);
    },
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];
      }
    },
    async handleSubmit(e) {
      if (this.getRegulationImplStatus.action == "Add") {
        this.regulationImpl.regulation_detail_id = this.getRegulationDetail.id;
        this.regulationImpl.pod_file = this.file;
        this.regulationImpl.upload_date = new Date();
      } else if (this.getRegulationImplStatus.action == "Edit") {
        if (this.file) {
          this.regulationImpl.pod_file = this.file;
        }
        this.regulationImpl._method = "PUT";
      } else {
        console.log("Error");
        return;
      }
      this.regulationImpl.verification_date = "";

      this.loading = true;
      this.$emit("get-regulation-impl", this.regulationImpl);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    FilterTableUnitBisnis,
    DxFileUploader,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
</style>