<template>
  <filter-table-unit-bisnis
    @get-unit-bisnis="getCompany"
    @get-department="getDepartment"
  ></filter-table-unit-bisnis>
  <br />
  <br />

  <div class="center">
    <DxPieChart
      id="pie"
      ref="pieChartTotal"
      :data-source="dataSource"
      palette="Material"
      :title="title"
      @point-click="pointClickHandler($event)"
      @legend-click="legendClickHandler($event)"
      style="background-color: white; margin-bottom: 10px"
    >
      <DxSeries argument-field="status" value-field="total">
        <DxLabel :visible="true">
          <DxConnector :visible="true" :width="1" />
        </DxLabel>
      </DxSeries>
      <DxExport
        :enabled="true"
        :formats="['PNG']"
        file-name="exported_chart"
        :printing-enabled="false"
      />
    </DxPieChart>
  </div>
</template>
  
  <script>
import DxPieChart, {
  DxSize,
  DxSeries,
  DxLabel,
  DxConnector,
  DxExport,
} from "devextreme-vue/pie-chart";
import { exportWidgets } from "devextreme/viz/export";
import DxButton from "devextreme-vue/button";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import axios from "axios";

export default {
  components: {
    DxPieChart,
    DxSize,
    DxSeries,
    DxLabel,
    DxConnector,
    DxExport,
    DxButton,
    FilterTableUnitBisnis,
  },
  computed: {
    pieChart: function () {
      return this.$refs[this.gridRef].instance;
    },
  },
  data() {
    return {
      dataSource: "",
      title: "All Regulation",
      allDepartments: [],
    };
  },
  mounted() {
    this.getTotalStatus();
  },
  methods: {
    async getTotalStatus(params = "") {
      const text = `?department=${params}`;
      const total = await this.$store.dispatch(
        "regulationList/getTotalStatus",
        text
      );
      console.log(total.data);
      this.dataSource = total.data;
    },
    async setDepartments(params) {
      const text = `?company=${params}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );
      this.allDepartments = depart.data;
    },
    getCompany(value) {
      this.setDepartments(value);
    },
    async getDepartment(value) {
      const allDepart = this.allDepartments;
      if (value) {
        let depart = allDepart.find((d) => d.department_id == value);
        //set title
        this.title = depart.department_name;
      } else {
        this.title = "All Regulation";
      }
      //get total
      this.getTotalStatus(value);
    },
    pointClickHandler(e) {
      this.toggleVisibility(e.target);
    },
    legendClickHandler(e) {
      const arg = e.target;
      const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

      this.toggleVisibility(item);
    },
    toggleVisibility(item) {
      item.isVisible() ? item.hide() : item.show();
    },
  },
};
</script>
  
  <style>
#pie {
  height: 400px;
  width: 90%;
}

#pie * {
  margin: 0 auto;
}
.center {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
</style>
  