<template>
  <div>
    <div>
      <tableAuditFactorFinding :roles="'admin'"></tableAuditFactorFinding>
    </div>
  </div>
</template>
      
  <script>
import tableAuditFactorFinding from "@/components/audit/tableAuditFactorFinding.vue";
export default {
  components: {
    tableAuditFactorFinding,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>