<template>
  <form action="post-action" @submit.prevent="handleSubmit" :ref="formRef">
    <div class="fileuploader-container custom-form">
      <DxFileUploader
        select-button-text="Pilih Dokumen"
        label-text="upload file"
        :allowed-file-extensions="['.docx', '.doc', '.pdf', '.xlsx']"
        :max-file-size="6000000"
        upload-mode="useForm"
        @value-changed="getFile"
      >
        <DxValidator v-if="this.getDocumentFileStatus == 'Add'">
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxFileUploader>
    </div>
    <div style="text-align: right; margin-bottom: 10px">
      Maximum file size:
      <span>5 MB</span>.
    </div>

    <div class="custom-form">
      <div class="label-radio">Catatan Revisi</div>
      <DxHtmlEditor
        class="custom-form"
        v-model:value="documentFile.revision_note"
      >
        <DxToolbar>
          <DxItem name="undo" />
          <DxItem name="redo" />
          <DxItem name="separator" />
          <DxItem
            name="header"
            :accepted-values="headerValues"
            :options="headerOptions"
          />
          <DxItem name="separator" />
          <DxItem name="bold" />
          <DxItem name="italic" />
          <DxItem name="strike" />
          <DxItem name="underline" />
          <DxItem name="separator" />
          <DxItem name="orderedList" />
          <DxItem name="bulletList" />
        </DxToolbar>
      </DxHtmlEditor>
    </div>

    <DxButton
      v-if="!loading"
      text="Simpan Data"
      type="success"
      icon="save"
      :use-submit-behavior="true"
    />
  </form>
</template>
<script>
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import DxTextArea from "devextreme-vue/text-area";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxDateBox from "devextreme-vue/date-box";
import { DxTextBox } from "devextreme-vue/text-box";
import { mapGetters, mapActions } from "vuex";

import { DxHtmlEditor, DxToolbar, DxItem } from "devextreme-vue/html-editor";

export default {
  components: {
    DxButton,
    DxValidator,
    DxRequiredRule,
    DxTextArea,
    DxFileUploader,
    DxDateBox,
    DxTextBox,
    DxHtmlEditor,
    DxToolbar,
    DxItem,
  },
  data() {
    return {
      id: this.$route.params.id,
      documentFile: {},
      file: "",
      formRef: "form-doc-file",
      loading: false,
      // value: ref(markup),
      // popupVisible: ref(false),
      headerValues: [false, 1, 2, 3, 4, 5],
      headerOptions: { inputAttr: { "aria-label": "Header" } },
    };
  },
  emits: ["get-doc-file"],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.getDocumentFileStatus == "Edit") {
      this.documentFile = this.getDocumentFile;
    }
    if (this.getDocumentFileStatus == "EditExpired") {
      this.documentFile = this.getDocumentFile;
      const nowDate = new Date();
      this.documentFile.start_date = new Date();
      this.documentFile.end_date = new Date(
        nowDate.setFullYear(nowDate.getFullYear() + 3)
      );
    }
  },
  computed: {
    ...mapGetters("docFile", ["getDocumentFile", "getDocumentFileStatus"]),
    ...mapGetters("document", ["getDocument"]),
  },
  methods: {
    async handleSubmit() {
      if (this.getDocumentFileStatus == "Add") {
        this.documentFile.document_id = this.getDocument.id;
        this.documentFile.upload_date = new Date();
      }
      this.documentFile.files_url = this.file;
      this.loading = true;
      this.$emit("get-doc-file", this.documentFile);
    },
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];
      }
    },
  },
};
</script>

<style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
.main-block {
  max-width: 900px;
  min-width: 700px;
}
.label-radio {
  color: darkgray;
  display: block;
}
.label-radio:after {
  content: " : ";
}
</style>