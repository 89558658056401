<template>
  <div>
    <form action="/addRegulationList" @submit.prevent="handleSubmit">
      <DxCheckBox
        text="Bab : All"
        :value="all"
        class="custom-form"
        @valueChanged="checkbox"
      />
      <div class="center" v-if="!all">
        <DxTextBox
          class="group"
          label="Bab"
          v-model:value="regulationDetail.chapter"
          :is-required="true"
          width="30%"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <DxTextBox
          class="group"
          label="Pasal"
          v-model:value="regulationDetail.article"
          :is-required="true"
          width="30%"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <DxTextBox
          class="group"
          label="Ayat"
          v-model:value="regulationDetail.verse"
          :is-required="true"
          width="30%"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
      </div>
      <DxNumberBox
        class="custom-form"
        format="#"
        label="Jumlah bukti yang harus dipenuhi"
        :is-required="true"
        v-model:value="regulationDetail.total_impl"
        ><DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxNumberBox>
      <DxTextArea
        class="custom-form"
        label="Inti Sari"
        :height="90"
        :input-attr="{ 'aria-label': 'Notes' }"
        v-model:value="regulationDetail.summary"
      >
        <DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextArea>

      <DxButton
        v-if="!loading"
        text="Simpan"
        type="success"
        icon="save"
        :use-submit-behavior="true"
      />
    </form>
  </div>
</template>
        
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxNumberBox from "devextreme-vue/number-box";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import { DxCheckBox } from "devextreme-vue/check-box";

export default {
  data() {
    return {
      department_id: "22e3ac63-255a-4e3e-8853-a361e4b13a8d",
      id: this.$route.params.id,
      submitButtonOptions: {
        text: "Simpan Data",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      regulationDetail: {},
      all: false,
    };
  },
  computed: {
    ...mapGetters("regulationDetail", [
      "getRegulationDetail",
      "getRegulationDetailStatus",
    ]),
    ...mapGetters("regulationList", ["getRegulationList"]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  mounted() {
    if (this.getRegulationDetailStatus.action == "Edit") {
      this.regulationDetail = this.getRegulationDetail;
      if (this.getRegulationDetail.chapter == "All") {
        this.all = true;
      }
    }
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["get-regulation-detail", "get-status"],
  methods: {
    checkbox(params) {
      this.regulationDetail.chapter = "";
      this.regulationDetail.article = "";
      this.regulationDetail.verse = "";
      if (params.value) {
        this.regulationDetail.chapter = "All";
        this.regulationDetail.article = "-";
        this.regulationDetail.verse = "-";
      }
      this.all = params.value;
    },
    async handleSubmit(e) {
      if (this.getRegulationDetailStatus.action == "Add") {
        this.regulationDetail.regulation_id = this.getRegulationList.id;
        this.regulationDetail.status = "process";
      }
      this.loading = true;
      this.$emit("get-regulation-detail", this.regulationDetail);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    FilterTableUnitBisnis,
    DxCheckBox,
    DxNumberBox,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.center {
  justify-content: space-between;
  display: flex;
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  display: inline-block;
}
</style>