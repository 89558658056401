<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/auditCorrection" @submit.prevent="handleSubmit">
        <p>
          contoh : kordinator memastikan proses serah terima dengan driver
          berjalan sesuai dengan SOP dan kondisi gudang baru pada saat ini lebih
          safety untuk parkir armada di dalam gudang dan terpantau oleh tim dan
          CCTV
        </p>
        <div v-for="(form, index) in auditCorrection" :key="index">
          <DxTextArea
            class="custom-form"
            label="Perbaikan Permanen *"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.action"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextArea>
          <DxButton
            v-if="auditCorrection.length > 1"
            class="customBtn"
            text="Form"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading">
          <DxButton
            v-if="paramsRoute.condition == 'add' && auditCorrection.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditCorrection: [
        {
          report_id: "",
          action: "",
        },
      ],
      readOnly: false,
      paramsRoute: this.$route.params,
    };
  },
  computed: {
    ...mapGetters("auditCorrection", [
      "getAuditCorrection",
      "getAuditCorrectionStatus",
    ]),
    ...mapGetters("auditReport", ["getAuditReport"]),
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getAuditCorrection) {
        this.auditCorrection[0] = this.getAuditCorrection;
      } else {
        this.loadAuditCorrection(this.paramsRoute.id);
      }
    }
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  emits: ["get-audit-correction"],
  methods: {
    async loadAuditCorrection(params) {
      try {
        const getAuditCorrection = await this.$store.dispatch(
          "auditCorrection/getAuditCorrection",
          `/${params}`
        );

        this.auditCorrection[0] = getAuditCorrection.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.auditCorrection.push({
        action: "",
      });
    },
    removeForm(index) {
      this.auditCorrection.splice(index, 1);
    },
    async handleSubmit(e) {
      this.loading = true;
      // if (this.getAuditCorrectionStatus.action == "Add") {
      //   this.auditCorrection.report_id = this.getAuditReport.id;
      // }
      this.$emit("get-audit-correction", this.auditCorrection);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.customBtn {
  margin: 1%;
}
</style>