<template>
  <div>
    <!-- <h2 class="content-block">
      Menunggu Persetujuan Head <span v-if="title">{{ title }}</span>
    </h2> -->
    <!-- :where-null="whereNull" -->
    <tableRequestDoc :role="role"></tableRequestDoc>
    <!-- @title="getTitle" -->
  </div>
</template>
  
  <script>
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import tableRequestDoc from "@/components/documentManagement/tableRequestDoc.vue";

import notify from "devextreme/ui/notify";
import router from "@/router";

export default {
  data() {
    return {
      role: "head",
      whereNull: "head_signed",
      title: false,
    };
  },
  methods: {
    async getTitle(value) {
      this.title = value;
    },
  },
  components: {
    FilterTableUnitBisnis,
    tableRequestDoc,
  },
};
</script>