import axios from 'axios';
import Cookie from 'js-cookie'
import Api from '@/services/Api';
import CryptoJS from 'crypto-js';

export default {
    namespaced: true,
    state() {
        return {
            codeDepartment: null,
            allDepartment: localStorage.getItem('m_dpt') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('m_dpt'), 'secretKey').toString(CryptoJS.enc.Utf8)) : null,
            codeDepartmentStatus: null,
        }
    },
    mutations: {
        setCodeDepartment(state, payload) {
            state.codeDepartment = payload
        },
        setAllDepartment(state, payload) {
            state.allDepartment = payload
        },
        setCodeDepartmentStatus(state, payload) {
            state.codeDepartmentStatus = payload
        },
    },
    actions: {
        setCodeDepartment({ commit }, params) {
            commit('setCodeDepartment', params)
        },
        setCodeDepartmentStatus({ commit }, params) {
            commit('setCodeDepartmentStatus', params)
        },
        async getAllDepartment({ commit, state }, params) {
            return await Api().get(
                `departments${params}`,
            ).then((res) => {
                // set localstorage and generate
                localStorage.setItem('m_dpt', CryptoJS.AES.encrypt(JSON.stringify(res.data.data), 'secretKey').toString());

                commit('setAllDepartment', res.data.data)
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getDepartments({ commit, state }, params) {
            return await Api().get(
                `departments${params}`,
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getCodeDepartment({ commit, state }, params) {
            return await Api().get(
                `/departments/code${params}`,
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postCodeDepartment({ commit, state }, params) {
            return await Api().post(
                `/departments`, params
            ).then((res) => {
                return res.data
            })
        },
        async editCodeDepartment({ commit, state }, params) {
            return await Api().put(
                `/departments/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyCodeDepartment({ commit, state }, params) {
            return await Api().delete(
                `/departments/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getCodeDepartment(state) {
            return state.codeDepartment;
        },
        getAllDepartment(state) {
            return state.allDepartment;
        },
        getCodeDepartmentStatus(state) {
            return state.codeDepartmentStatus;
        },
    }
}