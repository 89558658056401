import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            riskIdentification: null,
            riskIdentificationStatus: null,
        }
    },
    mutations: {
        setRiskIdentification(state, payload) {
            state.riskIdentification = payload
        },
        setRiskIdentificationStatus(state, payload) {
            state.riskIdentificationStatus = payload
        },
    },
    actions: {
        setRiskIdentification({ commit }, params) {
            commit('setRiskIdentification', params)
        },
        setRiskIdentificationStatus({ commit }, params) {
            commit('setRiskIdentificationStatus', params)
        },
        async getRiskIdentification({ commit, state }, params) {
            return await Api().get(
                `risk-identifications${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRiskIdentification({ commit, state }, params) {
            return await Api().post(
                `risk-identifications`, params
            ).then((res) => {
                return res.data
            })
        },
        async editRiskIdentification({ commit, state }, params) {
            return await Api().put(
                `risk-identifications/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyRiskIdentification({ commit, state }, params) {
            return await Api().delete(
                `risk-identifications/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getRiskIdentification(state) {
            return state.riskIdentification;
        },
        getRiskIdentificationStatus(state) {
            return state.riskIdentificationStatus;
        },
    }
}