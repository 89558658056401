import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            userAccess: null,
            userAccessStatus: null,
        }
    },
    mutations: {
        setUserAccess(state, payload) {
            state.userAccess = payload
        },
        setUserAccessStatus(state, payload) {
            state.userAccessStatus = payload
        },
    },
    actions: {
        setUserAccess({ commit }, params) {
            commit('setUserAccess', params)
        },
        setUserAccessStatus({ commit }, params) {
            commit('setUserAccessStatus', params)
        },
        async getUserAccess({ commit, state }, params) {
            return await Api().get(
                `user-access${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postUserAccess({ commit, state }, params) {
            return await Api().post(
                `/user-access`, params
            ).then((res) => {
                return res.data
            })
        },
        async editUserAccess({ commit, state }, params) {
            return await Api().put(
                `/user-access/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyUserAccess({ commit, state }, params) {
            return await Api().delete(
                `user-access/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getUserAccess(state) {
            return state.userAccess;
        },
        getUserAccessStatus(state) {
            return state.userAccessStatus;
        },
    }
}