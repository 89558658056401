import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditRiskControl: null,
            auditRiskControlStatus: null,
        }
    },
    mutations: {
        setAuditRiskControl(state, payload) {
            state.auditRiskControl = payload
        },
        setAuditRiskControlStatus(state, payload) {
            state.auditRiskControlStatus = payload
        },
    },
    actions: {
        setAuditRiskControl({ commit }, params) {
            commit('setAuditRiskControl', params)
        },
        setAuditRiskControlStatus({ commit }, params) {
            commit('setAuditRiskControlStatus', params)
        },
        async getAuditRiskControl({ commit, state }, params) {
            return await Api().get(
                `audit-risk-controls${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditRiskControl({ commit, state }, params) {
            return await Api().post(
                `audit-risk-controls`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAuditRiskControl({ commit, state }, params) {
            return await Api().put(
                `audit-risk-controls/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditRiskControl({ commit, state }, params) {
            return await Api().delete(
                `audit-risk-controls/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditRiskControl(state) {
            return state.auditRiskControl;
        },
        getAuditRiskControlStatus(state) {
            return state.auditRiskControlStatus;
        },
    }
}