<template>
  <div>
    <h2 class="content-block">
      {{ getAuditRootCouseStatus.action == "Add" ? "Membuat" : "Edit" }}
      Daftar Analisa Akar Masalah
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formAuditRootCouse @get-audit-root-couse="submit"></formAuditRootCouse>
  </div>
</template>
              <script>
import formAuditRootCouse from "@/components/audit/formAuditRootCause.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formAuditRootCouse,
    DxButton,
  },
  computed: {
    ...mapGetters("auditRootCouse", ["getAuditRootCouseStatus"]),
    redirect() {
      if (this.getAuditRootCouseStatus.roles == "user") {
        router.push(`/user/audit-report/info`);
      } else if (this.getAuditRootCouseStatus.roles == "suAdmin") {
        // router.push(`/admin/audit-report/info`);
        console.log("suadmin");
      } else {
        router.push(`/admin/audit-report/info`);
      }
    },
  },
  methods: {
    async submit(value) {
      const data = value.form;
      if (this.getAuditRootCouseStatus.action == "Add") {
        try {
          for (const item of data) {
            item.report_id = value.audit_report;
            await this.$store.dispatch(
              "auditRootCouse/postAuditRootCouse",
              item
            );
          }
          this.redirect;
        } catch (error) {
          console.log(error);
          notify(error.message, "error", 2000);
        }
      } else if (this.getAuditRootCouseStatus.action == "Edit") {
        this.$store
          .dispatch("auditRootCouse/editAuditRootCouse", value.form[0])
          .then((res) => {
            this.redirect;
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>