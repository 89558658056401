<template>
  <div>
    <form action="/addRegulationList" @submit.prevent="handleSubmit">
      <DxTextBox
        class="custom-form"
        label="Nomor Regulasi"
        v-model:value="regulationList.regulation_number"
        :is-required="true"
        ><DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextBox>
      <DxTextArea
        class="custom-form"
        label="Topik Regulasi"
        :height="90"
        :input-attr="{ 'aria-label': 'Notes' }"
        v-model:value="regulationList.regulation_topic"
      >
        <DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextArea>
      <DxTextBox
        class="custom-form"
        label="Tipe Regulasi"
        v-model:value="regulationList.type_regulation"
        :is-required="true"
        ><DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextBox>
      <DxSelectBox
        v-if="getRegulationListStatus.roles == 'suAdmin'"
        class="custom-form"
        :data-source="unitBisnisOptions"
        label="Pilih Unit Bisnis"
        value-expr="id"
        display-expr="name"
        :is-required="true"
        @valueChanged="changeUnitBisnis"
        v-model="regulationList.company_id"
      >
      </DxSelectBox>
      <DxSelectBox
        v-if="getRegulationListStatus.roles == 'suAdmin'"
        class="custom-form"
        :data-source="departmentOptions"
        label="Pilih Department"
        value-expr="department_id"
        display-expr="department_name"
        v-model="regulationList.department_id"
        :is-required="true"
        :read-only="readOnlyDept"
      >
      </DxSelectBox>

      <DxButton
        v-if="!loading"
        text="Simpan Data"
        type="success"
        icon="save"
        :use-submit-behavior="true"
      />
    </form>
  </div>
</template>
      
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      submitButtonOptions: {
        text: "Submit the Form",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      regulationList: {},
      unitBisnisOptions: "",
      departmentOptions: "",
      readOnlyDept: true,
    };
  },
  mounted() {
    if (this.getRegulationListStatus.action == "Edit") {
      this.regulationList = this.getRegulationList;
    }
    this.unitBisnis();
  },
  emits: ["get-regulation-list"],
  computed: {
    ...mapGetters("regulationList", [
      "getRegulationList",
      "getRegulationListStatus",
    ]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  methods: {
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      this.readOnlyDept = false;
      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    handleSubmit(e) {
      if (this.getRegulationListStatus.roles == "user") {
        this.regulationList.department_id = this.getUserDetail.department_id;
        // this.regulationList.status = "process";
      }
      this.loading = true;
      this.$emit("get-regulation-list", this.regulationList);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    FilterTableUnitBisnis,
  },
};
</script>

<style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>