<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRisk" @submit.prevent="handleSubmit">
        <DxTextArea
          class="custom-form"
          label="Proses / Aktivitas"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="risk.process_name"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>
        <DxSelectBox
          v-if="getRiskStatus.roles == 'suAdmin'"
          class="custom-form"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis"
          value-expr="id"
          display-expr="name"
          :is-required="true"
          @valueChanged="changeUnitBisnis"
          v-model="risk.company_id"
        >
        </DxSelectBox>
        <DxSelectBox
          v-if="getRiskStatus.roles == 'suAdmin'"
          class="custom-form"
          :data-source="departmentOptions"
          label="Pilih Department"
          value-expr="department_id"
          display-expr="department_name"
          v-model="risk.department_id"
          :is-required="true"
          :read-only="readOnlyDept"
        >
        </DxSelectBox>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
      <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      submitButtonOptions: {
        text: "Submit the Form",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      loadIndicatorVisible: false,
      buttonText: "Simpan",
      risk: {},
      unitBisnisOptions: "",
      departmentOptions: "",
      readOnlyDept: true,
    };
  },
  mounted() {
    if (this.getRiskStatus.action == "Edit") {
      this.risk = this.getRisk;
    }
    this.unitBisnis();
  },
  emits: ["get-risk"],
  computed: {
    ...mapGetters("risk", ["getRisk", "getRiskStatus"]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  methods: {
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      this.readOnlyDept = false;
      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    handleSubmit(e) {
      this.loading = true;
      if (this.getRiskStatus.roles == "user") {
        this.risk.department_id = this.getUserDetail.department_id;
      }
      this.$emit("get-risk", this.risk);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
    FilterTableUnitBisnis,
  },
};
</script>
      
      <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}

#button .button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
</style>