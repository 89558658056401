import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            capa: null,
            capaStatus: null,
        }
    },
    mutations: {
        setCapa(state, payload) {
            state.capa = payload
        },
        setCapaStatus(state, payload) {
            state.capaStatus = payload
        },
    },
    actions: {
        setCapa({ commit }, params) {
            commit('setCapa', params)
        },
        setCapaStatus({ commit }, params) {
            commit('setCapaStatus', params)
        },
        async getCapa({ commit, state }, params) {
            return await Api().get(
                `capa${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postCapa({ commit, state }, params) {
            return await Api().post(
                `capa`, params
            ).then((res) => {
                return res.data
            })
        },
        async editCapa({ commit, state }, params) {
            return await Api().put(
                `capa/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyCapa({ commit, state }, params) {
            return await Api().delete(
                `capa/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getCapa(state) {
            return state.capa;
        },
        getCapaStatus(state) {
            return state.capaStatus;
        },
    }
}