<template>
  <div>
    <h2 class="content-block">
      {{ getAuditReportStatus.action == "Add" ? "Membuat" : "Edit" }}
      Laporan Audit
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formAuditReport @get-audit-report="submit"></formAuditReport>
  </div>
</template>
              <script>
import formAuditReport from "@/components/audit/formAuditReport.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      auditFindings: [],
    };
  },
  components: {
    formAuditReport,
    DxButton,
  },
  computed: {
    ...mapGetters("auditReport", ["getAuditReportStatus"]),
  },
  methods: {
    async getAuditGroupFinding(report_id) {
      const auditGroupFinding = await this.$store.dispatch(
        "auditGroupFinding/getAuditGroupFinding",
        `?audit_report_id=${report_id}`
      );
      console.log(auditGroupFinding);
    },
    async onSubmitGroupFindings(idReport) {
      for (let i = 0; i < this.auditFindings.length; i++) {
        let params = {
          audit_report_id: idReport,
          checklist_id: this.auditFindings[i].audit_checklist.id,
          audit_finding_id: this.auditFindings[i].id,
          refrensi: this.auditFindings[i].audit_checklist.clausul_relevan,
          finding_desc: this.auditFindings[i].verification_desc,
        };

        await this.$store.dispatch(
          "auditGroupFinding/postAuditGroupFinding",
          params
        );
      }
    },
    async submit(value) {
      this.auditFindings = value.findings;
      if (this.getAuditReportStatus.action == "Add") {
        this.$store
          .dispatch("auditReport/postAuditReport", value)
          .then((res) => {
            this.onSubmitGroupFindings(res.data.id);
            router.push(`/admin/audit-report/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getAuditReportStatus.action == "Edit") {
        this.$store
          .dispatch("auditReport/editAuditReport", value)
          .then((res) => {
            if (value.audit_group_finding.length == 0) {
              this.onSubmitGroupFindings(res.data.id);
              console.log("temuan baru dijalankan");
            }
            router.push(`/admin/audit-report/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>