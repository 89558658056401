<template>
  <div>
    <h2 class="content-block">Daftar Asset</h2>

    <!-- <filter-table-unit-bisnis
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
      v-if="role == 'admin'"
    ></filter-table-unit-bisnis>

    <br /> -->

    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton hint="details" icon="more" @click="details" />
        <!-- <DxButton
          text="edit"
          hint="edit"
          icon="edit"
          @click="edit"
          v-if="role == 'user'"
        /> -->
      </dx-column>
      <dx-column data-field="asset_code" caption="Kode Asset" />
      <dx-column data-field="name" caption="Nama Asset" />
      <dx-column data-field="asset_model" caption="Model Asset" />
      <dx-column data-field="asset_brand" caption="Brand Asset" />
      <dx-column data-field="asset_image" caption="Foto Asset" />
      <dx-column data-field="condition_asset" caption="Kondisi Asset" />
      <dx-column data-field="location" caption="Lokasi Asset" />

      <!-- <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
        <template #masterDetailTemplate="{ data: risk }">
          <table-risk-identification :risk="risk.data" />
        </template> -->
    </dx-data-grid>
  </div>
</template>
          <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import DxPopup from "devextreme-vue/popup";
import router from "@/router";
// import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
// import tableRiskIdentification from "./tableRiskIdentification.vue";

import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    // FilterTableUnitBisnis,
    // tableRiskIdentification,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            // department: vthis.department || "",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "risk-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      unitBisnis: "",
      department: "",
      title: "",
      btnAdd: null,
      popupAdd: false,
      allDepartments: [],
    };
  },
  mounted() {
    this.getDepartments();
  },
  props: {
    role: {
      type: String,
    },
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
  },
  methods: {
    async details(params) {
      await this.$store.dispatch("risk/setRisk", params.row.data);
      if (this.role == "admin") {
        router.push(`/admin/risk-identification/info`);
      } else {
        router.push(`/user/risk-identification/info`);
      }
    },
    getCompany(value) {
      this.unitBisnis = value;
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        const depart = this.allDepartments;
        const departName = depart.find(
          (depart) => depart.department_id == value
        );
        this.title = departName.department_name;
      } else {
        this.title = "";
      }
      this.dataGrid.refresh();
    },
    async getDepartments() {
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        "?"
      );
      this.allDepartments = depart.data;
    },
    edit(params) {
      this.$store.dispatch("risk/setRiskStatus", "Edit");
      this.$store.dispatch("risk/setRisk", params.row.data);
      router.push(`/user/risk/form`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
      //   if (vThis.role == "user") {
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "add",
          text: "Tambah",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.$store.dispatch("risk/setRiskStatus", "Add");
            router.push(`/user/risk/form`);
          },
        },
      });
      //   }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      //   const userDetail = this.$store.getters["authentication/getUserDetail"];
      //   if (this.role == "user") {
      //     params.department = userDetail.department_id;
      //     this.title = userDetail.department_name;
      //   }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "asset/getAsset",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>
          <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  margin: 2%;
}
.table-center {
  width: 50%;
  margin: auto;
}
.link-icon {
  text-decoration: none;
  font-size: 18px;
}
</style>