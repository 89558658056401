import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditRootCause: null,
            auditRootCauseStatus: null,
        }
    },
    mutations: {
        setAuditRootCause(state, payload) {
            state.auditRootCause = payload
        },
        setAuditRootCauseStatus(state, payload) {
            state.auditRootCauseStatus = payload
        },
    },
    actions: {
        setAuditRootCause({ commit }, params) {
            commit('setAuditRootCause', params)
        },
        setAuditRootCauseStatus({ commit }, params) {
            commit('setAuditRootCauseStatus', params)
        },
        async getAuditRootCause({ commit, state }, params) {
            return await Api().get(
                `audit-root-causes${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditRootCause({ commit, state }, params) {
            return await Api().post(
                `audit-root-causes`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAuditRootCause({ commit, state }, params) {
            return await Api().put(
                `audit-root-causes/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditRootCause({ commit, state }, params) {
            return await Api().delete(
                `audit-root-causes/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditRootCause(state) {
            return state.auditRootCause;
        },
        getAuditRootCauseStatus(state) {
            return state.auditRootCauseStatus;
        },
    }
}