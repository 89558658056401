<template>
  <div>
    <h2 class="content-block">
      {{ getAuditVerificationItemStatus.action == "Add" ? "Membuat" : "Edit" }}
      Audit Item Verifikasi
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formAuditVerificationItem
      @get-audit-verification-item="submit"
    ></formAuditVerificationItem>
  </div>
</template>
              <script>
import formAuditVerificationItem from "@/components/audit/formAuditVerificationItem.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formAuditVerificationItem,
    DxButton,
  },
  data() {
    return {
      auditReport: {},
    };
  },
  computed: {
    ...mapGetters("auditVerificationItem", ["getAuditVerificationItemStatus"]),
    // ...mapGetters("auditPlan", ["getAuditPlan"]),
    ...mapGetters("auditChecklist", ["getAuditChecklist"]),
    setAuditReport() {
      this.auditReport.company_id = this.getAuditChecklist.company_id;
      this.auditReport.department_id = this.getAuditChecklist.department_id;
      this.auditReport.section_id = this.getAuditChecklist.section_id;
      this.auditReport.subsection_id = this.getAuditChecklist.subsection_id;
      this.auditReport.branch_id = this.getAuditChecklist.branch_id;
      this.auditReport.checklist_id = this.getAuditChecklist.id;
      // this.auditReport.reference = this.getAuditChecklist.clausul_relevan;
    },
  },
  mounted() {},
  methods: {
    // buat add audit report ketika status nya nc dan obs
    async submit(value) {
      if (this.getAuditVerificationItemStatus.action == "Add") {
        this.$store
          .dispatch("auditVerificationItem/postAuditVerificationItem", value)
          .then((res) => {
            // if (res.data.verification_status != "OK") {
            //   this.setAuditReport;
            //   this.auditReport.verification_item_id = res.data.id;
            //   // this.auditReport.finding_detail = res.data.verification_desc;
            //   this.addAuditReport();
            // }
            router.push(`/admin/audit-checklist/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getAuditVerificationItemStatus.action == "Edit") {
        this.$store
          .dispatch("auditVerificationItem/editAuditVerificationItem", value)
          .then((res) => {
            // if (this.getAuditVerificationItemStatus.roles == "user") {
            //   router.push(`/user/risk/info`);
            // } else if (this.getAuditVerificationItemStatus.roles == "suAdmin") {
            //   router.push(`/super-admin/risk/info`);
            // }
            router.push(`/admin/audit-checklist/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
    addAuditReport() {
      this.$store
        .dispatch("auditReport/postAuditReport", this.auditReport)
        .then((res) => {
          console.log(res.data, "audit report");
          // router.push(`/admin/audit-checklist/info`);
          // notify("Berhasil", "success", 2000);
        })
        .catch((e) => {
          console.log(e);
          notify(`Audit Report ${e.message}`, "error", 2000);
          return;
        });
    },
  },
};
</script>