<template>
  <div class="widget-container">
    <h2 class="content-block">
      {{ getRegulationListStatus.action == "Add" ? "Membuat" : "Edit" }}
      Daftar Regulasi Baru
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <div class="content-block dx-card responsive-paddings">
      <form-regulation-list @get-regulation-list="saveData" />
    </div>
  </div>
</template>
<script>
import DxButton from "devextreme-vue/button";
import formRegulationList from "@/components/regulation/formRegulationList.vue";
import notify from "devextreme/ui/notify";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formRegulationList,
    DxButton,
  },
  computed: {
    ...mapGetters("regulationList", ["getRegulationListStatus"]),
  },
  methods: {
    async saveData(params) {
      if (this.getRegulationListStatus.action == "Add") {
        params.status = "process";
        this.$store
          .dispatch("regulationList/postRegulationLists", params)
          .then((res) => {
            this.$store.dispatch(
              "regulationList/setRegulationList",
              res.data.data
            );
            this.$store.dispatch("regulationDoc/setRegulationDocStatus", {
              action: "Add",
              roles: this.getRegulationListStatus.roles,
            });
            notify("berhasil", "success", 2000);
            router.push(`/regulation-document/form`);
          })
          .catch((e) => {
            console.log(e);
            notify("Error", "error", 2000);
          });
      } else if (this.getRegulationListStatus.action == "Edit") {
        this.$store
          .dispatch("regulationList/updateRegulationLists", params)
          .then((res) => {
            notify("berhasil", "success", 2000);
            if (this.getRegulationListStatus.roles == "suAdmin") {
              router.push(`/super-admin/regulation/info`);
            } else {
              router.push(`/user/regulation/manage`);
            }
          })
          .catch((e) => {
            console.log(e);
            notify("Error", "error", 2000);
          });
      } else {
        notify("Error", "error", 2000);
        return;
      }
    },
  },
};
</script>