<template>
  <div>
    <h2 class="content-block">
      {{ getAberrationStandartStatus.action == "Add" ? "Membuat" : "Edit" }}
      Standard Penyimpangan Audit
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formAuditAberrationStandart
      @get-audit-aberration-standart="submit"
    ></formAuditAberrationStandart>
  </div>
</template>

<script>
import formAuditAberrationStandart from "@/components/audit/formAuditAberrationStandart.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formAuditAberrationStandart,
    DxButton,
  },
  computed: {
    ...mapGetters("aberrationStandart", ["getAberrationStandartStatus"]),
  },
  methods: {
    async submit(value) {
      if (this.getAberrationStandartStatus.action == "Add") {
        this.$store
          .dispatch("aberrationStandart/postAberrationStandart", value)
          .then((res) => {
            router.push(`/admin/audit-aberration-standart/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getAberrationStandartStatus.action == "Edit") {
        this.$store
          .dispatch("aberrationStandart/editAberrationStandart", value)
          .then((res) => {
            router.push(`/admin/audit-aberration-standart/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>