<template>
  <div>
    <h2 class="content-block">
      {{ title }}
    </h2>

    <filter-table-unit-bisnis
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
      v-if="!manage || roles == 'suAdmin'"
    ></filter-table-unit-bisnis>

    <br />

    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :search-value="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
      @exporting="onExporting"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton text="Details" hint="details" icon="more" @click="details" />
        <DxButton hint="edit" icon="edit" @click="edit" v-if="manage" />
        <DxButton
          hint="hapus"
          icon="trash"
          @click="popupDestroy"
          v-if="manage && roles == 'suAdmin'"
        />
      </dx-column>
      <dx-column data-field="regulation_number" caption="Nomor Regulasi" />
      <dx-column data-field="type_regulation" caption="Jenis Peraturan" />
      <dx-column
        data-field="status"
        caption="Status"
        cell-template="grid-cell"
        width="100"
      />
      <template #grid-cell="{ data }">
        <div
          :class="{
            done: data.value == 'done',
            process: data.value == 'process',
          }"
        >
          {{ data.value }}
        </div>
      </template>
      <dx-column data-field="regulation_topic" caption="Topik Regulator" />

      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: regulation }">
        <table-regulation-doc
          :regulation="regulation.data"
          :manage="manage"
          :roles="roles"
        />
      </template>
    </dx-data-grid>

    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
  
  <script>
import tableRegulationDoc from "./tableRegulationDocument.vue";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
  DxSelection,
} from "devextreme-vue/data-grid";
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";

import { DxTextArea } from "devextreme-vue/text-area";
import { DxItem } from "devextreme-vue/form";
import { DxTextBox } from "devextreme-vue/text-box";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";
import "whatwg-fetch";
import notify from "devextreme/ui/notify";
import { mapGetters, mapActions } from "vuex";

import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxTextArea,
    DxSearchPanel,
    DxButton,
    DxTextBox,
    FilterTableUnitBisnis,
    DxMasterDetail,
    tableRegulationDoc,
    Workbook,
    saveAs,
    DxSelection,
    DxButtonx,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            department: vthis.department || "",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "regula-list-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      unitBisnis: "",
      department: "",
      btnAdd: null,
      title: false,
      export: [],
      exportList: [],
      exportDetail: [],
      allDepartments: [],
      popupDelete: false,
      deleteRegulation: {},
    };
  },
  props: {
    manage: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.getDepartments();
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },

    ...mapGetters("regulationList", ["getRegulationList"]),
    ...mapGetters("authentication", ["getUser"]),
  },
  methods: {
    async getExport() {
      const dataExport = await this.$store.dispatch(
        "regulationList/getRegulationLists",
        "/export"
      );
      return await dataExport.data;
    },
    async getDepartments() {
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        "?"
      );
      this.allDepartments = depart.data;
    },
    async onExporting(e) {
      const data = await this.getExport();

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Regulation_list");

      worksheet.columns = [
        {
          header: "Department",
          key: "department_id",
          width: 30,
        },
        {
          header: "No Peraturan dan Judul",
          key: "regulation_number",
          width: 40,
        },
        {
          header: "Persentase / No Peraturan",
          key: "persentase_detail",
          width: 15,
          style: { numFmt: "0%" },
        },
        { header: "Bab", key: "chapter" },
        { header: "Pasal", key: "article" },
        { header: "Ayat", key: "verse" },
        { header: "Inti Sari", key: "summary", width: 50 },
        {
          header: "Bukti Yang Harus Dipenuhi",
          key: "total_impl",
          width: 15,
        },
        { header: "SUDAH", key: "done" },
        { header: "PROSES", key: "process" },
        { header: "DITOLAK", key: "reject" },
        { header: "Bukti Yang Diunggah", key: "actual_impl", width: 15 },
        {
          header: "Persentase Pemenuhan",
          key: "percentage",
          width: 15,
          style: { numFmt: "0%" },
        },
      ];

      // worksheet.getRow(1).font = {
      //   bold: true,
      //   size: 12,
      // };
      worksheet.getRow(1).alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };

      [
        "A1",
        "B1",
        "C1",
        "D1",
        "E1",
        "F1",
        "G1",
        "H1",
        "I1",
        "J1",
        "K1",
        "L1",
        "M1",
      ].map((key) => {
        worksheet.getCell(key).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
        };
        worksheet.getCell(key).font = {
          bold: true,
          size: 12,
        };
      });

      worksheet.columns.forEach((column) => {
        column.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      // worksheet.getColumn("order").alignment = {
      //   vertical: "middle",
      //   horizontal: "center",
      // };
      worksheet.getColumn("chapter").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getColumn("article").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getColumn("verse").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getColumn("total_impl").alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      worksheet.getColumn("done").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getColumn("process").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getColumn("reject").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getColumn("actual_impl").alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };

      let start = 1;
      const depart = this.allDepartments;
      for (let index = 0; index < data.length; index++) {
        let num = start + index;

        const departName = depart.find(
          (depart) => depart.department_id == data[index].department_id
        );
        const row = worksheet.getRow(num + 1);
        // row.getCell("order").value = index + 1;

        if (index > 0) {
          let name = data[index].department_id;
          if (name != data[index - 1].department_id) {
            row.getCell("department_id").value = departName.department_name;
            // row.getCell("order").value = "-";
          }
        } else {
          row.getCell("department_id").value = departName.department_name;
          // row.getCell("order").value = "-";
        }

        // row.getCell("department_id").value = departName.department_name;
        row.getCell("regulation_number").value = data[index].regulation_number;
        row.getCell("persentase_detail").value =
          data[index].done_count / data[index].details_count;
        if (data[index].details_count < 1) {
          row.getCell("persentase_detail").value = 0;
        }
        if (data[index].details.length > 0) {
          start = start + (data[index].details.length - 1);
          const details = data[index].details;
          for (let b = 0; b < details.length; b++) {
            let n = b + 1;
            const rowDetail = worksheet.getRow(num + n);

            rowDetail.getCell("chapter").value = details[b].chapter;
            rowDetail.getCell("article").value = details[b].article;
            rowDetail.getCell("verse").value = details[b].verse;
            rowDetail.getCell("summary").value = details[b].summary;
            rowDetail.getCell("total_impl").value = details[b].total_impl;
            rowDetail.getCell("done").value = details[b].impl_true;
            rowDetail.getCell("process").value = details[b].impl_null;
            rowDetail.getCell("reject").value = details[b].impl_false;
            rowDetail.getCell("actual_impl").value = details[b].actual_impl;
            rowDetail.getCell("percentage").value =
              details[b].impl_true / details[b].total_impl;
          }
        }
      }

      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "regulation report.xlsx"
        );
      });
    },
    onToolbarPreparing(e) {
      const vThis = this;
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
      if (this.manage) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.$store.dispatch("regulationList/setRegulationListStatus", {
                action: "Add",
                roles: vThis.roles,
              });
              router.push("/regulation/form");
            },
          },
        });
      } else if (this.roles == "admin") {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            icon: "export",
            text: "Reports",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.onExporting();
            },
          },
        });
      }
    },
    getCompany(value) {
      this.unitBisnis = value;
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        const depart = this.allDepartments;
        const departName = depart.find(
          (depart) => depart.department_id == value
        );
        this.title = departName.department_name;
      } else {
        this.title = "";
      }
      this.dataGrid.refresh();
    },
    confirmDestroy() {
      this.$store
        .dispatch(
          "regulationList/destroyRegulationLists",
          this.deleteRegulation
        )
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteRegulation = params.row.data;
    },
    async details(params) {
      await this.$store.dispatch(
        "regulationList/setRegulationList",
        params.row.data
      );
      if (this.manage && this.roles == "user") {
        router.push(`/user/regulation-detail/manage`);
        return;
      } else if (this.manage && this.roles == "suAdmin") {
        router.push(`/super-admin/regulation-detail/info`);
        return;
      } else if (this.roles == "admin") {
        router.push(`/admin/regulation-detail`);
        return;
      } else {
        router.push(`/user/regulation-detail`);
      }
    },
    async edit(params) {
      if (params.row.data.status == "done" && this.roles == "user") {
        notify("Data regulation sudah done!", "warning", 2000);
        return;
      }
      await this.$store.dispatch(
        "regulationList/setRegulationList",
        params.row.data
      );
      await this.$store.dispatch("regulationList/setRegulationListStatus", {
        action: "Edit",
        roles: this.roles,
      });
      router.push("/regulation/form");
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      const userDetail = this.$store.getters["authentication/getUserDetail"];
      if (this.manage && this.roles == "user") {
        params.department = userDetail.department_id;
        this.title = "Kelola Regulasi Pemerintah " + userDetail.department_name;
      } else if (this.manage && this.roles == "suAdmin") {
        this.title = "Semua Daftar Regulasi Pemerintah";
      } else if (this.roles == "admin") {
        params.impl_null = "verified";
        this.title = "Daftar Verifikasi Regulasi Pemerintah";
      } else {
        this.title = "Daftar Regulasi Pemerintah";
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "regulationList/getRegulationLists",
        text.replace(/['"]+/g, "")
      );
      this.export = await results;
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>
<style scoped>
.done {
  background-color: green;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.process {
  background-color: yellow;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}

.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
</style>