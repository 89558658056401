<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskIdentification" @submit.prevent="handleSubmit">
        <DxTextArea
          class="custom-form"
          label="Kegagalan / Risiko"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="identification.risk_failure"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <DxTextArea
          class="custom-form"
          label="Identifikasi / Analisa Faktor Internal "
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="identification.risk_couse_internal"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <DxTextArea
          class="custom-form"
          label="Identifikasi / Analisa Faktor Eksternal"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="identification.risk_couse_external"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <DxTextArea
          class="custom-form"
          label="Dampak Dari Risiko Ke Internal"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="identification.risk_impact_internal"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <DxTextArea
          class="custom-form"
          label="Dampak Dari Risiko Ke Eksternal"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="identification.risk_impact_external"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
              
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      submitButtonOptions: {
        text: "Submit the Form",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      identification: {},
      //   itemOptions: [],
    };
  },
  mounted() {
    if (this.getRiskIdentificationStatus.action == "Edit") {
      this.identification = this.getRiskIdentification;
    }
  },
  emits: ["get-identification"],
  computed: {
    ...mapGetters("risk", ["getRisk", "getRiskStatus"]),
    ...mapGetters("riskIdentification", [
      "getRiskIdentification",
      "getRiskIdentificationStatus",
    ]),
  },
  methods: {
    handleSubmit(e) {
      this.identification.risk_id = this.getRisk.id;
      this.loading = true;
      this.$emit("get-identification", this.identification);
      // this.loading = false;
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
    // FilterTableUnitBisnis,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>