<template>
  <div>
    <table-document :doc-type="'FORM'" :role="'admin'"></table-document>
  </div>
</template>
  <script>
import tableDocument from "@/components/documentManagement/tableDocument.vue";

export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  components: {
    tableDocument,
  },
};
</script>