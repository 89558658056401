import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditCorrection: null,
            auditCorrectionStatus: null,
        }
    },
    mutations: {
        setAuditCorrection(state, payload) {
            state.auditCorrection = payload
        },
        setAuditCorrectionStatus(state, payload) {
            state.auditCorrectionStatus = payload
        },
    },
    actions: {
        setAuditCorrection({ commit }, params) {
            commit('setAuditCorrection', params)
        },
        setAuditCorrectionStatus({ commit }, params) {
            commit('setAuditCorrectionStatus', params)
        },
        async getAuditCorrection({ commit, state }, params) {
            return await Api().get(
                `audit-corrections${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditCorrection({ commit, state }, params) {
            return await Api().post(
                `audit-corrections`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAuditCorrection({ commit, state }, params) {
            return await Api().put(
                `audit-corrections/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditCorrection({ commit, state }, params) {
            return await Api().delete(
                `audit-corrections/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditCorrection(state) {
            return state.auditCorrection;
        },
        getAuditCorrectionStatus(state) {
            return state.auditCorrectionStatus;
        },
    }
}