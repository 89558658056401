import Api from '@/services/Api';
import CryptoJS from 'crypto-js';

export default {
    namespaced: true,
    state() {
        return {
            branch: null,
            allBranch: localStorage.getItem('m_bran') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('m_bran'), 'secretKey').toString(CryptoJS.enc.Utf8)) : null,
            branchStatus: null,
        }
    },
    mutations: {
        setBranch(state, payload) {
            state.branch = payload
        },
        setBranchStatus(state, payload) {
            state.branchStatus = payload
        },
        setAllBranch(state, payload) {
            state.allBranch = payload
        },
    },
    actions: {
        setBranch({ commit }, params) {
            commit('setBranch', params)
        },
        setBranchStatus({ commit }, params) {
            commit('setBranchStatus', params)
        },
        async getAllBranch({ commit, state }, params) {
            return await Api().get(
                `branchs${params}`,
            ).then((res) => {
                // set localstorage and generate
                localStorage.setItem('m_bran', CryptoJS.AES.encrypt(JSON.stringify(res.data.data), 'secretKey').toString());

                commit('setAllBranch', res.data.data)
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getBranch({ commit, state }, params) {
            return await Api().get(
                `branchs${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postBranch({ commit, state }, params) {
            return await Api().post(
                `branchs`, params
            ).then((res) => {
                return res.data
            })
        },
        async editBranch({ commit, state }, params) {
            return await Api().put(
                `branchs/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyBranch({ commit, state }, params) {
            return await Api().delete(
                `branchs/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getBranch(state) {
            return state.branch;
        },
        getAllBranch(state) {
            return state.allBranch;
        },
        getBranchStatus(state) {
            return state.branchStatus;
        },
    }
}