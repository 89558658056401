<template>
  <div class="widget-container">
    <div class="content-block dx-card responsive-paddings">
      <DxSelectBox
        :data-source="unitBisnisOptions"
        label="Pilih Unit Bisnis"
        value-expr="id"
        display-expr="name"
        :is-required="true"
        v-model.value="valueUnitBisnis"
        @valueChanged="changeUnitBisnis()"
      >
      </DxSelectBox>
      <br />
      <DxSelectBox
        :data-source="departmentOptions"
        label="Pilih Department"
        value-expr="department_id"
        display-expr="department_name"
        :is-required="true"
        :read-only="readOnlyDept"
        v-model.value="valueDepartment"
        @valueChanged="changeDepartment()"
      >
      </DxSelectBox>
      <DxButton
        v-if="!readOnlyDept"
        class="custom-form"
        type="default"
        icon="clearsquare"
        text="clear"
        hint="Reset"
        stylingMode="outlined"
        @click="clear()"
      />
    </div>
  </div>
</template>

<script>
import { DxSelectBox } from "devextreme-vue/select-box";
import DxButton from "devextreme-vue/button";

export default {
  data() {
    return {
      unitBisnisOptions: "",
      departmentOptions: "",
      valueUnitBisnis: "",
      valueDepartment: "",
      readOnlyDept: true,
    };
  },
  mounted() {
    this.unitBisnis();
  },
  emits: ["get-department", "get-unit-bisnis"],
  methods: {
    clear() {
      this.valueUnitBisnis = "";
      this.valueDepartment = "";
    },
    changeDepartment() {
      this.$emit("get-department", this.valueDepartment);
    },
    async changeUnitBisnis() {
      if (this.valueUnitBisnis) {
        const text = `?company=${this.valueUnitBisnis}`;
        const depart = await this.$store.dispatch(
          "department/getDepartments",
          text
        );
        this.departmentOptions = depart.data;
        this.readOnlyDept = false;
      } else {
        this.readOnlyDept = true;
      }
      this.valueDepartment = "";
      this.$emit("get-unit-bisnis", this.valueUnitBisnis);
      return;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
  },
  components: {
    DxSelectBox,
    DxButton,
  },
};
</script>
<style scoped>
.custom-form {
  margin-top: 20px;
}
</style>