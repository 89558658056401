import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            riskControl: null,
            riskControlStatus: null,
        }
    },
    mutations: {
        setRiskControl(state, payload) {
            state.riskControl = payload
        },
        setRiskControlStatus(state, payload) {
            state.riskControlStatus = payload
        },
    },
    actions: {
        setRiskControl({ commit }, params) {
            commit('setRiskControl', params)
        },
        setRiskControlStatus({ commit }, params) {
            commit('setRiskControlStatus', params)
        },
        async getRiskControl({ commit, state }, params) {
            return await Api().get(
                `risk-controls${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRiskControl({ commit, state }, params) {
            return await Api().post(
                `risk-controls`, params
            ).then((res) => {
                return res.data
            })
        },
        async editRiskControl({ commit, state }, params) {
            return await Api().put(
                `risk-controls/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyRiskControl({ commit, state }, params) {
            return await Api().delete(
                `risk-controls/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getRiskControl(state) {
            return state.riskControl;
        },
        getRiskControlStatus(state) {
            return state.riskControlStatus;
        },
    }
}