<template>
  <div>
    <tableDocFile :role="'admin'"></tableDocFile>
  </div>
</template>
  
  <script>
import DxButton from "devextreme-vue/button";
import tableDocFile from "@/components/documentManagement/tableDocFile.vue";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";

export default {
  data() {
    return {
      gridRef: "detail-document-table",
      document: "",
    };
  },
  methods: {},
  components: {
    DxButton,
    tableDocFile,
  },
};
</script>