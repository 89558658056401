<template>
  <div>
    <div class="widget-container">
      <h2 class="content-block">
        {{ getDocumentFileStatus == "Add" ? "Membuat" : "Edit" }} Dokumen
      </h2>
      <DxButton
        class="content-block"
        type="default"
        icon="back"
        hint="kembali"
        @click="this.$router.back()"
      />
      <div class="content-block dx-card responsive-paddings">
        <formDocumentFile @get-doc-file="handleSubmit"></formDocumentFile>
      </div>
    </div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import formDocumentFile from "@/components/documentManagement/formDocumentFile.vue";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

import router from "@/router";

export default {
  components: {
    DxButton,
    formDocumentFile,
  },
  data() {
    return {
      documentFile: {},
      doc_number: false,
      file: "",
      formRef: "form-document-file",
      document: {},
      departApproval: [],
    };
  },
  computed: {
    ...mapGetters("docFile", ["getDocumentFile", "getDocumentFileStatus"]),
    ...mapGetters("document", ["getDocument"]),
  },
  mounted() {},
  methods: {
    async handleSubmit(params) {
      if (this.getDocumentFileStatus == "Add") {
        await this.getRelatedDoc();

        this.$store.dispatch("docFile/postDocFiles", params).then((res) => {
          this.postDocApproval(res.data.id);

          notify("berhasil", "success", 2000);
          router.push(`/admin/document/doc-file`);
        });
      } else if (
        this.getDocumentFileStatus == "Edit" ||
        this.getDocumentFileStatus == "EditExpired"
      ) {
        if (this.getDocumentFileStatus == "EditExpired") {
          this.destroyDocNotif(params.document_id);
        }
        params._method = "PUT";
        this.$store.dispatch("docFile/updateDocFiles", params).then((res) => {
          notify("berhasil", "success", 2000);
          router.push(`/admin/document/doc-file`);
        });
      } else {
        console.log("Error System");
      }
    },
    async postDocApproval(doc_file = "") {
      for (let i = 0; i < this.departApproval.length; i++) {
        let params = {
          doc_file_id: doc_file,
          department_id: this.departApproval[i],
        };
        await this.$store.dispatch("docApproval/postDocApproval", params);
      }
    },
    async getRelatedDoc() {
      const doc = this.getDocument;

      if (doc.related_department != null) {
        this.departApproval = doc.related_department.split(",");
      }
      const check = this.departApproval.find((dep) => dep == doc.department_id);
      if (!check) {
        this.departApproval.push(doc.department_id);
      }
    },
    destroyDocNotif(params) {
      const text = `?document=${params}`;
      this.$store.dispatch("docFile/getDocNotif", text).then((res) => {
        if (res.data) {
          this.$store
            .dispatch("docFile/destroyDocNotif", res.data[0])
            .then((res) => {
              console.log("data notif dihapus untuk perpanjang");
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
.main-block {
  max-width: 900px;
  min-width: 700px;
}
</style>