import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            aberrationStandart: null,
            aberrationStandartStatus: null,
        }
    },
    mutations: {
        setAberrationStandart(state, payload) {
            state.aberrationStandart = payload
        },
        setAberrationStandartStatus(state, payload) {
            state.aberrationStandartStatus = payload
        },
    },
    actions: {
        setAberrationStandart({ commit }, params) {
            commit('setAberrationStandart', params)
        },
        setAberrationStandartStatus({ commit }, params) {
            commit('setAberrationStandartStatus', params)
        },
        async getAberrationStandart({ commit, state }, params) {
            return await Api().get(
                `aberration-standarts${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAberrationStandart({ commit, state }, params) {
            return await Api().post(
                `aberration-standarts`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAberrationStandart({ commit, state }, params) {
            return await Api().put(
                `aberration-standarts/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAberrationStandart({ commit, state }, params) {
            return await Api().delete(
                `aberration-standarts/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAberrationStandart(state) {
            return state.aberrationStandart;
        },
        getAberrationStandartStatus(state) {
            return state.aberrationStandartStatus;
        },
    }
}