<template>
  <div>
    <div>
      <tableAuditAberrationStandart
        :roles="'admin'"
      ></tableAuditAberrationStandart>
    </div>
  </div>
</template>
      
  <script>
import tableAuditAberrationStandart from "@/components/audit/tableAuditAberrationStandart.vue";
export default {
  components: {
    tableAuditAberrationStandart,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>