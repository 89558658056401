<template>
  <div>
    <table-regulation-detail
      :manage="true"
      roles="suAdmin"
    ></table-regulation-detail>
  </div>
</template>
        
        <script>
import tableRegulationDetail from "@/components/regulation/tableRegulationDetail.vue";
import router from "@/router";
import axios from "axios";
import "whatwg-fetch";
export default {
  components: {
    tableRegulationDetail,
  },
  data() {
    return {
      btnAdd: null,
      title: "",
      detail: {},
    };
  },
  mounted() {},
  methods: {},
};
</script>