import Api from '@/services/Api';
import axios from 'axios';
import Cookie from 'js-cookie'

export default {
    namespaced: true,
    state() {
        return {
            regulationList: null,
            regulationListStatus: null,
        }
    },
    mutations: {
        setRegulationList(state, payload) {
            state.regulationList = payload
        },
        setRegulationListStatus(state, payload) {
            state.regulationListStatus = payload
        },
    },
    actions: {
        setRegulationList({ commit }, params) {
            commit('setRegulationList', params)
        },
        setRegulationListStatus({ commit }, params) {
            commit('setRegulationListStatus', params)
        },
        async getRegulationLists({ commit, state }, params) {
            return await Api().get(
                `regulation-lists${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },

        async getRegulationList({ commit, state }, params) {
            return await Api().get(
                `regulation-lists/${params.id}`, {
                headers: { Authorization: `Bearer ${Cookie.get('token')}` },
            }
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRegulationLists({ }, params) {
            return await Api().post(
                "regulation-lists", params
            )
        },
        async updateRegulationLists({ }, params) {
            return await Api().put(
                `regulation-lists/${params.id}`, params
            )
        },
        async destroyRegulationLists({ }, params) {
            return await Api().delete(
                `regulation-lists/${params.id}`, params
            )
        },
        async getTotalStatus({ commit, state }, params) {
            return await Api().get(
                `regulation-lists/dashboard${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
    },
    getters: {
        getRegulationList(state) {
            return state.regulationList;
        },
        getRegulationListStatus(state) {
            return state.regulationListStatus;
        },
    }
}