import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            documentFile: null,
            documentFileStatus: null,
            doc: null
        }
    },
    mutations: {
        setDocumentFile(state, payload) {
            state.documentFile = payload
        },
        setDocumentFileStatus(state, payload) {
            state.documentFileStatus = payload
        },
        setDoc(state, payload) {
            state.doc = payload
        },
        cleanDocumentFile(state) {
            state.documentFile = null
        },
        cleanDoc(state) {
            state.doc = null
        },
    },
    actions: {
        setDoc({ commit }, params) {
            commit('setDoc', params)
        },
        cleanDoc({ commit }, params) {
            commit('cleanDoc', params)
        },
        setDocumentFile({ commit }, params) {
            commit('setDocumentFile', params)
        },
        setDocumentFileStatus({ commit }, params) {
            commit('setDocumentFileStatus', params)
        },
        cleanDocumentFile({ commit }) {
            commit('cleanDocumentFile')
        },
        async getDocFiles({ commit, state }, params) {
            return await Api().get(
                `doc-files${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getDocFile({ commit, state }, params) {
            return await Api()
                .get(`doc-files/${params.id}`).then((res) => {
                    commit('setData', res.data)
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async postDocFiles({ }, params) {
            return await Api()
                .post('doc-files', params, { headers: { "Content-Type": "multipart/form-data" }, }).then((res) => {
                    return res.data
                })
        },
        async updateDocFiles({ }, params) {
            return await Api()
                .post(`doc-files/${params.id}`, params, { headers: { "Content-Type": "multipart/form-data" }, }).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async destroyDocFiles({ }, params) {
            return await Api()
                .delete(`doc-files/${params.id}`, params).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async getDocNotif({ commit, state }, params) {
            return await Api().get(
                `notif-doc-files${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async destroyDocNotif({ }, params) {
            return await Api()
                .delete(`notif-doc-files/${params.id}`).then((res) => {
                    return res.data
                })
        },
        async downloadFile({ commit, state }, params) {
            return await Api()
                .get(`/doc-files/download/${params.name_file}`).then((res) => {
                    return res
                })
        },
    },
    getters: {
        getDocumentFile(state) {
            return state.documentFile;
        },
        getDocumentFileStatus(state) {
            return state.documentFileStatus;
        },
        getDoc(state) {
            return state.doc;
        },
    }
}