<template>
  <div>
    <div>
      <auditPlan :roles="'user'"></auditPlan>
    </div>
  </div>
</template>
    
<script>
import auditPlan from "@/components/audit/auditPlan";
import router from "@/router";
export default {
  components: {
    auditPlan,
  },
  data() {
    return {};
  },
};
</script>