import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            result: [],
            detail: []
        }
    },
    mutations: {
        setData(state, payload) {
            state.result = payload
        },
        setDetail(state, payload) {
            state.detail = payload
        },
    },
    actions: {
        async getdetailByDocAccess({ commit, state }, params) {
            console.log(params);
            return await Api()
                .get(`doc-access-details/access_right/${params.id}`).then((res) => {
                    commit('setDetail', res.data)
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async getDocumentAccessRights({ commit, state }, params) {
            return await Api()
                .get(`doc-access-rights?page=${params.page}&limit=${params.perPage}&sorted_by=${params.sortedBy}&sort=${params.sort}&search=${params.filterValue}`).then((res) => {
                    commit('setData', res.data)
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        }
    },
    getters: {
        getRegulations(state) {
            return state.result
        }
    }
}