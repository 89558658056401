<template>
  <div class="widget-container">
    <h2 class="content-block">Mengunggah Dokumen Regulasi</h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <div class="content-block dx-card responsive-paddings">
      <form-regulation-document @get-regulation-doc="saveData" />
    </div>
  </div>
</template>
  <script>
import DxButton from "devextreme-vue/button";
import formRegulationDocument from "@/components/regulation/formRegulationDocument.vue";
import notify from "devextreme/ui/notify";
import { mapGetters, mapActions } from "vuex";

import router from "@/router";

export default {
  components: {
    formRegulationDocument,
    DxButton,
  },
  computed: {
    ...mapGetters("regulationDoc", ["getRegulationDocStatus"]),
  },
  methods: {
    async saveData(params) {
      if (this.getRegulationDocStatus.action == "Add") {
        this.$store
          .dispatch("regulationDoc/postRegulationDocuments", params)
          .then((res) => {
            this.$store.dispatch("regulationDetail/setRegulationDetailStatus", {
              action: "Add",
              roles: this.getRegulationDocStatus.roles,
            });
            notify("berhasil", "success", 2000);
            router.push(`/regulation-detail/form`);
          })
          .catch((e) => {
            console.log(e);
            notify("gagal", "error", 2000);
          });
      } else if (this.getRegulationDocStatus.action == "Edit") {
        this.$store
          .dispatch("regulationDoc/updateRegulationDocuments", params)
          .then((res) => {
            notify("berhasil", "success", 2000);
            if (this.getRegulationDocStatus.roles == "suAdmin") {
              router.push(`/super-admin/regulation/info`);
            } else {
              router.push(`/user/regulation/manage`);
            }
          })
          .catch((e) => {
            console.log(e);
            notify("gagal", "error", 2000);
          });
      }
    },
  },
};
</script>