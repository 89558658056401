import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditReport: null,
            auditReportStatus: null,
        }
    },
    mutations: {
        setAuditReport(state, payload) {
            state.auditReport = payload
        },
        setAuditReportStatus(state, payload) {
            state.auditReportStatus = payload
        },
    },
    actions: {
        setAuditReport({ commit }, params) {
            commit('setAuditReport', params)
        },
        setAuditReportStatus({ commit }, params) {
            commit('setAuditReportStatus', params)
        },
        async getFindingTypeCount({ commit, state }, params) {
            return await Api().get(
                `finding-type-count${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getAuditReport({ commit, state }, params) {
            return await Api().get(
                `audit-reports${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditReport({ commit, state }, params) {
            return await Api().post(
                `audit-reports`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAuditReport({ commit, state }, params) {
            return await Api().put(
                `audit-reports/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditReport({ commit, state }, params) {
            return await Api().delete(
                `audit-reports/${params.id}`
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditGroupFinding({ commit, state }, params) {
            return await Api().delete(
                `audit-group-findings/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditReport(state) {
            return state.auditReport;
        },
        getAuditReportStatus(state) {
            return state.auditReportStatus;
        },
    }
}