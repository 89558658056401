<template>
  <div>
    <h2 class="content-block">
      {{
        getRiskScoreStatus.action == "addControl"
          ? "Penilaian Pengendalian Risiko"
          : "Penilaian Tingkat Keparahan"
      }}
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <FormRiskScore @get-risk-score="submit"></FormRiskScore>
  </div>
</template>
        <script>
import FormRiskScore from "@/components/riskManagement/formRiskScore.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    FormRiskScore,
    DxButton,
  },
  computed: {
    ...mapGetters("riskScore", ["getRiskScoreStatus"]),
  },
  methods: {
    async submit(value) {
      if (
        this.getRiskScoreStatus.action == "addControl" ||
        this.getRiskScoreStatus.action == "addSeverity"
      ) {
        value.forEach((res) => {
          this.$store
            .dispatch("riskScore/postRiskScore", res)
            .then((res) => {})
            .catch((e) => {
              console.log(e);
              notify(e.message, "error", 2000);
              return;
            });
        });
        if (this.getRiskScoreStatus.roles == "user") {
          router.push(`/user/risk-identification/info`);
        } else if (this.getRiskScoreStatus.roles == "suAdmin") {
          router.push(`/super-admin/risk-identification/info`);
        }
        notify("Berhasil", "success", 2000);
      } else if (
        this.getRiskScoreStatus.action == "editControl" ||
        this.getRiskScoreStatus.action == "editSeverity"
      ) {
        this.$store
          .dispatch("riskScore/editRiskScore", value[0])
          .then((res) => {
            if (this.getRiskScoreStatus.roles == "admin") {
              router.push(`/admin/risk-identification/info`);
            } else if (this.getRiskScoreStatus.roles == "suAdmin") {
              router.push(`/super-admin/risk-identification/info`);
            } else {
              router.push(`/user/risk-identification/info`);
            }
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>