<template>
  <div>
    <h2 class="content-block">
      {{ getCodeDepartmentStatus == "Add" ? "Membuat" : "Edit" }} Kode
      Department
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formDepartmentCode @get-department-code="submit"></formDepartmentCode>
  </div>
</template>
  <script>
import formDepartmentCode from "@/components/master/formDepartmentCode.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formDepartmentCode,
    DxButton,
  },
  computed: {
    ...mapGetters("department", ["getCodeDepartmentStatus"]),
  },
  methods: {
    async submit(value) {
      if (this.getCodeDepartmentStatus == "Add") {
        this.$store
          .dispatch("department/postCodeDepartment", value)
          .then((res) => {
            router.push(`/super-admin/code/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getCodeDepartmentStatus == "Edit") {
        this.$store
          .dispatch("department/editCodeDepartment", value)
          .then((res) => {
            router.push(`/super-admin/code/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>