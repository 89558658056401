<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addProcess" @submit.prevent="handleSubmit">
        <DxSelectBox
          class="custom-form"
          :data-source="checklistOption"
          label="Pilih Clausul Relevan"
          value-expr="id"
          display-expr="clausul_relevan"
          :is-required="true"
          v-model="processList.checklist_id"
          :search-enabled="true"
          item-template="item"
        >
          <template #item="{ data: item }">
            <div class="custom-item">
              <div class="product-name">
                <b
                  >{{ item.clausul_relevan }} (
                  {{ departmentName({ value: item.department_id }) }} )</b
                >
              </div>
            </div>
          </template>
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextArea
          class="custom-form"
          label="Nama Proses"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="processList.name"
        >
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      processList: {},
      readOnly: false,
      checklistOption: [],
    };
  },
  computed: {
    ...mapGetters("processList", ["getProcessList", "getProcessListStatus"]),
    ...mapGetters("auditChecklist", ["getAuditChecklist"]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  mounted() {
    if (this.getProcessListStatus.action == "Edit") {
      this.processList = this.getProcessList;
    }
    this.getChecklist();
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  emits: ["get-process"],
  methods: {
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    async getChecklist() {
      const text = `?`;
      const checklist = await this.$store.dispatch(
        "auditChecklist/getAuditChecklist",
        text
      );
      this.checklistOption = checklist.data;
    },
    async handleSubmit(e) {
      this.loading = true;
      if (this.getProcessListStatus.action == "Add") {
        this.processList.checklist_id = this.getAuditChecklist.id;
      }
      this.$emit("get-process", this.processList);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>