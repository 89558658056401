<template>
  <div>
    <div>
      <tableRiskSeverity :role="'suAdmin'"></tableRiskSeverity>
    </div>
  </div>
</template>
        
        <script>
import tableRiskSeverity from "@/components/riskManagement/tableRiskSeverity.vue";
import router from "@/router";
export default {
  components: {
    tableRiskSeverity,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>