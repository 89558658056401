<template>
  <div>
    <div>
      <tableRegulationList
        :manage="true"
        :roles="'suAdmin'"
      ></tableRegulationList>
    </div>
  </div>
</template>
  
  <script>
import tableRegulationList from "@/components/regulation/tableRegulationList.vue";
import router from "@/router";
export default {
  components: {
    tableRegulationList,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>