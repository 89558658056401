import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            improvementScore: null,
            improvementScoreStatus: null,
        }
    },
    mutations: {
        setImprovementScore(state, payload) {
            state.improvementScore = payload
        },
        setImprovementScoreStatus(state, payload) {
            state.improvementScoreStatus = payload
        },
    },
    actions: {
        setImprovementScore({ commit }, params) {
            commit('setImprovementScore', params)
        },
        setImprovementScoreStatus({ commit }, params) {
            commit('setImprovementScoreStatus', params)
        },
        async getImprovementScore({ commit, state }, params) {
            return await Api().get(
                `improvement-scores${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postImprovementScore({ commit, state }, params) {
            return await Api().post(
                `improvement-scores`, params
            ).then((res) => {
                return res.data
            })
        },
        async editImprovementScore({ commit, state }, params) {
            return await Api().put(
                `improvement-scores/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyImprovementScore({ commit, state }, params) {
            return await Api().delete(
                `improvement-scores/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getImprovementScore(state) {
            return state.improvementScore;
        },
        getImprovementScoreStatus(state) {
            return state.improvementScoreStatus;
        },
    }
}