<template>
  <div>
    <h2 class="content-block">
      {{ getPotentialRiskStatus.action == "Add" ? "Membuat" : "Edit" }}
      Potensi Risiko
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formPotentialRisk @get-potential-risk="submit"></formPotentialRisk>
  </div>
</template>
              <script>
import formPotentialRisk from "@/components/audit/formPotentialRisk.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formPotentialRisk,
    DxButton,
  },
  computed: {
    ...mapGetters("potentialRisk", ["getPotentialRiskStatus"]),
  },
  methods: {
    async submit(value) {
      if (this.getPotentialRiskStatus.action == "Add") {
        this.$store
          .dispatch("potentialRisk/postPotentialRisk", value)
          .then((res) => {
            // this.$store.dispatch("auditPlan/setRisk", res.data);
            // this.$store.dispatch(
            //   "riskIdentification/setRiskIdentificationStatus",
            //   { action: "Add", roles: this.getPotentialRiskStatus.roles }
            // );
            router.push(`/admin/audit-checklist/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getPotentialRiskStatus.action == "Edit") {
        this.$store
          .dispatch("potentialRisk/editPotentialRisk", value)
          .then((res) => {
            // if (this.getPotentialRiskStatus.roles == "user") {
            //   router.push(`/user/risk/info`);
            // } else if (this.getPotentialRiskStatus.roles == "suAdmin") {
            //   router.push(`/super-admin/risk/info`);
            // }
            router.push(`/admin/audit-checklist/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>