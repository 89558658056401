<template>
  <div>
    <h3 class="content-block">Related Department :</h3>
    <div class="content-block dx-card table-center">
      <DxDataGrid
        :data-source="departments"
        :show-borders="true"
        :column-auto-width="true"
        key-expr="department_id"
        :remote-operations="true"
        :ref="gridRef"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :show-info="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />

        <dx-column data-field="department_name" />
        <dx-column data-field="company_name" />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
  },
  props: ["templateData"],
  data() {
    return {
      pageSizes: [5, 10, 15],
      gridRef: "related-table",
      department_id: this.related,
      departments: [],
      only: false,
    };
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    related() {
      if (!this.templateData.related_department) {
        return null;
      }
      return this.templateData.related_department.split(",");
    },
  },
  mounted() {
    this.getDepartment();
  },
  methods: {
    async getDepartment() {
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        "?"
      );
      let data = depart.data;
      let setDepart = this.related;
      if (setDepart !== null) {
        setDepart.forEach((e) => {
          this.departments.push(data.find((d) => d.department_id == e));
          this.dataGrid.refresh();
        });
      }
    },
  },
};
</script>

<style scoped>
.table-center {
  width: 60%;
  margin: auto;
}
</style>