<template>
  <div>
    <h2 class="content-block">
      {{ getPicChecklistStatus.action == "Add" ? "Membuat" : "Edit" }}
      Pic Checklist
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formPicChecklist @get-pic-checklist="submit"></formPicChecklist>
  </div>
</template>
              <script>
import formPicChecklist from "@/components/audit/formPicChecklist.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formPicChecklist,
    DxButton,
  },
  computed: {
    ...mapGetters("picChecklist", ["getPicChecklistStatus"]),
  },
  methods: {
    async submit(value) {
      if (this.getPicChecklistStatus.action == "Add") {
        this.$store
          .dispatch("picChecklist/postPicChecklist", value)
          .then((res) => {
            // this.$store.dispatch("auditPlan/setRisk", res.data);
            // this.$store.dispatch(
            //   "riskIdentification/setRiskIdentificationStatus",
            //   { action: "Add", roles: this.getPicChecklistStatus.roles }
            // );
            router.push(`/admin/audit-checklist/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getPicChecklistStatus.action == "Edit") {
        this.$store
          .dispatch("picChecklist/editPicChecklist", value)
          .then((res) => {
            // if (this.getPicChecklistStatus.roles == "user") {
            //   router.push(`/user/risk/info`);
            // } else if (this.getPicChecklistStatus.roles == "suAdmin") {
            //   router.push(`/super-admin/risk/info`);
            // }
            router.push(`/admin/audit-checklist/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>