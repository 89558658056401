<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addCodeCompany" @submit.prevent="handleSubmit">
        <DxSelectBox
          :read-only="readOnly"
          class="custom-form"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis"
          value-expr="id"
          display-expr="name"
          :is-required="true"
          v-model.selected-item="codeCompany.company_id"
        >
        </DxSelectBox>

        <DxTextBox
          class="custom-form"
          label="Kode"
          v-model:value="codeCompany.code"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
      </form>
    </div>
  </div>
</template>
        
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import DxValidationGroup from "devextreme-vue/validation-group";

export default {
  data() {
    return {
      loading: false,
      unitBisnisOptions: [],
      codeCompany: {},
      id: this.$route.params.id,
      readOnly: false,
    };
  },
  computed: {
    ...mapGetters("unitBisnis", [
      "getCodeUnit",
      "getCodeUnitStatus",
      "getAllUnitBisnis",
    ]),
  },
  mounted() {
    // this.unitBisnis();
    this.unitBisnisOptions = this.getAllUnitBisnis;
    if (this.getCodeUnitStatus == "Edit") {
      this.codeCompany = this.getCodeUnit;
    }
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  emits: ["get-unit-code"],
  methods: {
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", "?");
      this.unitBisnisOptions = units.data;
    },
    async handleSubmit(e) {
      this.$emit("get-unit-code", this.codeCompany);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    FilterTableUnitBisnis,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>