import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            riskSeverity: null,
            riskSeverityStatus: null,
        }
    },
    mutations: {
        setRiskSeverity(state, payload) {
            state.riskSeverity = payload
        },
        setRiskSeverityStatus(state, payload) {
            state.riskSeverityStatus = payload
        },
    },
    actions: {
        setRiskSeverity({ commit }, params) {
            commit('setRiskSeverity', params)
        },
        setRiskSeverityStatus({ commit }, params) {
            commit('setRiskSeverityStatus', params)
        },
        async getRiskSeverity({ commit, state }, params) {
            return await Api().get(
                `risk-severities${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRiskSeverity({ commit, state }, params) {
            return await Api().post(
                `risk-severities`, params
            ).then((res) => {
                return res.data
            })
        },
        async editRiskSeverity({ commit, state }, params) {
            return await Api().put(
                `risk-severities/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyRiskSeverity({ commit, state }, params) {
            return await Api().delete(
                `risk-severities/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getRiskSeverity(state) {
            return state.riskSeverity;
        },
        getRiskSeverityStatus(state) {
            return state.riskSeverityStatus;
        },
    }
}