import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            potentialRisk: null,
            potentialRiskStatus: null,
        }
    },
    mutations: {
        setPotentialRisk(state, payload) {
            state.potentialRisk = payload
        },
        setPotentialRiskStatus(state, payload) {
            state.potentialRiskStatus = payload
        },
    },
    actions: {
        setPotentialRisk({ commit }, params) {
            commit('setPotentialRisk', params)
        },
        setPotentialRiskStatus({ commit }, params) {
            commit('setPotentialRiskStatus', params)
        },
        async getPotentialRisk({ commit, state }, params) {
            return await Api().get(
                `potential-risks${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postPotentialRisk({ commit, state }, params) {
            return await Api().post(
                `potential-risks`, params
            ).then((res) => {
                return res.data
            })
        },
        async editPotentialRisk({ commit, state }, params) {
            return await Api().put(
                `potential-risks/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyPotentialRisk({ commit, state }, params) {
            return await Api().delete(
                `potential-risks/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getPotentialRisk(state) {
            return state.potentialRisk;
        },
        getPotentialRiskStatus(state) {
            return state.potentialRiskStatus;
        },
    }
}