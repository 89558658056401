<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskControl" @submit.prevent="handleSubmit">
        <DxSelectBox
          class="custom-form"
          :data-source="itemOptions"
          label="Pilih Control Item"
          value-expr="id"
          display-expr="name"
          v-model="control.control_item_id"
          :is-required="true"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextArea
          class="custom-form"
          label="Deskripsi"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="control.description"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
          
    <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      submitButtonOptions: {
        text: "Submit the Form",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      control: {},
      itemOptions: [],
    };
  },
  mounted() {
    if (this.getRiskControlStatus.action == "Edit") {
      this.control = this.getRiskControl;
    }
    this.getItem();
  },
  emits: ["get-risk-control"],
  computed: {
    ...mapGetters("riskControl", ["getRiskControl", "getRiskControlStatus"]),
  },
  methods: {
    async getItem() {
      const text = `?type=Pengendalian Risiko&limit=10000`;
      const dataItem = await this.$store.dispatch(
        "riskControlItem/getRiskControlItem",
        text
      );
      this.itemOptions = dataItem.data;
    },
    handleSubmit(e) {
      this.loading = true;
      this.$emit("get-risk-control", this.control);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    FilterTableUnitBisnis,
    DxLoadIndicator,
  },
};
</script>
    
    <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>