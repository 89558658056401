<template>
  <div>
    <table-regulation-detail :manage="true"></table-regulation-detail>
  </div>
</template>
      
      <script>
import tableRegulationDetail from "@/components/regulation/tableRegulationDetail.vue";
export default {
  components: {
    tableRegulationDetail,
  },
  data() {
    return {};
  },
};
</script>