import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            improvementIssueFile: null,
            improvementIssueFileStatus: null,
        }
    },
    mutations: {
        setImprovementIssueFile(state, payload) {
            state.improvementIssueFile = payload
        },
        setImprovementIssueFileStatus(state, payload) {
            state.improvementIssueFileStatus = payload
        },
    },
    actions: {
        setImprovementIssueFile({ commit }, params) {
            commit('setImprovementIssueFile', params)
        },
        setImprovementIssueFileStatus({ commit }, params) {
            commit('setImprovementIssueFileStatus', params)
        },
        async getImprovementIssueFile({ commit, state }, params) {
            return await Api().get(
                `improvement-issue-files${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postImprovementIssueFile({ commit, state }, params) {
            return await Api().post(
                `improvement-issue-files`, params, { headers: { "Content-Type": "multipart/form-data" }, }
            ).then((res) => {
                return res.data
            })
        },
        async editImprovementIssueFile({ commit, state }, params) {
            return await Api().post(
                `improvement-issue-files/${params.id}`, params, { headers: { "Content-Type": "multipart/form-data" }, }
            ).then((res) => {
                return res.data
            })
        },
        async destroyImprovementIssueFile({ commit, state }, params) {
            return await Api().delete(
                `improvement-issue-files/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getImprovementIssueFile(state) {
            return state.improvementIssueFile;
        },
        getImprovementIssueFileStatus(state) {
            return state.improvementIssueFileStatus;
        },
    }
}