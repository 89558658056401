<template>
  <div>
    <div>
      <tableAsset :role="'admin'"></tableAsset>
    </div>
  </div>
</template>
    
    <script>
import tableAsset from "@/components/calibration/tableAsset.vue";
import router from "@/router";
export default {
  components: {
    tableAsset,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>