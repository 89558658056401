<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addCodeDepartment" @submit.prevent="handleSubmit">
        <DxSelectBox
          :read-only="readOnly"
          class="custom-form"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis"
          value-expr="company_id"
          display-expr="code"
          :is-required="true"
          v-model.selected-item="codeDepartment.company_id"
          @valueChanged="changeUnitBisnis"
        >
        </DxSelectBox>
        <DxSelectBox
          :read-only="readOnly"
          class="custom-form"
          :data-source="departmentOptions"
          label="Pilih Department"
          value-expr="department_id"
          display-expr="department_name"
          :is-required="true"
          v-model.selected-item="codeDepartment.department_id"
        >
        </DxSelectBox>

        <DxTextBox
          class="custom-form"
          label="Kode"
          v-model:value="codeDepartment.code"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
      </form>
    </div>
  </div>
</template>
        
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import DxValidationGroup from "devextreme-vue/validation-group";

export default {
  data() {
    return {
      loading: false,
      unitBisnisOptions: [],
      departmentOptions: [],
      codeDepartment: {},
      id: this.$route.params.id,
      readOnly: false,
    };
  },
  computed: {
    ...mapGetters("department", [
      "getCodeDepartment",
      "getCodeDepartmentStatus",
    ]),
  },
  mounted() {
    this.unitBisnis();
    if (this.getCodeDepartmentStatus == "Edit") {
      this.codeDepartment = this.getCodeDepartment;
    }
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  emits: ["get-department-code"],
  methods: {
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );
      this.departmentOptions = depart.data;
    },
    async detail() {
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        `/${this.id}`
      );
      this.readOnly = true;
      this.codeDepartment = depart.data;
      console.log(this.codeDepartment);
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getCodeUnit", "?");
      this.unitBisnisOptions = units.data;
    },
    async handleSubmit(e) {
      this.$emit("get-department-code", this.codeDepartment);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    FilterTableUnitBisnis,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>