import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            improvementRootCause: null,
            improvementRootCauseStatus: null,
        }
    },
    mutations: {
        setImprovementRootCause(state, payload) {
            state.improvementRootCause = payload
        },
        setImprovementRootCauseStatus(state, payload) {
            state.improvementRootCauseStatus = payload
        },
    },
    actions: {
        setImprovementRootCause({ commit }, params) {
            commit('setImprovementRootCause', params)
        },
        setImprovementRootCauseStatus({ commit }, params) {
            commit('setImprovementRootCauseStatus', params)
        },
        async getImprovementRootCause({ commit, state }, params) {
            return await Api().get(
                `improvement-root-causes${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postImprovementRootCause({ commit, state }, params) {
            return await Api().post(
                `improvement-root-causes`, params
            ).then((res) => {
                return res.data
            })
        },
        async editImprovementRootCause({ commit, state }, params) {
            return await Api().put(
                `improvement-root-causes/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyImprovementRootCause({ commit, state }, params) {
            return await Api().delete(
                `improvement-root-causes/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getImprovementRootCause(state) {
            return state.improvementRootCause;
        },
        getImprovementRootCauseStatus(state) {
            return state.improvementRootCauseStatus;
        },
    }
}