<template>
  <div>
    <div>
      <auditReport :roles="'user'" :history="false"></auditReport>
    </div>
  </div>
</template>
    
<script>
import auditReport from "@/components/audit/tableAuditReport.vue";
export default {
  components: {
    auditReport,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>