import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            risk: null,
            riskStatus: null,
        }
    },
    mutations: {
        setRisk(state, payload) {
            state.risk = payload
        },
        setRiskStatus(state, payload) {
            state.riskStatus = payload
        },
    },
    actions: {
        setRisk({ commit }, params) {
            commit('setRisk', params)
        },
        setRiskStatus({ commit }, params) {
            commit('setRiskStatus', params)
        },
        async getRisk({ commit, state }, params) {
            return await Api().get(
                `risks${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRisk({ commit, state }, params) {
            return await Api().post(
                `risks`, params
            ).then((res) => {
                return res.data
            })
        },
        async editRisk({ commit, state }, params) {
            return await Api().put(
                `risks/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyRisk({ commit, state }, params) {
            return await Api().delete(
                `risks/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getRisk(state) {
            return state.risk;
        },
        getRiskStatus(state) {
            return state.riskStatus;
        },
    }
}