<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addScopeActivities" @submit.prevent="handleSubmit">
        <DxTextArea
          class="custom-form"
          label="Nama Aktivitas"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="scopeActivities.activity"
        >
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
              
    <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      scopeActivities: {},
      readOnly: false,
    };
  },
  computed: {
    ...mapGetters("scopeActivities", [
      "getScopeActivities",
      "getScopeActivitiesStatus",
    ]),
    ...mapGetters("processList", ["getProcessList"]),
  },
  mounted() {
    if (this.getScopeActivitiesStatus.action == "Edit") {
      this.scopeActivities = this.getScopeActivities;
    }
  },
  props: {},
  emits: ["get-scope-activities"],
  methods: {
    async handleSubmit(e) {
      this.loading = true;
      if (this.getScopeActivitiesStatus.action == "Add") {
        this.scopeActivities.process_id = this.getProcessList.id;
      }
      this.$emit("get-scope-activities", this.scopeActivities);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
              
              <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>