import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditFinding: null,
            auditFindingStatus: null,
        }
    },
    mutations: {
        setAuditFinding(state, payload) {
            state.auditFinding = payload
        },
        setAuditFindingStatus(state, payload) {
            state.auditFindingStatus = payload
        },
    },
    actions: {
        setAuditFinding({ commit }, params) {
            commit('setAuditFinding', params)
        },
        setAuditFindingStatus({ commit }, params) {
            commit('setAuditFindingStatus', params)
        },
        async getAuditFinding({ commit, state }, params) {
            return await Api().get(
                `audit-findings${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditFinding({ commit, state }, params) {
            return await Api().post(
                `audit-findings`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAuditFinding({ commit, state }, params) {
            return await Api().put(
                `audit-findings/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditFinding({ commit, state }, params) {
            return await Api().delete(
                `audit-findings/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditFinding(state) {
            return state.auditFinding;
        },
        getAuditFindingStatus(state) {
            return state.auditFindingStatus;
        },
    }
}