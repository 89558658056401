<template>
  <div>
    <h2 class="content-block">
      {{ getRiskStatus.action == "Add" ? "Membuat" : "Edit" }}
      Proses / Aktivitas
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formRisk @get-risk="submit"></formRisk>
  </div>
</template>
        <script>
import formRisk from "@/components/riskManagement/formRisk.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formRisk,
    DxButton,
  },
  computed: {
    ...mapGetters("risk", ["getRiskStatus"]),
  },
  methods: {
    async submit(value) {
      if (this.getRiskStatus.action == "Add") {
        this.$store
          .dispatch("risk/postRisk", value)
          .then((res) => {
            this.$store.dispatch("risk/setRisk", res.data);
            this.$store.dispatch(
              "riskIdentification/setRiskIdentificationStatus",
              { action: "Add", roles: this.getRiskStatus.roles }
            );
            router.push(`/risk-identification/form`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getRiskStatus.action == "Edit") {
        this.$store
          .dispatch("risk/editRisk", value)
          .then((res) => {
            if (this.getRiskStatus.roles == "user") {
              router.push(`/user/risk/info`);
            } else if (this.getRiskStatus.roles == "suAdmin") {
              router.push(`/super-admin/risk/info`);
            }
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>