<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addPotentialRisk" @submit.prevent="handleSubmit">
        <DxTextArea
          class="custom-form"
          label="Potensi Risiko"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="potentialRisk.potential"
        >
        </DxTextArea>

        <DxSelectBox
          class="custom-form"
          :data-source="riskLevelOptions"
          label="Pilih Risk Level"
          value-expr="name"
          display-expr="name"
          :is-required="true"
          v-model="potentialRisk.risk_level"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      potentialRisk: {},
      readOnly: false,
      riskLevelOptions: [{ name: "Low" }, { name: "Medium" }, { name: "High" }],
    };
  },
  computed: {
    ...mapGetters("potentialRisk", [
      "getPotentialRisk",
      "getPotentialRiskStatus",
    ]),
    ...mapGetters("picChecklist", ["getPicChecklist"]),
  },
  mounted() {
    if (this.getPotentialRiskStatus.action == "Edit") {
      this.potentialRisk = this.getPotentialRisk;
    }
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  emits: ["get-potential-risk"],
  methods: {
    async handleSubmit(e) {
      this.loading = true;
      if (this.getPotentialRiskStatus.action == "Add") {
        this.potentialRisk.pic_id = this.getPicChecklist.id;
      }
      this.$emit("get-potential-risk", this.potentialRisk);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>