import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            improvementProof: null,
            improvementProofStatus: null,
        }
    },
    mutations: {
        setImprovementProof(state, payload) {
            state.improvementProof = payload
        },
        setImprovementProofStatus(state, payload) {
            state.improvementProofStatus = payload
        },
    },
    actions: {
        setImprovementProof({ commit }, params) {
            commit('setImprovementProof', params)
        },
        setImprovementProofStatus({ commit }, params) {
            commit('setImprovementProofStatus', params)
        },
        async getImprovementProof({ commit, state }, params) {
            return await Api().get(
                `improvement-proofs${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postImprovementProof({ commit, state }, params) {
            return await Api().post(
                `improvement-proofs`, params, { headers: { "Content-Type": "multipart/form-data" }, }
            ).then((res) => {
                return res.data
            })
        },
        async editImprovementProof({ commit, state }, params) {
            return await Api().post(
                `improvement-proofs/${params.id}`, params, { headers: { "Content-Type": "multipart/form-data" }, }
            ).then((res) => {
                return res.data
            })
        },
        async destroyImprovementProof({ commit, state }, params) {
            return await Api().delete(
                `improvement-proofs/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getImprovementProof(state) {
            return state.improvementProof;
        },
        getImprovementProofStatus(state) {
            return state.improvementProofStatus;
        },
    }
}