import Api from '@/services/Api';
import CryptoJS from 'crypto-js';

export default {
    namespaced: true,
    state() {
        return {
            section: null,
            allSection: localStorage.getItem('m_sec') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('m_sec'), 'secretKey').toString(CryptoJS.enc.Utf8)) : null,
            sectionStatus: null,
        }
    },
    mutations: {
        setSection(state, payload) {
            state.section = payload
        },
        setSectionStatus(state, payload) {
            state.sectionStatus = payload
        },
        setAllSection(state, payload) {
            state.allSection = payload
        },
    },
    actions: {
        setSection({ commit }, params) {
            commit('setSection', params)
        },
        setSectionStatus({ commit }, params) {
            commit('setSectionStatus', params)
        },
        async getAllSection({ commit, state }, params) {
            return await Api().get(
                `sections${params}`,
            ).then((res) => {
                // set localstorage and generate
                localStorage.setItem('m_sec', CryptoJS.AES.encrypt(JSON.stringify(res.data.data), 'secretKey').toString());

                commit('setAllSection', res.data.data)
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getSection({ commit, state }, params) {
            return await Api().get(
                `sections${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postSection({ commit, state }, params) {
            return await Api().post(
                `sections`, params
            ).then((res) => {
                return res.data
            })
        },
        async editSection({ commit, state }, params) {
            return await Api().put(
                `sections/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroySection({ commit, state }, params) {
            return await Api().delete(
                `sections/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getSection(state) {
            return state.section;
        },
        getAllSection(state) {
            return state.allSection;
        },
        getSectionStatus(state) {
            return state.sectionStatus;
        },
    }
}