<template>
  <div>
    <h5>{{ data.description }}</h5>
    <DxSelectBox
      class="custom-form"
      :data-source="riskScore"
      label="Pilih Penilaian"
      value-expr="id"
      display-expr="score"
      :is-required="true"
      v-model="score.risk_score_indicator_id"
      @valueChanged="setScore"
      :search-enabled="true"
      item-template="item"
    >
      <template #item="{ data: item }">
        <div>
          <div>
            <b>{{ item.score }} ( {{ item.name }} )</b>
          </div>
        </div>
      </template>
      <DxValidator>
        <DxRequiredRule message="Required" />
      </DxValidator>
    </DxSelectBox>
  </div>
</template>
<script>
import { DxSelectBox } from "devextreme-vue/select-box";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      score: {},
      riskScore: [],
    };
  },
  mounted() {
    this.getScoreIndicator();
    if (
      this.getRiskScoreStatus.action == "editControl" ||
      this.getRiskScoreStatus.action == "editSeverity"
    ) {
      this.score.id = this.editScore.id;
      this.score.risk_identification_id = this.editScore.risk_identification_id;
      this.score.control_item_id = this.editScore.id_control_item;
      this.score.risk_score_indicator_id =
        this.editScore.risk_score_indicator_id;
      this.score.score_component_id = this.editScore.score_component_id;
    }
  },
  emits: ["set-score"],
  computed: {
    ...mapGetters("riskIdentification", ["getRiskIdentification"]),
    ...mapGetters("riskScore", ["getRiskScoreStatus"]),
  },
  methods: {
    setScore(params) {
      if (params.value) {
        if (
          this.getRiskScoreStatus.action == "addControl" ||
          this.getRiskScoreStatus.action == "addSeverity"
        ) {
          this.score.risk_identification_id = this.getRiskIdentification.id;
          this.score.score_component_id = this.data.id;
        }
        this.$emit("set-score", this.score);
      }
    },
    async getScoreIndicator() {
      const text = `?control_item=${this.data.control_item_id}&sorted_by=score&sort=asc`;
      const score = await this.$store.dispatch(
        "riskScoreIndicator/getRiskScoreIndicator",
        text
      );
      this.riskScore = score.data;
    },
  },
  props: {
    data: {
      type: Object,
    },
    editScore: {
      type: Object,
      default: null,
    },
  },
  components: {
    DxValidator,
    DxRequiredRule,
    DxSelectBox,
  },
};
</script>

<style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.dx-list-item-content {
  white-space: normal;
}
</style>