<template>
  <div>
    <h2 class="content-block">
      Detail Dokumen : {{ getDocument.document_number }} -
      {{ getDocument.document_name }}
    </h2>

    <DxButtonx
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <br />
    <br />
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :search-value="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column
        type="buttons"
        :width="110"
        data-field="Action"
        v-if="role == 'admin' || role == 'head' || role == 'suAdmin'"
      >
        <DxButton
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
          v-if="role == 'admin' || role == 'suAdmin'"
        />
        <DxButton
          hint="hapus"
          icon="trash"
          @click="popupDestroy"
          v-if="role == 'suAdmin'"
        />
        <DxButton
          hint="verif"
          icon="fa fa-check-to-slot"
          @click="popupHeadAction"
          v-if="role == 'head'"
        />
      </dx-column>
      <dx-column data-field="revision_number" caption="Nomor Revisi" />
      <dx-column
        data-field="upload_date"
        caption="Tanggal Unggah"
        :customize-text="dateColumn"
      />
      <dx-column data-field="files_url" caption="Link File" type="buttons">
        <DxButton text="pdf" hint="pdf" icon="file" @click="displayPdf" />
      </dx-column>
      <dx-column
        data-field="start_date"
        caption="Tanggal Ditetapkan"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="end_date"
        caption="Tanggal Kadaluarsa"
        :customize-text="dateColumn"
      />
      <dx-column data-field="is_active" caption="Active" />
      <dx-column
        data-field="revision_note"
        caption="Catatan Revisi"
        :cell-template="cellTemplate"
      />
      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: docApproval }">
        <table-approval :docApproval-data="docApproval.data" :role="role" />
      </template>
    </dx-data-grid>

    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Verifikasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupHead"
      :show-close-button="true"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Pastikan Dokumen sudah dicek!</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="Setuju"
              icon="check"
              type="success"
              @click="headVerif(true)"
            />
            <DxButtonx
              class="customBtn"
              text="Tolak"
              icon="close"
              type="danger"
              @click="headVerif(false)"
            />
          </div>
        </div>
      </template>
    </DxPopup>

    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
<script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import { DxTextArea } from "devextreme-vue/text-area";
import { DxItem } from "devextreme-vue/form";
import { DxBox } from "devextreme-vue/box";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";

import CustomStore from "devextreme/data/custom_store";
import tableApproval from "./tableApproval.vue";
import notify from "devextreme/ui/notify";
import { mapGetters, mapActions } from "vuex";
import router from "@/router";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";

export default {
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "is_active",
            sort: sort || "desc",
            search: filterValue,
            document: vthis.getDocument.id,
          });

          return await datax;
        },
      }),
    };

    return {
      gridRef: "doc-file-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      popupHead: false,
      approval: {},
      docFileBefore: {},
      docFile: {},
      detailUser: {},
      popupDelete: false,
      deleteDocFile: {},
    };
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("authentication", ["getUserDetail"]),
    ...mapGetters("document", ["getDocument"]),
    ...mapGetters("docFile", ["getDocumentFile", "getDocumentFileStatus"]),
  },
  props: {
    role: {
      type: String,
    },
    getAll: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getDepartments();
  },
  methods: {
    confirmDestroy() {
      this.$store
        .dispatch("docFile/destroyDocFiles", this.deleteDocFile)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteDocFile = params.row.data;
    },
    async getDepartments() {
      await this.$store.dispatch("department/getAllDepartment", "?");
    },
    displayPdf(params) {
      if (this.getDocument) {
        if (this.getDocument.document_type != "FORM") {
          this.$store.dispatch("docFile/setDocumentFile", params.row.data);
          router.push(`/view-pdf`);
        } else {
          window.open(params.row.data.download_file, "_blank");
        }
      } else {
        console.log("error");
      }
    },
    cellTemplate(container, options) {
      container.innerHTML = options.data.revision_note;
    },
    dateColumn(cellInfo) {
      if (cellInfo.value) {
        const date = new Date(cellInfo.value);
        const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        const mounth = parseInt(date.getMonth()) + 1;
        const mm = mounth < 10 ? "0" + mounth : mounth;

        return dd + "-" + mm + "-" + date.getFullYear();
      }
    },
    async popupHeadAction(params) {
      const text = `?department=${this.getUserDetail.department_id}&doc_file=${params.row.key}`;
      const getApproval = await this.$store.dispatch(
        "docApproval/getDocApprovals",
        text
      );

      if (getApproval.data[0].approved !== null) {
        notify("Approval sudah diisi!", "warning", 2000);
        this.popupHead = false;
        return;
      }

      this.approval = getApproval.data[0];
      this.approval.approved_by = this.getUserDetail.full_name;
      this.docFile = params.row.data;
      this.popupHead = true;
    },
    async headVerif(params) {
      this.approval.approval_date = new Date();
      this.approval.approval_type = "approval_document";
      this.approval.approved = params;

      this.$store
        .dispatch("docApproval/updateDocApproval", this.approval)
        .then((res) => {
          if (this.approval.approved) {
            const text = `?doc_file=${res.data.data.doc_file_id}&column_null=approved`;
            this.$store
              .dispatch("docApproval/getDocApprovals", text)
              .then((res) => {
                if (res.data.length < 1) {
                  this.updateDocFileBefore(this.docFile.document_id);
                  this.updateDocFile(true);
                }
              });
          } else {
            this.updateDocFile(false);
          }
        });
      this.popupHead = false;
      this.dataGrid.refresh();
    },
    updateDocFileBefore(params) {
      const text = `?document=${params}&is_active=true`;
      this.$store.dispatch("docFile/getDocFiles", text).then((res) => {
        if (res.data.length > 0) {
          this.docFileBefore = res.data[0];
          this.docFileBefore.is_active = false;
          this.docFileBefore._method = "PUT";

          this.$store
            .dispatch("docFile/updateDocFiles", this.docFileBefore)
            .then((res) => {
              console.log("update before success");
            });
          this.dataGrid.refresh();
        }
      });
    },
    async updateDocFile(params) {
      if (params) {
        const nowDate = new Date();
        this.docFile.start_date = new Date();
        this.docFile.end_date = new Date(
          nowDate.setFullYear(nowDate.getFullYear() + 3)
        );
      }
      this.docFile.is_active = params;
      this.docFile._method = "PUT";

      const text = `?document=${this.docFile.document_id}`;
      const docNotif = await this.$store.dispatch("docFile/getDocNotif", text);
      const Vthis = this;

      this.$store
        .dispatch("docFile/updateDocFiles", this.docFile)
        .then((res) => {
          // Check DocFileNotif
          if (docNotif.paginate.count > 0) {
            // Delete DocFileNotif
            this.$store
              .dispatch("docFile/destroyDocNotif", docNotif.data[0])
              .then((res) => {
                console.log("data notif kehapus");
              })
              .catch((e) => {
                notify(e.message, "error", 2000);
              });
          }
          this.dataGrid.refresh();
        });
    },
    edit(params) {
      let endDate = new Date(params.row.data.end_date);
      let notif = new Date(endDate.setMonth(endDate.getMonth() - 3));

      if (params.row.data.is_active && new Date() >= notif) {
        this.$store.dispatch("docFile/setDocumentFileStatus", "EditExpired");
        this.$store.dispatch("docFile/setDocumentFile", params.row.data);

        if (this.role == "suAdmin") {
          router.push(`/super-admin/document/doc-file/form`);
        } else {
          router.push(`/admin/doc-file/add`);
        }
      } else if (
        params.row.data.is_active == null &&
        params.row.data.end_date == null
      ) {
        this.$store.dispatch("docFile/setDocumentFileStatus", "Edit");
        this.$store.dispatch("docFile/setDocumentFile", params.row.data);

        if (this.role == "suAdmin") {
          router.push(`/super-admin/document/doc-file/form`);
        } else {
          router.push(`/admin/doc-file/add`);
        }
        return;
      } else {
        notify("Doc file sudah diverifikasi!", "warning", 2000);
      }
      // if (params.row.data.is_active != null) {
      //   notify("Doc file sudah diverifikasi!", "warning", 2000);
      //   return;
      // }
      // this.$store.dispatch("docFile/setDocumentFileStatus", "Edit");
      // this.$store.dispatch("docFile/setDocumentFile", params.row.data);

      // if (this.role == "suAdmin") {
      //   router.push(`/super-admin/document/doc-file/form`);
      // } else {
      //   router.push(`/admin/doc-file/add`);
      // }
    },
    onToolbarPreparing(e) {
      var vThis = this;
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
      if (this.role == "admin" || this.role == "suAdmin") {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function (params) {
              vThis.$store.dispatch("docFile/setDocumentFileStatus", "Add");
              if (vThis.role == "suAdmin") {
                router.push(`/super-admin/document/doc-file/form`);
              } else {
                router.push(`/admin/doc-file/add`);
              }
            },
          },
        });
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      if (this.getDocumentFileStatus == "Access") {
        params.is_active = true;
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "docFile/getDocFiles",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxTextArea,
    DxSearchPanel,
    DxButton,
    DxButtonx,
    DxBox,
    DxSelectBox,
    DxMasterDetail,
    tableApproval,
  },
};
</script>
<style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
.link-icon {
  text-decoration: none;
  font-size: 18px;
}
</style>