<template>
  <div>
    <div>
      <h2 class="content-block">
        Detail Regulasi {{ getRegulationList.regulation_number }}
      </h2>

      <DxButtonx
        class="content-block"
        type="default"
        icon="back"
        hint="kembali"
        @click="this.$router.back()"
      />

      <br />
      <br />

      <dx-data-grid
        class="dx-card wide-card"
        :data-source="dataSource"
        :show-borders="false"
        :column-auto-width="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :remote-operations="true"
        :search-value="true"
        :ref="gridRef"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :show-page-size-selector="true"
          :show-info="true"
          :allowed-page-sizes="pageSizes"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <DxSearchPanel :visible="true" />

        <dx-column
          type="buttons"
          :width="110"
          data-field="Action"
          v-if="manage"
        >
          <DxButton
            text="edit"
            hint="edit"
            icon="edit"
            @click="edit"
            v-if="manage"
          />
          <DxButton
            hint="hapus"
            icon="trash"
            @click="popupDestroy"
            v-if="manage && roles == 'suAdmin'"
          />
        </dx-column>
        <dx-column data-field="chapter" caption="Bab" />
        <dx-column data-field="article" caption="Pasal" />
        <dx-column data-field="verse" caption="Ayat" />
        <dx-column
          data-field="total_impl"
          caption="Jumlah Bukti Yang Harus Dipenuhi"
          alignment="center"
        />
        <dx-column
          data-field="status"
          caption="Status"
          width="100"
          cell-template="grid-cell"
        />
        <template #grid-cell="{ data }">
          <div
            :class="{
              done: data.value == 'done',
              process: data.value == 'process',
            }"
          >
            {{ data.value }}
          </div>
        </template>
        <dx-column data-field="summary" caption="Inti Sari" />

        <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
        <template #masterDetailTemplate="{ data: detail }">
          <table-regulation-impl
            :reg-detail="detail.data"
            :manage="manage"
            :admin="admin"
            :roles="roles"
            @verif-regulation-impl="verif"
            @get-detail="statusProcess"
          />
          <!-- @get-data-impl="processStatus"
            @get-detail="dataDetail" -->
        </template>
      </dx-data-grid>
      <DxPopup
        :width="300"
        height="auto"
        :show-title="true"
        title="Konfirmasi"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        v-model:visible="popupDelete"
      >
        <template #content="{}">
          <div class="popup-property-details">
            <b>Apakah yakin hapus data ?</b>
            <div class="justify-center">
              <DxButtonx
                class="customBtn"
                text="ya"
                icon="check"
                type="success"
                @click="confirmDestroy()"
              />
              <DxButtonx
                class="customBtn"
                text="batal"
                icon="close"
                type="danger"
                @click="popupDelete = false"
              />
            </div>
          </div>
        </template>
      </DxPopup>
    </div>
  </div>
</template>
      
      <script>
import tableRegulationImpl from "@/components/regulation/tableRegulationImpl.vue";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import { DxTextArea } from "devextreme-vue/text-area";
import { DxItem } from "devextreme-vue/form";
import { DxTextBox } from "devextreme-vue/text-box";
import DxButtonx from "devextreme-vue/button";
import DxPopup from "devextreme-vue/popup";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";
// import axios from "axios";
import notify from "devextreme/ui/notify";
import "whatwg-fetch";
import { mapGetters, mapActions } from "vuex";

import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxTextArea,
    DxSearchPanel,
    DxButton,
    DxButtonx,
    DxTextBox,
    FilterTableUnitBisnis,
    DxMasterDetail,
    tableRegulationImpl,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            regulation: vthis.getRegulationList.id,
          });
          return await datax;
        },
      }),
    };
    return {
      gridRef: "documents-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      unitBisnis: "",
      regulation: null,
      btnAdd: null,
      list: {},
      impl: "",
      detail: {},
      popupDelete: false,
      deleteRegDetail: {},
    };
  },
  props: {
    manage: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: String,
      default: null,
    },
  },
  emits: ["get-verif", "get-data-impl"],
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("regulationDetail", ["getRegulationDetail"]),
    ...mapGetters("regulationList", ["getRegulationList"]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  mounted() {},
  methods: {
    async statusProcess(params) {
      await this.$store.dispatch(
        "regulationDetail/updateRegulationDetails",
        params
      );
      if (this.getRegulationList.status == "done") {
        this.getRegulationList.status = "process";
        await this.$store.dispatch(
          "regulationList/updateRegulationLists",
          this.getRegulationList
        );
      }
      this.dataGrid.refresh();
    },
    confirmDestroy() {
      this.$store
        .dispatch(
          "regulationDetail/destroyRegulationDetails",
          this.deleteRegDetail
        )
        .then((res) => {
          if (this.getRegulationList.status == "done") {
            this.getRegulationList.status = "process";
            this.$store.dispatch(
              "regulationList/updateRegulationLists",
              this.getRegulationList
            );
          }
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      console.log("delete berhasil");
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteRegDetail = params.row.data;
    },
    // Process Verifikasi Implementation
    verif(params) {
      this.$store
        .dispatch("regulationImpl/updateRegulationImplementations", params)
        .then((res) => {
          this.checkImpl(res.data.data.regulation_detail_id);
        });
    },
    async checkImpl(detail_id) {
      const checkImplDone = await this.getCountImpl(
        `?regulation_detail=${detail_id}&verified=true`
      );

      if (checkImplDone === this.getRegulationDetail.total_impl) {
        this.doneStatus();
      }
    },
    async getCountImpl(text) {
      const impl = await this.$store.dispatch(
        "regulationImpl/getRegulationImplementations",
        text
      );
      return await impl.paginate.total;
    },
    async doneStatus() {
      this.getRegulationDetail.status = "done";
      await this.$store
        .dispatch(
          "regulationDetail/updateRegulationDetails",
          this.getRegulationDetail
        )
        .then((res) => {
          this.updateRegListDone(res.data.data.regulation_id);
        });

      this.dataGrid.refresh();
    },
    async updateRegListDone(regulation) {
      const data = await this.getDetailProcess(regulation);
      if (data <= 0) {
        this.getRegulationList.status = "done";
        await this.$store.dispatch(
          "regulationList/updateRegulationLists",
          this.getRegulationList
        );
      }
    },
    async getDetailProcess(regulation) {
      const params = `?regulation=${regulation}&status=process`;

      const data = await this.$store.dispatch(
        "regulationDetail/getRegulationDetails",
        params
      );
      return await data.paginate.total;
    },
    // End Process Verifikasi Implementation
    async edit(params) {
      if (params.row.data.status == "done") {
        notify("Data regulation detail sudah done!", "warning", 2000);
        return;
      }
      this.$store.dispatch("regulationDetail/setRegulationDetailStatus", {
        action: "Edit",
        roles: this.roles,
      });
      this.$store.dispatch(
        "regulationDetail/setRegulationDetail",
        params.row.data
      );
      router.push(`/regulation-detail/form`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      if (this.manage) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.$store.dispatch(
                "regulationDetail/setRegulationDetailStatus",
                {
                  action: "Add",
                  roles: vThis.roles,
                }
              );
              router.push(`/regulation-detail/form`);
            },
          },
        });
      }
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "regulationDetail/getRegulationDetails",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>

<style scoped>
.done {
  background-color: green;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.process {
  background-color: yellow;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}

.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
</style>