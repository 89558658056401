<template>
  <div>
    <h2 class="content-block">
      {{ getProcessListStatus.action == "Add" ? "Membuat" : "Edit" }}
      Daftar Proses
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formProcessList @get-process="submit"></formProcessList>
  </div>
</template>
              <script>
import formProcessList from "@/components/audit/formProcessList.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formProcessList,
    DxButton,
  },
  computed: {
    ...mapGetters("processList", ["getProcessListStatus"]),
  },
  methods: {
    async submit(value) {
      if (this.getProcessListStatus.action == "Add") {
        this.$store
          .dispatch("processList/postProcessList", value)
          .then((res) => {
            // this.$store.dispatch("auditPlan/setRisk", res.data);
            // this.$store.dispatch(
            //   "riskIdentification/setRiskIdentificationStatus",
            //   { action: "Add", roles: this.getProcessListStatus.roles }
            // );
            router.push(`/admin/audit-checklist/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getProcessListStatus.action == "Edit") {
        this.$store
          .dispatch("processList/editProcessList", value)
          .then((res) => {
            // if (this.getProcessListStatus.roles == "user") {
            //   router.push(`/user/risk/info`);
            // } else if (this.getProcessListStatus.roles == "suAdmin") {
            //   router.push(`/super-admin/risk/info`);
            // }
            router.push(`/admin/audit-checklist/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>