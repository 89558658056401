<template>
  <div>
    <DxTabPanel>
      <DxItem title="Penilaian Pengendalian Risiko" template="risk-control" />
      <template #risk-control>
        <table v-if="totalRiskControl > 0">
          <tr v-for="data in riskControl" :key="data.id">
            <th width="100">
              <DxButton
                type="default"
                icon="edit"
                hint="edit"
                @click="editRiskControl(data)"
              />
              <DxButton
                type="default"
                hint="hapus"
                icon="trash"
                @click="popupDestroy(data, 'control')"
                v-if="role == 'suAdmin'"
              />
            </th>
            <th width="30%">{{ data.desc_risk_control }}</th>
            <th width="5px">:</th>
            <th>{{ data.score_indicator }}</th>
            <th>{{ data.name_score_indicator }}</th>
          </tr>
          <tr v-if="riskControl.length > 0">
            <th></th>
            <th>Nilai Control</th>
            <th width="5px">:</th>
            <th>{{ totalRiskControl }}</th>
            <th>%</th>
          </tr>
        </table>
        <div>
          <DxButton
            v-if="role != 'admin'"
            class="content-block"
            text="Penilaian Pengendalian"
            type="default"
            icon="add"
            hint="score"
            @click="addScoreControl"
          />
        </div>
      </template>
      <DxItem
        title="Detail Pengendalian Risiko"
        template="risk-control-detail"
      />
      <template #risk-control-detail>
        <div class="dx-card" v-for="data in riskControlDetail" :key="data.id">
          <table>
            <tr>
              <th width="100">
                <DxButton
                  type="default"
                  icon="edit"
                  hint="edit"
                  @click="editControlDetail(data)"
                />
                <DxButton
                  type="default"
                  hint="hapus"
                  icon="trash"
                  @click="popupDestroy(data, 'detail')"
                  v-if="role == 'suAdmin'"
                />
              </th>
              <th width="200">{{ data.control_description }}</th>
              <th width="10">:</th>
              <td>{{ data.description }}</td>
            </tr>
          </table>
        </div>
        <DxButton
          v-if="role != 'admin'"
          class="content-block"
          text="Detail Pengendalian Risiko"
          type="default"
          icon="add"
          hint="score"
          @click="addRiskControlDetail"
        />
      </template>
      <DxItem title="Tingkat Keparahan Risiko" template="risk-severity" />
      <template #risk-severity>
        <table v-if="riskSeverity.length > 0">
          <tr v-for="data in riskSeverity" :key="data.id">
            <th width="100">
              <DxButton
                type="default"
                icon="edit"
                hint="edit"
                @click="editRiskSeverity(data)"
              />
              <DxButton
                type="default"
                hint="hapus"
                icon="trash"
                @click="popupDestroy(data, 'severity')"
                v-if="role == 'suAdmin'"
              />
            </th>
            <th width="30%">{{ data.desc_risk_severities }}</th>
            <th width="5px">:</th>
            <th>{{ data.score_indicator }}</th>
            <th>{{ data.name_score_indicator }}</th>
          </tr>
          <tr>
            <th></th>
            <th>Total Nilai Severity</th>
            <th width="5px">:</th>
            <th>{{ totalSeverity }}</th>
            <th>%</th>
          </tr>
        </table>
        <div>
          <DxButton
            v-if="role != 'admin'"
            class="content-block"
            text="Penilaian Tingkat Keparahan"
            type="default"
            icon="add"
            hint="score"
            @click="addScoreSeverity"
          />
        </div>
      </template>
      <DxItem title="Rencana Perbaikan" template="risk-imp-plan" />
      <template #risk-imp-plan>
        <div class="dx-card" v-for="data in riskImpPlan" :key="data.id">
          <table>
            <tr>
              <th width="300">{{ data.desc_item }}</th>
              <th width="10">:</th>
              <td>{{ data.description }}</td>
            </tr>
            <tr>
              <th>By Who</th>
              <th>:</th>
              <td>{{ data.improve_by }}</td>
            </tr>
            <tr>
              <th>By When</th>
              <th>:</th>
              <td>{{ formatDate(data.improve_at) }}</td>
            </tr>
            <tr>
              <th>
                <DxButton
                  type="default"
                  icon="edit"
                  hint="edit"
                  @click="editAddImpPlan(data)"
                />
                <DxButton
                  type="default"
                  hint="hapus"
                  icon="trash"
                  @click="popupDestroy(data, 'improv')"
                  v-if="role == 'suAdmin'"
                />
              </th>
            </tr>
          </table>
        </div>
        <DxButton
          v-if="role != 'admin'"
          class="content-block"
          text="Rencana Perbaikan"
          type="default"
          icon="add"
          hint="score"
          @click="addImpPlan"
        />
      </template>
    </DxTabPanel>
    <br />
    <br />
    <div v-if="status != null">
      <div :class="status">{{ status }}</div>
    </div>
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
  
  <script>
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";
import DxButton from "devextreme-vue/button";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";

import notify from "devextreme/ui/notify";
import router from "@/router";

export default {
  components: {
    DxTabPanel,
    DxItem,
    DxLoadIndicator,
    DxButton,
    DxPopup,
    DxButtonx,
  },
  data() {
    return {
      riskControl: [],
      riskControlDetail: [],
      riskSeverity: [],
      riskImpPlan: [],
      maxRiskControl: 0,
      sumRiskControl: 0,
      maxSeverity: 0,
      sumSeverity: 0,
      scoreIndicator: [],
      totalRiskControl: 0,
      totalSeverity: 0,
      status: null,
      popupDelete: false,
      deleteData: {},
      typeDelete: "",
    };
  },
  props: {
    role: {
      type: String,
    },
    riskIdentification: {
      type: Object,
    },
  },
  emits: ["delete-data"],
  mounted() {
    this.getRiskControlDetail();
    this.getRiskImpPlan();
    this.getScoreIndicator();
  },
  methods: {
    destroyData(params) {
      this.$store
        .dispatch(params, this.deleteData)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.$emit("delete-data", this.deleteData);
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
    },
    confirmDestroy() {
      if (this.typeDelete == "control" || this.typeDelete == "severity") {
        this.destroyData("riskScore/destroyRiskScore");
      } else if (this.typeDelete == "detail") {
        this.destroyData("riskControlDetail/destroyRiskControlDetail");
      } else if (this.typeDelete == "improv") {
        this.destroyData("riskImprovementPlan/destroyRiskImpPlan");
      } else {
        console.log("harus diisi");
      }
      this.popupDelete = false;
    },
    popupDestroy(params, type) {
      this.popupDelete = true;

      this.deleteData = params;
      this.typeDelete = type;
    },
    formatDate(params) {
      console.log(params);
      let date = new Date(params);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "-" + mm + "-" + date.getFullYear();
    },
    getValueMatrix(probability, severity) {
      switch (true) {
        case probability < 1 && severity < 1:
          this.status = null;
          break;
        case (probability < 41 && severity < 21) ||
          (severity < 41 && probability < 21):
          this.status = "low";
          break;
        case (probability < 41 && severity < 41) ||
          (probability < 61 && severity < 41) ||
          (probability < 61 && severity < 21) ||
          (probability < 81 && severity < 21) ||
          (probability < 21 && severity < 61) ||
          (probability < 21 && severity < 81) ||
          (probability < 41 && severity < 61):
          this.status = "medium";
          break;
        case (probability < 101 && severity < 21) ||
          (probability < 81 && severity < 41) ||
          (probability < 61 && severity < 61) ||
          (probability < 41 && severity < 81) ||
          (probability < 21 && severity < 101):
          this.status = "high";
          break;
        case probability < 101 && severity < 101:
          this.status = "extreme";
          break;
        default:
          this.status = null;
      }
    },
    getCount(dataScore, type) {
      const data = dataScore.filter((res) => res.score_indicator > 0);

      let maxScore = 0;
      let sumScore = 0;

      data.forEach((e) => {
        let arr = this.scoreIndicator;
        // filter by control item
        arr = arr.filter((res) => {
          return res.control_item_id == e.id_control_item;
        });
        // sort by max score
        arr.sort((a, b) => b.score - a.score)[0];
        // count
        maxScore += arr[0].score;
        sumScore += e.score_indicator;
      });
      let result = ((sumScore / maxScore) * 100).toFixed(0);
      if (type == "control") {
        this.totalRiskControl = result;
        // check value matrix
        this.getValueMatrix(result, this.totalSeverity);
      } else {
        this.totalSeverity = result;
        // check value matrix
        this.getValueMatrix(this.totalRiskControl, result);
      }
    },
    async getScoreIndicator() {
      const text = "?limit=10000";
      const indicator = await this.$store.dispatch(
        "riskScoreIndicator/getRiskScoreIndicator",
        text
      );
      this.scoreIndicator = indicator.data;
      this.getRiskControl();
      this.getRiskSeverity();
    },
    editControlDetail(params) {
      this.$store.dispatch("riskControlDetail/setRiskControlDetail", params);
      this.$store.dispatch("riskControlDetail/setRiskControlDetailStatus", {
        action: "Edit",
        roles: this.role,
      });
      router.push(`/risk-control-detail/form`);
    },
    addRiskControlDetail() {
      this.$store.dispatch(
        "riskIdentification/setRiskIdentification",
        this.riskIdentification
      );
      this.$store.dispatch("riskControlDetail/setRiskControlDetailStatus", {
        action: "Add",
        roles: this.role,
      });
      router.push(`/risk-control-detail/form`);
    },
    editAddImpPlan(params) {
      this.$store.dispatch("riskImprovementPlan/setRiskImpPlan", params);
      this.$store.dispatch("riskImprovementPlan/setRiskImpPlanStatus", {
        action: "Edit",
        roles: this.role,
      });
      router.push(`/risk-imp-plan/form`);
    },
    addImpPlan() {
      this.$store.dispatch(
        "riskIdentification/setRiskIdentification",
        this.riskIdentification
      );
      this.$store.dispatch("riskImprovementPlan/setRiskImpPlanStatus", {
        action: "Add",
        roles: this.role,
      });
      router.push(`/risk-imp-plan/form`);
    },
    async editRiskSeverity(params) {
      const text = `/control-item?id=${params.score_component_id}`;
      const severity = await this.$store.dispatch(
        "riskSeverity/getRiskSeverity",
        text
      );
      this.$store.dispatch("riskScore/setRiskScoreStatus", {
        action: "editSeverity",
        roles: this.role,
      });
      this.$store.dispatch("riskScore/setRiskScore", params);
      this.$store.dispatch("riskSeverity/setRiskSeverity", severity.data);
      router.push(`/risk-score/form`);
    },
    async getSeverity() {
      const text = "/control-item";
      const severity = await this.$store.dispatch(
        "riskSeverity/getRiskSeverity",
        text
      );
      const arr = severity.data;
      if (severity.data.length != this.riskSeverity.length) {
        let data = arr;
        this.riskSeverity.forEach((each) => {
          data = data.filter((res) => {
            return res.id != each.score_component_id;
          });
        });
        this.$store.dispatch("riskScore/setRiskScoreStatus", {
          action: "addSeverity",
          roles: this.role,
        });
        this.$store.dispatch("riskSeverity/setRiskSeverity", data);
        router.push(`/risk-score/form`);
      } else {
        notify(`score Tingkat Keparahan sudah diisi semua`, "warning", 2000);
      }
    },
    addScoreSeverity() {
      // check add score severity
      this.getSeverity();

      this.$store.dispatch(
        "riskIdentification/setRiskIdentification",
        this.riskIdentification
      );
      // this.$store.dispatch("riskScore/setRiskScoreStatus", "severity");
      // router.push(`/user/risk-score/form`);
    },
    async editRiskControl(params) {
      // console.log(params);
      const text = `/control-item?id=${params.score_component_id}`;
      const control = await this.$store.dispatch(
        "riskControl/getRiskControl",
        text
      );

      this.$store.dispatch("riskScore/setRiskScoreStatus", {
        action: "editControl",
        roles: this.role,
      });
      this.$store.dispatch("riskScore/setRiskScore", params);
      this.$store.dispatch("riskControl/setRiskControl", control.data);

      router.push(`/risk-score/form`);
    },
    async getControl() {
      const text = "/control-item";
      const control = await this.$store.dispatch(
        "riskControl/getRiskControl",
        text
      );
      const arr = control.data;
      if (control.data.length != this.riskControl.length) {
        let data = arr;
        this.riskControl.forEach((each) => {
          data = data.filter((res) => {
            return res.id != each.score_component_id;
          });
        });
        this.$store.dispatch("riskScore/setRiskScoreStatus", {
          action: "addControl",
          roles: this.role,
        });
        this.$store.dispatch("riskControl/setRiskControl", data);
        router.push(`/risk-score/form`);
      } else {
        notify(`score pengendalian sudah diisi semua`, "warning", 2000);
      }
    },
    addScoreControl() {
      // check add new score
      this.getControl();

      this.$store.dispatch(
        "riskIdentification/setRiskIdentification",
        this.riskIdentification
      );
    },
    getRiskControl() {
      const text = `/get-risk-control?identification=${this.riskIdentification.id}`;
      this.$store.dispatch("riskScore/getRiskScore", text).then((res) => {
        const dataScore = res.data;
        this.riskControl = dataScore;
        // Count risk control
        if (this.riskControl.length > 0) {
          this.getCount(dataScore, "control");
        }
      });
    },
    async getRiskControlDetail() {
      const text = `/control-item?identification=${this.riskIdentification.id}`;
      const controlDetail = await this.$store.dispatch(
        "riskControlDetail/getRiskControlDetail",
        text
      );

      this.riskControlDetail = controlDetail.data;
    },
    getRiskSeverity() {
      const text = `/get-severity?identification=${this.riskIdentification.id}`;
      this.$store.dispatch("riskScore/getRiskScore", text).then((res) => {
        const dataScore = res.data;
        this.riskSeverity = dataScore;
        // Count risk severity
        if (this.riskSeverity.length > 0) {
          this.getCount(dataScore, "severity");
        }
      });
    },
    async getRiskImpPlan() {
      const text = `/control-item?identification=${this.riskIdentification.id}`;
      const riskImp = await this.$store.dispatch(
        "riskImprovementPlan/getRiskImpPlan",
        text
      );
      this.riskImpPlan = riskImp.data;
    },
  },
};
</script>;

<style scoped>
table tr th {
  padding: 10px;
}
.extreme {
  margin: auto;
  width: 20%;
  border: 3px solid red;
  padding: 10px;
  text-align: center;
  color: white;
  font-size: 30px;
  background-color: red;
  border-radius: 10px;
}
.high {
  margin: auto;
  width: 20%;
  border: 3px solid rgb(255, 187, 0);
  padding: 10px;
  text-align: center;
  font-size: 30px;
  background-color: rgb(255, 187, 0);
  border-radius: 10px;
}
.medium {
  margin: auto;
  width: 20%;
  border: 3px solid yellow;
  padding: 10px;
  text-align: center;
  font-size: 30px;
  background-color: yellow;
  border-radius: 10px;
}
.low {
  margin: auto;
  width: 20%;
  border: 3px solid rgb(121, 255, 109);
  padding: 10px;
  text-align: center;
  font-size: 30px;
  background-color: rgb(121, 255, 109);
  border-radius: 10px;
}
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
</style>
