<template>
  <div>
    <div>
      <tableAuditFaultType :roles="'admin'"></tableAuditFaultType>
    </div>
  </div>
</template>
      
  <script>
import tableAuditFaultType from "@/components/audit/tableAuditFaultType.vue";
export default {
  components: {
    tableAuditFaultType,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>