<template>
  <div>
    <h2 class="content-block">
      {{ getAuditRiskControlStatus.action == "Add" ? "Membuat" : "Edit" }}
      Pengendalian Risiko
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formAuditRiskControl
      @get-audit-risk-control="submit"
    ></formAuditRiskControl>
  </div>
</template>
              <script>
import formAuditRiskControl from "@/components/audit/formAuditRiskControl.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formAuditRiskControl,
    DxButton,
  },
  data() {
    return {
      auditReport: {},
    };
  },
  computed: {
    ...mapGetters("auditRiskControl", ["getAuditRiskControlStatus"]),
    ...mapGetters("auditPlan", ["getAuditPlan"]),
    ...mapGetters("auditChecklist", ["getAuditChecklist"]),
  },
  mounted() {},
  methods: {
    // buat add audit report ketika status nya nc dan obs
    async submit(value) {
      if (this.getAuditRiskControlStatus.action == "Add") {
        this.$store
          .dispatch("auditRiskControl/postAuditRiskControl", value)
          .then((res) => {
            router.push(`/admin/audit-checklist/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getAuditRiskControlStatus.action == "Edit") {
        this.$store
          .dispatch("auditRiskControl/editAuditRiskControl", value)
          .then((res) => {
            // if (this.getAuditRiskControlStatus.roles == "user") {
            //   router.push(`/user/risk/info`);
            // } else if (this.getAuditRiskControlStatus.roles == "suAdmin") {
            //   router.push(`/super-admin/risk/info`);
            // }
            router.push(`/admin/audit-checklist/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>