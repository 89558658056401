<template>
  <div>
    <h2 class="content-block">
      {{ getRiskControlDetailStatus.action == "Add" ? "Membuat" : "Edit" }}
      Detail Pengendalian Risiko
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formRiskControlDetail
      @get-risk-control-detail="submit"
    ></formRiskControlDetail>
  </div>
</template>
            <script>
import formRiskControlDetail from "@/components/riskManagement/formRiskControlDetail.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formRiskControlDetail,
    DxButton,
  },
  computed: {
    ...mapGetters("riskControlDetail", ["getRiskControlDetailStatus"]),
    redirect() {
      if (this.getRiskControlDetailStatus.roles == "admin") {
        router.push(`/admin/risk-identification/info`);
      } else if (this.getRiskControlDetailStatus.roles == "suAdmin") {
        router.push(`/super-admin/risk-identification/info`);
      } else if (this.getRiskControlDetailStatus.roles == "user") {
        router.push(`/user/risk-identification/info`);
      } else {
        console.log("error");
      }
    },
  },
  methods: {
    async submit(value) {
      const vThis = this;
      if (this.getRiskControlDetailStatus.action == "Add") {
        this.$store
          .dispatch("riskControlDetail/postRiskControlDetail", value)
          .then((res) => {
            vThis.redirect;
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getRiskControlDetailStatus.action == "Edit") {
        this.$store
          .dispatch("riskControlDetail/editRiskControlDetail", value)
          .then((res) => {
            vThis.redirect;
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>