<template>
  <div>
    <table-regulation-detail
      :admin="true"
      @get-verif="saveVerif"
      @get-status="getStatus"
    ></table-regulation-detail>
  </div>
</template>
    
    <script>
import tableRegulationDetail from "@/components/regulation/tableRegulationDetail.vue";
export default {
  components: {
    tableRegulationDetail,
  },
  data() {
    return {
      title: "",
      dataDetail: {},
    };
  },
  methods: {
    saveVerif(params) {
      this.$store
        .dispatch("regulationImpl/updateRegulationImplementations", params)
        .then((res) => {
          this.checkImpl(res.data.data.regulation_detail_id);
        });
    },
    async checkImpl(detail_id) {
      const text = `?regulation_detail=${detail_id}&verified=false`;
      const data = await this.$store.dispatch(
        "regulationImpl/getRegulationImplementations",
        text
      );
      if (data.paginate.total > 0) {
        return;
      }
      this.doneStatus();
    },
    async doneStatus() {
      this.dataDetail.status = "done";
      await this.$store.dispatch(
        "regulationDetail/updateRegulationDetails",
        this.dataDetail
      );
      console.log("done sukses");
    },
    getStatus(params) {
      this.dataDetail = params;
    },
  },
};
</script>