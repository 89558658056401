<template>
  <div class="widget-container">
    <h2 class="content-block">Membuat Implementasi Regulasi</h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <div class="content-block dx-card responsive-paddings">
      <form-regulation-impl @get-regulation-impl="saveData" />
    </div>
  </div>
</template>
        <script>
import DxButton from "devextreme-vue/button";
import formRegulationImpl from "@/components/regulation/formRegulationImpl.vue";
import notify from "devextreme/ui/notify";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      detail_id: this.$route.params.id,
      detail: {},
      list: {},
    };
  },
  components: {
    formRegulationImpl,
    DxButton,
  },
  computed: {
    ...mapGetters("regulationImpl", [
      // "getRegulationImpl",
      "getRegulationImplStatus",
    ]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  methods: {
    saveData(params) {
      if (this.getRegulationImplStatus == "Add") {
        this.$store
          .dispatch("regulationImpl/postRegulationImplementations", params)
          .then((res) => {
            notify("berhasil", "success", 2000);
            router.push(`/super-admin/regulation-detail/info`);
          });
      } else if (this.getRegulationImplStatus == "Edit") {
        this.$store
          .dispatch("regulationImpl/updateRegulationImplementations", params)
          .then((res) => {
            notify("berhasil", "success", 2000);

            router.push(`/super-admin/regulation-detail/info`);
          });
      } else {
        console.log("error");
        return;
      }
      // this.$store
      //   .dispatch("regulationImpl/postRegulationImplementations", params)
      //   .then((res) => {
      //     notify("berhasil", "success", 2000);

      //     if (this.detail.status == "done") {
      //       this.processStatus();
      //     }

      //     router.push(
      //       `/user/regulation-detail/manage/${this.detail.regulation_id}`
      //     );
      //   });
    },
    async processStatus() {
      this.detail.status = "process";
      this.$store
        .dispatch("regulationDetail/updateRegulationDetails", this.detail)
        .then((res) => {
          if (this.list.status == "done") {
            this.list.status = "process";
            this.$store.dispatch(
              "regulationList/updateRegulationLists",
              this.list
            );
          }
        });
      // if (this.list.status == "done") {
      //   this.list.status = "process";
      //   const update = await this.$store.dispatch(
      //     "regulationList/updateRegulationLists",
      //     this.list
      //   );
      // }
    },
    async getRegulationDetail() {
      const regDetail = await this.$store.dispatch(
        "regulationDetail/getRegulationDetail",
        { id: this.detail_id }
      );
      this.detail = regDetail.data;
      this.getRegulation();
    },
    async getRegulation() {
      const regList = await this.$store.dispatch(
        "regulationList/getRegulationList",
        { id: this.detail.regulation_id }
      );
      this.list = regList.data;
    },
  },
};
</script>