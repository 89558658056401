<template>
  <div>
    <h2 class="content-block">
      Daftar Permintaan Dokumen <span v-if="title">{{ title }}</span>
    </h2>
    <tableRequestDoc :role="role" @title="getTitle"></tableRequestDoc>
    <!-- :request-status="requestStatus" -->
    <!-- :not-null="notNull" -->
  </div>
</template>
    
    <script>
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import tableRequestDoc from "@/components/documentManagement/tableRequestDoc.vue";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";

export default {
  data() {
    return {
      role: "suAdmin",
      requestStatus: "pending",
      notNull: "head_signed",
      title: false,
    };
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
  },
  methods: {
    async getTitle(value) {
      this.title = value;
    },
  },
  components: {
    FilterTableUnitBisnis,
    tableRequestDoc,
  },
};
</script>