<template>
  <div>
    <h2 class="content-block">
      {{ getAuditPlanStatus.action == "Add" ? "Membuat" : "Edit" }}
      Agenda Audit
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formAuditPlan @get-audit-plan="submit"></formAuditPlan>
  </div>
</template>
          <script>
import formAuditPlan from "@/components/audit/formAuditPlan.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formAuditPlan,
    DxButton,
  },
  computed: {
    ...mapGetters("auditPlan", ["getAuditPlanStatus"]),
  },
  methods: {
    async submit(value) {
      if (this.getAuditPlanStatus.action == "Add") {
        this.$store
          .dispatch("auditPlan/postAuditPlan", value)
          .then((res) => {
            // this.$store.dispatch("auditPlan/setRisk", res.data);
            // this.$store.dispatch(
            //   "riskIdentification/setRiskIdentificationStatus",
            //   { action: "Add", roles: this.getAuditPlanStatus.roles }
            // );
            router.push(`/admin/audit-plan/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getAuditPlanStatus.action == "Edit") {
        this.$store
          .dispatch("auditPlan/editAuditPlan", value)
          .then((res) => {
            // if (this.getAuditPlanStatus.roles == "user") {
            //   router.push(`/user/risk/info`);
            // } else if (this.getAuditPlanStatus.roles == "suAdmin") {
            //   router.push(`/super-admin/risk/info`);
            // }
            router.push(`/admin/audit-plan/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>