import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            capaRootCause: null,
            capaRootCauseStatus: null,
        }
    },
    mutations: {
        setCapaRootCause(state, payload) {
            state.capaRootCause = payload
        },
        setCapaRootCauseStatus(state, payload) {
            state.capaRootCauseStatus = payload
        },
    },
    actions: {
        setCapaRootCause({ commit }, params) {
            commit('setCapaRootCause', params)
        },
        setCapaRootCauseStatus({ commit }, params) {
            commit('setCapaRootCauseStatus', params)
        },
        async getCapaRootCause({ commit, state }, params) {
            return await Api().get(
                `capa-root-causes${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postCapaRootCause({ commit, state }, params) {
            return await Api().post(
                `capa-root-causes`, params
            ).then((res) => {
                return res.data
            })
        },
        async editCapaRootCause({ commit, state }, params) {
            return await Api().put(
                `capa-root-causes/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyCapaRootCause({ commit, state }, params) {
            return await Api().delete(
                `capa-root-causes/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getCapaRootCause(state) {
            return state.capaRootCause;
        },
        getCapaRootCauseStatus(state) {
            return state.capaRootCauseStatus;
        },
    }
}