import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            capaCorrectionAction: null,
            capaCorrectionActionStatus: null,
        }
    },
    mutations: {
        setCapaCorrectionAction(state, payload) {
            state.capaCorrectionAction = payload
        },
        setCapaCorrectionActionStatus(state, payload) {
            state.capaCorrectionActionStatus = payload
        },
    },
    actions: {
        setCapaCorrectionAction({ commit }, params) {
            commit('setCapaCorrectionAction', params)
        },
        setCapaCorrectionActionStatus({ commit }, params) {
            commit('setCapaCorrectionActionStatus', params)
        },
        async getCapaCorrectionAction({ commit, state }, params) {
            return await Api().get(
                `capa-correction-actions${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postCapaCorrectionAction({ commit, state }, params) {
            return await Api().post(
                `capa-correction-actions`, params
            ).then((res) => {
                return res.data
            })
        },
        async editCapaCorrectionAction({ commit, state }, params) {
            return await Api().put(
                `capa-correction-actions/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyCapaCorrectionAction({ commit, state }, params) {
            return await Api().delete(
                `capa-correction-actions/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getCapaCorrectionAction(state) {
            return state.capaCorrectionAction;
        },
        getCapaCorrectionActionStatus(state) {
            return state.capaCorrectionActionStatus;
        },
    }
}