<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditChecklist" @submit.prevent="handleSubmit">
        <DxSelectBox
          class="custom-form"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis"
          value-expr="id"
          display-expr="name"
          :is-required="true"
          @valueChanged="changeUnitBisnis"
          v-model="auditChecklist.company_id"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>
        <DxSelectBox
          class="custom-form"
          :data-source="departmentOptions"
          label="Pilih Department"
          value-expr="department_id"
          display-expr="department_name"
          v-model="auditChecklist.department_id"
          :is-required="true"
          :read-only="readOnlyDept"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextArea
          class="custom-form"
          label="Clausul Relevan"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="auditChecklist.clausul_relevan"
        >
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
          
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditChecklist: {},
      readOnly: false,
      unitBisnisOptions: "",
      departmentOptions: "",
      readOnlyDept: true,
    };
  },
  computed: {
    ...mapGetters("auditChecklist", [
      "getAuditChecklist",
      "getAuditChecklistStatus",
    ]),
    ...mapGetters("auditPlan", ["getAuditPlan"]),
  },
  mounted() {
    if (this.getAuditChecklistStatus.action == "Edit") {
      this.auditChecklist = this.getAuditChecklist;
    }
    this.unitBisnis();
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  emits: ["get-audit-checklist"],
  methods: {
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      this.readOnlyDept = false;
      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-audit-checklist", this.auditChecklist);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
          
          <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>