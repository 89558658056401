import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditChecklist: null,
            auditChecklistStatus: null,
        }
    },
    mutations: {
        setAuditChecklist(state, payload) {
            state.auditChecklist = payload
        },
        setAuditChecklistStatus(state, payload) {
            state.auditChecklistStatus = payload
        },
    },
    actions: {
        setAuditChecklist({ commit }, params) {
            commit('setAuditChecklist', params)
        },
        setAuditChecklistStatus({ commit }, params) {
            commit('setAuditChecklistStatus', params)
        },
        async getAuditChecklist({ commit, state }, params) {
            return await Api().get(
                `audit-checklists${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditChecklist({ commit, state }, params) {
            return await Api().post(
                `audit-checklists`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAuditChecklist({ commit, state }, params) {
            return await Api().put(
                `audit-checklists/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditChecklist({ commit, state }, params) {
            return await Api().delete(
                `audit-checklists/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditChecklist(state) {
            return state.auditChecklist;
        },
        getAuditChecklistStatus(state) {
            return state.auditChecklistStatus;
        },
    }
}