<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addPicChecklist" @submit.prevent="handleSubmit">
        <DxTextBox
          class="custom-form"
          label="PIC"
          v-model:value="picChecklist.pic"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
              
    <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      picChecklist: {},
      readOnly: false,
      unitBisnisOptions: "",
      departmentOptions: "",
      readOnlyDept: true,
    };
  },
  computed: {
    ...mapGetters("picChecklist", ["getPicChecklist", "getPicChecklistStatus"]),
    ...mapGetters("scopeActivities", ["getScopeActivities"]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  mounted() {
    this.unitBisnis();
    if (this.getPicChecklistStatus.action == "Edit") {
      this.picChecklist = this.getPicChecklist;

      // const depart = this.getAllDepartment.find(
      //   (res) => res.department_id == this.getPicChecklist.pic_depart
      // );
      // this.picChecklist.company_id = depart.company_id;
    }
  },
  props: {},
  emits: ["get-pic-checklist"],
  methods: {
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      this.readOnlyDept = false;
      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    async handleSubmit(e) {
      this.loading = true;
      if (this.getPicChecklistStatus.action == "Add") {
        this.picChecklist.activity_id = this.getScopeActivities.id;
      }
      this.$emit("get-pic-checklist", this.picChecklist);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
              
              <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>