import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            faultType: null,
            faultTypeStatus: null,
        }
    },
    mutations: {
        setFaultType(state, payload) {
            state.faultType = payload
        },
        setFaultTypeStatus(state, payload) {
            state.faultTypeStatus = payload
        },
    },
    actions: {
        setFaultType({ commit }, params) {
            commit('setFaultType', params)
        },
        setFaultTypeStatus({ commit }, params) {
            commit('setFaultTypeStatus', params)
        },
        async getFaultType({ commit, state }, params) {
            return await Api().get(
                `fault-types${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postFaultType({ commit, state }, params) {
            return await Api().post(
                `fault-types`, params
            ).then((res) => {
                return res.data
            })
        },
        async editFaultType({ commit, state }, params) {
            return await Api().put(
                `fault-types/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyFaultType({ commit, state }, params) {
            return await Api().delete(
                `fault-types/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getFaultType(state) {
            return state.faultType;
        },
        getFaultTypeStatus(state) {
            return state.faultTypeStatus;
        },
    }
}