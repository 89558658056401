import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditImplementation: null,
            auditImplementationStatus: null,
        }
    },
    mutations: {
        setAuditImplementation(state, payload) {
            state.auditImplementation = payload
        },
        setAuditImplementationStatus(state, payload) {
            state.auditImplementationStatus = payload
        },
    },
    actions: {
        setAuditImplementation({ commit }, params) {
            commit('setAuditImplementation', params)
        },
        setAuditImplementationStatus({ commit }, params) {
            commit('setAuditImplementationStatus', params)
        },
        async getAuditImplementation({ commit, state }, params) {
            return await Api().get(
                `audit-implementations${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditImplementation({ commit, state }, params) {
            return await Api().post(
                `audit-implementations`, params, { headers: { "Content-Type": "multipart/form-data" }, }
            ).then((res) => {
                return res.data
            })
        },
        async editAuditImplementation({ commit, state }, params) {
            return await Api().post(
                `audit-implementations/${params.id}`, params, { headers: { "Content-Type": "multipart/form-data" }, }
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditImplementation({ commit, state }, params) {
            return await Api().delete(
                `audit-implementations/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditImplementation(state) {
            return state.auditImplementation;
        },
        getAuditImplementationStatus(state) {
            return state.auditImplementationStatus;
        },
    }
}