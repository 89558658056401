import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditVerificationItem: null,
            auditVerificationItemStatus: null,
        }
    },
    mutations: {
        setAuditVerificationItem(state, payload) {
            state.auditVerificationItem = payload
        },
        setAuditVerificationItemStatus(state, payload) {
            state.auditVerificationItemStatus = payload
        },
    },
    actions: {
        setAuditVerificationItem({ commit }, params) {
            commit('setAuditVerificationItem', params)
        },
        setAuditVerificationItemStatus({ commit }, params) {
            commit('setAuditVerificationItemStatus', params)
        },
        async getAuditVerificationItem({ commit, state }, params) {
            return await Api().get(
                `audit-verification-items${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditVerificationItem({ commit, state }, params) {
            return await Api().post(
                `audit-verification-items`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAuditVerificationItem({ commit, state }, params) {
            return await Api().put(
                `audit-verification-items/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditVerificationItem({ commit, state }, params) {
            return await Api().delete(
                `audit-verification-items/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditVerificationItem(state) {
            return state.auditVerificationItem;
        },
        getAuditVerificationItemStatus(state) {
            return state.auditVerificationItemStatus;
        },
    }
}