<template>
  <form action="post-action" @submit.prevent="handleSubmit">
    <DxSelectBox
      class="custom-form"
      :data-source="docTypeOptions"
      label="Pilih Tipe Dokumen"
      value-expr="name"
      display-expr="name"
      v-model="documents.document_type"
      :is-required="true"
      :read-only="readOnly"
    >
      <DxValidator>
        <DxRequiredRule message="Required" />
      </DxValidator>
    </DxSelectBox>

    <DxSelectBox
      class="custom-form"
      :data-source="unitBisnisOptions"
      label="Pilih Unit Bisnis"
      value-expr="id"
      display-expr="name"
      v-model="documents.company_id"
      :is-required="true"
      @valueChanged="changeUnitBisnis"
      :read-only="readOnly"
    >
      <DxValidator>
        <DxRequiredRule message="Required" />
      </DxValidator>
    </DxSelectBox>

    <DxSelectBox
      class="custom-form"
      :data-source="departmentOptions"
      label="Pilih Department"
      value-expr="department_id"
      display-expr="department_name"
      v-model="documents.department_id"
      :is-required="true"
      :read-only="readOnlyDept || readOnly"
    >
      <DxValidator>
        <DxRequiredRule message="Required" />
      </DxValidator>
    </DxSelectBox>

    <DxTextBox
      class="custom-form"
      label="Nama Dokumen"
      v-model:value="documents.document_name"
      :is-required="true"
      ><DxValidator>
        <DxRequiredRule message="Required" />
      </DxValidator>
    </DxTextBox>

    <DxTextArea
      class="custom-form"
      label="Ruang Lingkup"
      :height="90"
      :input-attr="{ 'aria-label': 'Notes' }"
      v-model:value="documents.document_scope"
    >
      <DxValidator>
        <DxRequiredRule message="Required" />
      </DxValidator>
    </DxTextArea>

    <DxTagBox
      class="custom-form"
      :search-enabled="true"
      :data-source="relatedDepartments"
      label="Pilih Department Relevan"
      display-expr="department_name"
      value-expr="department_id"
      :value="valueRelated"
      @value-changed="onValueChanged"
      :show-selection-controls="true"
      :maxFilterQueryLength="9999"
      item-template="item"
    >
      <template #item="{ data: item }">
        <div class="custom-item">
          <div class="product-name">
            {{ item.department_name }} <b>|| {{ item.company_name }}</b>
          </div>
        </div>
      </template>
      <DxValidator>
        <DxRequiredRule message="Required" />
      </DxValidator>
    </DxTagBox>

    <DxButton
      v-if="!loading"
      text="Simpan Data"
      type="success"
      icon="save"
      :use-submit-behavior="true"
    />
  </form>
</template>
  
  <script>
import DxButton from "devextreme-vue/button";
import DxSelectBox from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import DxTextArea from "devextreme-vue/text-area";
import DxTagBox from "devextreme-vue/tag-box";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

export default {
  components: {
    DxButton,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxTextArea,
    DxTagBox,
  },
  data() {
    return {
      id: this.$route.params.id,
      formRef: "form-add-document",
      docTypeOptions: [
        {
          name: "KETENTUAN PERUSAHAAN",
        },
        {
          name: "SOP",
        },
        {
          name: "WI",
        },
        {
          name: "FORM",
        },
      ],
      unitBisnisOptions: "",
      departmentOptions: "",
      readOnlyDept: true,
      sectionOptions: "",
      buttonOptions: {
        text: "Register",
        type: "success",
        useSubmitBehavior: true,
      },
      documents: {},
      loading: false,
      valueRelated: [],
      relatedDepartments: {},
      readOnly: false,
      docNameOld: "",
    };
  },
  mounted() {
    this.unitBisnis();
    this.getRelatedDepartments();
    if (this.getDocumentStatus == "Edit") {
      this.dataDocument();
    }
    if (this.getDocumentStatus == "Redirect") {
      this.loading = true;
      this.getRequestDoc();
    }
  },
  computed: {
    ...mapGetters("document", ["getDocument", "getDocumentStatus"]),
    ...mapGetters("requestDocument", ["getRequestDocument"]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["get-document"],
  methods: {
    async getDocumentByName() {
      const doc = await this.$store.dispatch(
        "document/getDocuments",
        `?document_name=${this.documents.document_name}`
      );
      return doc.data;
    },
    async getRelatedDepartments() {
      this.relatedDepartments = this.getAllDepartment;
    },
    onValueChanged(e) {
      let related = "";
      related = e.value;

      this.documents.related_department = related.join();
    },
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      this.readOnlyDept = false;
      this.readOnlyDocNum = true;
      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    async dataDocument() {
      let results = this.getDocument;
      this.documents = results;
      this.docNameOld = results.document_name;
      this.readOnly = true;
      if (this.documents.related_department != null) {
        this.valueRelated = this.documents.related_department.split(",");
      }
    },
    async getRequestDoc() {
      let results = this.getRequestDocument;

      const depart = this.getAllDepartment.find(
        (res) => res.department_id == results.department_id
      );

      this.documents.document_name = results.doc_name;
      this.docNameOld = results.doc_name;
      this.documents.document_type = results.doc_type;
      this.documents.company_id = depart.company_id;
      this.documents.department_id = results.department_id;

      this.readOnly = true;
      this.loading = false;
    },
    async handleSubmit() {
      if (this.docNameOld) {
        if (this.docNameOld == this.documents.document_name) {
          this.loading = true;
          this.$emit("get-document", this.documents);
          return;
        }
      }
      const doc = await this.getDocumentByName();
      if (doc.length > 0) {
        notify("Nama dokumen sudah ada,Silahkan buat baru", "warning", 2000);
        return;
      }
      this.loading = true;
      this.$emit("get-document", this.documents);
    },
  },
};
</script>
  <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>