<template>
  <div>
    <div class="dx-tooltip-appointment-item">
      <div class="dx-tooltip-appointment-item-marker">
        <div
          class="dx-tooltip-appointment-item-marker-body"
          :style="{ backgroundColor: markerColor }"
        ></div>
      </div>
      <div class="dx-tooltip-appointment-item-content">
        <div class="dx-tooltip-appointment-item-content">
          <div class="dx-tooltip-appointment-item-content-subject">
            {{ tooltipTemplate.agenda_type }} |
            {{ tooltipTemplate.event_name }} |
            {{ tooltipTemplate.audit_category }}
          </div>
          <div class="right">
            <div
              :class="
                tooltipTemplate.status ? 'status-done' : 'status-planning'
              "
            >
              {{ tooltipTemplate.status ? "Done" : "Planning" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DxButton } from "devextreme-vue/button";
import { mapGetters, mapActions } from "vuex";

import router from "@/router";

export default {
  components: {
    DxButton,
  },
  data() {
    return {};
  },
  props: {
    tooltipTemplate: {
      type: Object,
      default: () => {},
    },
    markerColor: {
      type: String,
    },
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.right {
  text-align: right; /* Memposisikan elemen di sebelah kanan kontainer */
}
.status-done {
  background-color: #8bc34a;
  color: white;
  font-weight: bold;
  text-align: end;
  border-radius: 25px;
  padding: 5px;
  display: inline-block;
  white-space: nowrap;
}
.status-planning {
  background-color: #ffff00;
  color: black;
  font-weight: bold;
  text-align: end;
  border-radius: 25px;
  padding: 5px;
  display: inline-block;
  white-space: nowrap;
}

.category-closed {
  background-color: #c4c4c4;
  color: white;
  font-weight: bold;
  text-align: end;
  border-radius: 25px;
  padding: 5px;
  display: inline-block;
  white-space: nowrap;
}
</style>