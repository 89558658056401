<template>
  <div>
    <div class="widget-container">
      <h2 class="content-block">Membuat Akun Baru</h2>
      <div class="content-block dx-card responsive-paddings">
        <form action="/addRequestDocument" @submit.prevent="handleSubmit">
          <DxSelectBox
            class="custom-form"
            :data-source="unitBisnisOptions"
            label="Pencarian Data User Berdasarkan Unit Bisnis"
            value-expr="id"
            display-expr="name"
            :is-required="true"
            @valueChanged="changeUnitBisnis"
          >
          </DxSelectBox>
          <DxSelectBox
            class="custom-form"
            :data-source="departmentOptions"
            label="Pencarian Data User Berdasarkan Department"
            value-expr="department_id"
            display-expr="department_name"
            :is-required="true"
            :read-only="readOnlyDept"
            @valueChanged="changeDepartment"
          >
          </DxSelectBox>
          <!-- v-model.selected-item="valueDepartment" -->

          <DxSelectBox
            class="custom-form"
            :data-source="dataUser"
            label="Pilih Data User"
            value-expr="user_id"
            display-expr="full_name"
            :accept-custom-value="true"
            :search-enabled="true"
            search-expr="full_name"
            @valueChanged="changeUser"
            :read-only="readOnlyUser"
            v-model.selected-item="user.user_detail_id"
            item-template="customItem"
          >
            <template #customItem="{ data: itemData }">
              <div>
                <div>
                  {{ itemData.nip }}
                  <b>| {{ itemData.full_name }}</b>
                </div>
              </div>
            </template>
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
            <!-- <DxValidationSummary /> -->
          </DxSelectBox>

          <DxTextBox
            class="custom-form"
            label="Username"
            v-model:value="user.username"
            :is-required="true"
            ><DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextBox>
          <DxTextBox
            class="custom-form"
            label="Password"
            v-model:value="user.password"
            :is-required="true"
            mode="password"
            ><DxValidator>
              <DxRequiredRule message="Required" />
              <DxStringLengthRule
                :min="8"
                message="The password must be at least 8 characters"
              />
            </DxValidator>
          </DxTextBox>
          <DxSelectBox
            class="custom-form"
            :data-source="roleOptions"
            label="Pilih Role"
            value-expr="code"
            display-expr="name"
            v-model="user.roles"
            :is-required="true"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
          <DxButton
            v-if="!loading"
            text="Submit the Form"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </form>
      </div>
    </div>
  </div>
</template>
      
      <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
  DxStringLengthRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      applicant: false,
      id: this.$route.params.id,
      dataUser: [],
      submitButtonOptions: {
        text: "Submit the Form",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      unitBisnisOptions: "",
      departmentOptions: "",
      roleOptions: [
        {
          code: 1,
          name: "user",
        },
        {
          code: 2,
          name: "head",
        },
        {
          code: 3,
          name: "admin",
        },
      ],
      readOnlyDept: true,
      readOnlyUser: true,
      user: {},
    };
  },
  mounted() {
    this.unitBisnis();
  },
  methods: {
    async changeDepartment(params) {
      console.log(params.value);
      const text = `?department=${params.value}`;
      const employee = await this.$store.dispatch(
        "authentication/getEmployees",
        text
      );
      this.dataUser = employee.data;
      this.readOnlyUser = false;
    },
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );
      this.readOnlyDept = false;
      this.readOnlyUser = true;
      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", "?");
      this.unitBisnisOptions = units.data;
    },
    changeUser(params) {
      console.log(params.value);
      if (params.value) {
        let data = this.dataUser;
        let userlogin = data.find((user) => user.user_id == params.value);
        this.user.username = userlogin.nip;
        this.user.password = userlogin.nip;
        this.user.roles = "user";
      }
    },
    handleSubmit(e) {
      this.$store
        .dispatch("authentication/register", this.user)
        .then((res) => {
          router.push(`/admin/account/info`);
          notify("Simpan data berhasil", "success", 2000);
        })
        .catch((e) => {
          const err = e.response.data.errors.username;
          if (err) {
            return notify(err, "error", 2000);
          }
          return notify("Simpan data gagal", "error", 2000);
        });
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxStringLengthRule,
    FilterTableUnitBisnis,
  },
};
</script>
      
      <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>