<template>
  <div>
    <!-- <h2 class="content-block">
      Daftar Permintaan Dokumen <span v-if="title">{{ title }}</span>
    </h2> -->
    <tableRequestDoc :role="role" :condition="null"></tableRequestDoc>
    <!-- :request-status="requestStatus" -->
    <!-- @title="getTitle" -->
  </div>
</template>
  
  <script>
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import tableRequestDoc from "@/components/documentManagement/tableRequestDoc.vue";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";

export default {
  data() {
    return {
      role: "user",
      requestStatus: "pending",
      title: false,
    };
  },
  methods: {
    getTitle(value) {
      this.title = value;
    },
  },
  components: {
    FilterTableUnitBisnis,
    tableRequestDoc,
  },
};
</script>