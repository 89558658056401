<template>
  <div>
    <h2 class="content-block" v-if="getFactorFindingStatus">
      {{ getFactorFindingStatus.action == "Add" ? "Membuat" : "Edit" }}
      Faktor Temuan Audit
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formAuditFactorFinding
      @get-audit-factor-finding="submit"
    ></formAuditFactorFinding>
  </div>
</template>

<script>
import formAuditFactorFinding from "@/components/audit/formAuditFactorFinding.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formAuditFactorFinding,
    DxButton,
  },
  computed: {
    ...mapGetters("factorFinding", ["getFactorFindingStatus"]),
  },
  methods: {
    async submit(value) {
      if (this.getFactorFindingStatus.action == "Add") {
        this.$store
          .dispatch("factorFinding/postFactorFinding", value)
          .then((res) => {
            router.push(`/admin/audit-factor-finding/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getFactorFindingStatus.action == "Edit") {
        this.$store
          .dispatch("factorFinding/editFactorFinding", value)
          .then((res) => {
            router.push(`/admin/audit-factor-finding/info`);
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>