<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/auditPrevective" @submit.prevent="handleSubmit">
        <p>contoh : koordinator membuat pengajuan gembok untuk setiap armada</p>
        <div v-for="(form, index) in auditPrevective" :key="index">
          <DxTextArea
            class="custom-form"
            label="Perbaikan Sementara *"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.action"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextArea>
          <DxButton
            v-if="auditPrevective.length > 1"
            class="customBtn"
            text="Form"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading">
          <DxButton
            v-if="paramsRoute.condition == 'add' && auditPrevective.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditPrevective: [
        {
          report_id: "",
          action: "",
        },
      ],
      readOnly: false,
      paramsRoute: this.$route.params,
    };
  },
  computed: {
    ...mapGetters("auditPrevective", [
      "getAuditPrevective",
      "getAuditPrevectiveStatus",
    ]),
    ...mapGetters("auditReport", ["getAuditReport"]),
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getAuditPrevective) {
        this.auditPrevective[0] = this.getAuditPrevective;
      } else {
        this.loadAuditPrevective(this.paramsRoute.id);
      }
    }
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  emits: ["get-audit-prevective"],
  methods: {
    async loadAuditPrevective(params) {
      try {
        const getAuditPrevective = await this.$store.dispatch(
          "auditPrevective/getAuditPrevective",
          `/${params}`
        );

        this.auditPrevective[0] = getAuditPrevective.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.auditPrevective.push({
        action: "",
      });
    },
    removeForm(index) {
      this.auditPrevective.splice(index, 1);
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-audit-prevective", this.auditPrevective);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.customBtn {
  margin: 1%;
}
</style>