<template>
  <div>
    <div>
      <tableAuditFindingType :roles="'admin'"></tableAuditFindingType>
    </div>
  </div>
</template>
      
  <script>
import tableAuditFindingType from "@/components/audit/tableAuditFindingType.vue";
export default {
  components: {
    tableAuditFindingType,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>