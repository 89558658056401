<template>
  <div>
    <h2 class="content-block">
      {{ getRiskControlItemStatus.action == "Add" ? "Membuat" : "Edit" }} Item
      Pengendalian Risiko
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formRiskControlItem @get-risk-control-item="submit"></formRiskControlItem>
  </div>
</template>
  <script>
import formRiskControlItem from "@/components/riskManagement/formRiskControlItem.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formRiskControlItem,
    DxButton,
  },
  computed: {
    ...mapGetters("riskControlItem", ["getRiskControlItemStatus"]),
    redirect() {
      if (this.getRiskControlItemStatus.roles == "suAdmin") {
        router.push(`/super-admin/risk-control-item/info`);
      } else if (this.getRiskControlItemStatus.roles == "admin") {
        router.push(`/admin/risk-control-item/info`);
      } else {
        console.log("error");
      }
    },
  },
  methods: {
    async submit(value) {
      const vThis = this;
      if (this.getRiskControlItemStatus.action == "Add") {
        this.$store
          .dispatch("riskControlItem/postRiskControlItem", value)
          .then((res) => {
            vThis.redirect;
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getRiskControlItemStatus.action == "Edit") {
        this.$store
          .dispatch("riskControlItem/editRiskControlItem", value)
          .then((res) => {
            vThis.redirect;
            notify("Berhasil", "success", 2000);
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>