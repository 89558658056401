import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            regulationDetail: null,
            regulationDetailStatus: null,
        }
    },
    mutations: {
        setRegulationDetail(state, payload) {
            state.regulationDetail = payload
        },
        setRegulationDetailStatus(state, payload) {
            state.regulationDetailStatus = payload
        },
    },
    actions: {
        setRegulationDetail({ commit }, params) {
            commit('setRegulationDetail', params)
        },
        setRegulationDetailStatus({ commit }, params) {
            commit('setRegulationDetailStatus', params)
        },
        async getRegulationDetails({ commit, state }, params) {
            return await Api().get(
                `regulation-details${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getRegulationDetail({ commit, state }, params) {
            return await Api().get(
                `regulation-details/${params.id}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRegulationDetails({ }, params) {
            return await Api().post(
                `regulation-details`, params
            )
        },
        async updateRegulationDetails({ }, params) {
            return await Api().put(
                `regulation-details/${params.id}`, params
            )
        },
        async destroyRegulationDetails({ }, params) {
            return await Api().delete(
                `regulation-details/${params.id}`, params
            )
        },
    },
    getters: {
        getRegulationDetail(state) {
            return state.regulationDetail;
        },
        getRegulationDetailStatus(state) {
            return state.regulationDetailStatus;
        }
    }
}