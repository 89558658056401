<template>
  <div class="widget-container">
    <h2 class="content-block">
      {{ getRegulationDetailStatus.action == "Add" ? "Membuat" : "Edit" }}
      Detail Regulasi
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <div class="content-block dx-card responsive-paddings">
      <form-regulation-detail @get-regulation-detail="saveData" />
    </div>
  </div>
</template>
    <script>
import DxButton from "devextreme-vue/button";
import formRegulationDetail from "@/components/regulation/formRegulationDetail.vue";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import router from "@/router";
export default {
  components: {
    formRegulationDetail,
    DxButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("regulationDetail", ["getRegulationDetailStatus"]),
    ...mapGetters("regulationList", ["getRegulationList"]),
  },
  methods: {
    async saveData(params) {
      if (this.getRegulationDetailStatus.action == "Add") {
        this.$store
          .dispatch("regulationDetail/postRegulationDetails", params)
          .then((res) => {
            if (this.getRegulationList.status == "done") {
              this.getRegulationList.status = "process";
              this.$store.dispatch(
                "regulationList/updateRegulationLists",
                this.getRegulationList
              );
            }
            this.$store.dispatch("regulationImpl/setRegulationImplStatus", {
              action: "Add",
              roles: this.getRegulationDetailStatus.roles,
            });
            this.$store.dispatch(
              "regulationDetail/setRegulationDetail",
              res.data.data
            );
            notify("berhasil", "success", 2000);
            router.push(`/regulation-impl/form`);
          })
          .catch((e) => {
            console.log(e);
            notify("gagal", "error", 2000);
          });
      } else if (this.getRegulationDetailStatus.action == "Edit") {
        this.$store
          .dispatch("regulationDetail/updateRegulationDetails", params)
          .then((res) => {
            notify("berhasil", "success", 2000);
            if (this.getRegulationDetailStatus.roles == "suAdmin") {
              router.push(`/super-admin/regulation-detail/info`);
            } else {
              router.push(`/user/regulation-detail/manage`);
            }
          })
          .catch((e) => {
            console.log(e);
            notify("gagal", "error", 2000);
          });
      } else {
        notify("gagal", "error", 2000);
        return;
      }
    },
  },
};
</script>