<template>
  <div>
    <h2 class="content-block">
      {{ getAuditImplementationStatus.action == "Add" ? "Membuat" : "Edit" }}
      Daftar Bukti Implementasi
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <formAuditImpl @get-audit-impl="submit"></formAuditImpl>
  </div>
</template>
              <script>
import formAuditImpl from "@/components/audit/formAuditImplementation.vue";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    formAuditImpl,
    DxButton,
  },
  computed: {
    ...mapGetters("auditImplementation", ["getAuditImplementationStatus"]),
    redirect() {
      if (this.getAuditImplementationStatus.roles == "user") {
        router.push(`/user/audit-report/info`);
      } else if (this.getAuditImplementationStatus.roles == "suAdmin") {
        // router.push(`/admin/audit-report/info`);
        console.log("suadmin");
      } else {
        router.push(`/admin/audit-report/info`);
      }
    },
  },
  methods: {
    async submit(value) {
      if (this.getAuditImplementationStatus.action == "Add") {
        this.$store
          .dispatch("auditImplementation/postAuditImplementation", value)
          .then((res) => {
            notify("Berhasil", "success", 2000);
            this.redirect;
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else if (this.getAuditImplementationStatus.action == "Edit") {
        value._method = "PUT";
        this.$store
          .dispatch("auditImplementation/editAuditImplementation", value)
          .then((res) => {
            notify("Berhasil", "success", 2000);
            this.redirect;
          })
          .catch((e) => {
            console.log(e);
            notify(e.message, "error", 2000);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>