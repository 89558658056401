<template>
  <div>
    <div class="master-detail-caption">Daftar PIC Checklist :</div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton hint="edit" icon="fa fa-pen-to-square" @click="edit" />
        <DxButton
          hint="hapus"
          icon="trash"
          @click="popupDestroy"
          v-if="roles == 'suAdmin'"
        />
      </dx-column>
      <dx-column data-field="pic" caption="PIC Checklist" />

      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: picChecklist }">
        <tablePotentialRisk
          :roles="roles"
          :picChecklist="picChecklist.data"
          :auditChecklist="auditChecklist"
        ></tablePotentialRisk>
      </template>
    </dx-data-grid>
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
          <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import tablePotentialRisk from "@/components/audit/tablePotentialRisk.vue";

import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
    tablePotentialRisk,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 9999,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            activity: vthis.scopeActivities.id,
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "pic-checklist-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      popupDelete: false,
      deleteData: {},
    };
  },
  mounted() {},
  props: {
    roles: {
      type: String,
    },
    scopeActivities: {
      type: Object,
    },
    auditChecklist: {
      type: Object,
    },
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
  },
  methods: {
    confirmDestroy() {
      this.$store
        .dispatch("picChecklist/destroyPicChecklist", this.deleteData)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteData = params.row.data;
    },
    edit(params) {
      this.$store.dispatch("picChecklist/setPicChecklistStatus", {
        action: "Edit",
        roles: this.roles,
      });
      this.$store.dispatch(
        "scopeActivities/setScopeActivities",
        this.processList
      );
      this.$store.dispatch("picChecklist/setPicChecklist", params.row.data);
      router.push(`/pic-checklist/form`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "add",
          text: "Tambah",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.$store.dispatch(
              "scopeActivities/setScopeActivities",
              vThis.scopeActivities
            );
            vThis.$store.dispatch("picChecklist/setPicChecklistStatus", {
              action: "Add",
              roles: vThis.roles,
            });
            router.push(`/pic-checklist/form`);
          },
        },
      });
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "picChecklist/getPicChecklist",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>
          <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>