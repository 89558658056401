<template>
  <div>
    <div>
      <tableRiskControl :role="'suAdmin'"></tableRiskControl>
    </div>
  </div>
</template>
      
      <script>
import tableRiskControl from "@/components/riskManagement/tableRiskControl.vue";
import router from "@/router";
export default {
  components: {
    tableRiskControl,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>