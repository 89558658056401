<template>
  <div>
    <!-- <h2 class="content-block">
      Riwayat Permintaan Dokumen <span v-if="title">{{ title }}</span>
    </h2> -->
    <tableRequestDoc :condition="'history'" :role="role"></tableRequestDoc>
    <!-- @title="getTitle" -->
  </div>
</template>
  
  <script>
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import tableRequestDoc from "@/components/documentManagement/tableRequestDoc.vue";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";

export default {
  data() {
    return {
      role: "user",
      history: "pending",
      title: false,
    };
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
  },
  methods: {
    async getTitle(value) {
      this.title = value;
    },
  },
  components: {
    FilterTableUnitBisnis,
    tableRequestDoc,
  },
};
</script>