import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            improvementReport: null,
            improvementReportStatus: null,
            perImprovementReport: null,
        }
    },
    mutations: {
        setImprovementReport(state, payload) {
            state.improvementReport = payload
        },
        setImprovementReportStatus(state, payload) {
            state.improvementReportStatus = payload
        },
        setPerImprovementReport(state, payload) {
            state.perImprovementReport = payload
        },
    },
    actions: {
        setImprovementReport({ commit }, params) {
            commit('setImprovementReport', params)
        },
        setImprovementReportStatus({ commit }, params) {
            commit('setImprovementReportStatus', params)
        },
        setPerImprovementReport({ commit }, params) {
            commit('setPerImprovementReport', params)
        },
        async getImprovementReport({ commit, state }, params) {
            return await Api().get(
                `improvement-reports${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postImprovementReport({ commit, state }, params) {
            return await Api().post(
                `improvement-reports`, params
            ).then((res) => {
                return res.data
            })
        },
        async editImprovementReport({ commit, state }, params) {
            return await Api().put(
                `improvement-reports/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyImprovementReport({ commit, state }, params) {
            return await Api().delete(
                `improvement-reports/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getImprovementReport(state) {
            return state.improvementReport;
        },
        getImprovementReportStatus(state) {
            return state.improvementReportStatus;
        },
        getPerImprovementReport(state) {
            return state.perImprovementReport;
        },
    }
}