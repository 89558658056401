<template>
  <div>
    <table-regulation-detail :manage="false"></table-regulation-detail>
  </div>
</template>
      
      <script>
import tableRegulationDetail from "@/components/regulation/tableRegulationDetail.vue";
import router from "@/router";
import axios from "axios";
import "whatwg-fetch";
export default {
  components: {
    tableRegulationDetail,
  },
  data() {
    return {
      regulation: this.$route.params.id,
      btnAdd: null,
      title: "",
      detail: {},
    };
  },
  mounted() {},
  methods: {},
};
</script>