import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            riskControlDetail: null,
            riskControlDetailStatus: null,
        }
    },
    mutations: {
        setRiskControlDetail(state, payload) {
            state.riskControlDetail = payload
        },
        setRiskControlDetailStatus(state, payload) {
            state.riskControlDetailStatus = payload
        },
    },
    actions: {
        setRiskControlDetail({ commit }, params) {
            commit('setRiskControlDetail', params)
        },
        setRiskControlDetailStatus({ commit }, params) {
            commit('setRiskControlDetailStatus', params)
        },
        async getRiskControlDetail({ commit, state }, params) {
            return await Api().get(
                `risk-control-details${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRiskControlDetail({ commit, state }, params) {
            return await Api().post(
                `risk-control-details`, params
            ).then((res) => {
                return res.data
            })
        },
        async editRiskControlDetail({ commit, state }, params) {
            return await Api().put(
                `risk-control-details/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyRiskControlDetail({ commit, state }, params) {
            return await Api().delete(
                `risk-control-details/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getRiskControlDetail(state) {
            return state.riskControlDetail;
        },
        getRiskControlDetailStatus(state) {
            return state.riskControlDetailStatus;
        },
    }
}