import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            scopeActivities: null,
            scopeActivitiesStatus: null,
        }
    },
    mutations: {
        setScopeActivities(state, payload) {
            state.scopeActivities = payload
        },
        setScopeActivitiesStatus(state, payload) {
            state.scopeActivitiesStatus = payload
        },
    },
    actions: {
        setScopeActivities({ commit }, params) {
            commit('setScopeActivities', params)
        },
        setScopeActivitiesStatus({ commit }, params) {
            commit('setScopeActivitiesStatus', params)
        },
        async getScopeActivities({ commit, state }, params) {
            return await Api().get(
                `scope-activities${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postScopeActivities({ commit, state }, params) {
            return await Api().post(
                `scope-activities`, params
            ).then((res) => {
                return res.data
            })
        },
        async editScopeActivities({ commit, state }, params) {
            return await Api().put(
                `scope-activities/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyScopeActivities({ commit, state }, params) {
            return await Api().delete(
                `scope-activities/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getScopeActivities(state) {
            return state.scopeActivities;
        },
        getScopeActivitiesStatus(state) {
            return state.scopeActivitiesStatus;
        },
    }
}