<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditRiskControl" @submit.prevent="handleSubmit">
        <DxTextArea
          class="custom-form"
          label="Pengendalian Risiko"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="auditRiskControl.name"
        >
        </DxTextArea>
        <DxTextBox
          class="custom-form"
          label="Tipe"
          v-model:value="auditRiskControl.type"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditRiskControl: {},
      readOnly: false,
      verificationStatusOptions: [
        { name: "OK" },
        { name: "NC" },
        { name: "Obs" },
      ],
    };
  },
  computed: {
    ...mapGetters("auditRiskControl", [
      "getAuditRiskControl",
      "getAuditRiskControlStatus",
    ]),
    ...mapGetters("potentialRisk", ["getPotentialRisk"]),
  },
  mounted() {
    if (this.getAuditRiskControlStatus.action == "Edit") {
      this.auditRiskControl = this.getAuditRiskControl;
    }
  },
  props: {},
  emits: ["get-audit-risk-control"],
  methods: {
    async handleSubmit(e) {
      this.loading = true;
      if (this.getAuditRiskControlStatus.action == "Add") {
        this.auditRiskControl.potential_id = this.getPotentialRisk.id;
      }
      this.$emit("get-audit-risk-control", this.auditRiskControl);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>