import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            processList: null,
            processListStatus: null,
        }
    },
    mutations: {
        setProcessList(state, payload) {
            state.processList = payload
        },
        setProcessListStatus(state, payload) {
            state.processListStatus = payload
        },
    },
    actions: {
        setProcessList({ commit }, params) {
            commit('setProcessList', params)
        },
        setProcessListStatus({ commit }, params) {
            commit('setProcessListStatus', params)
        },
        async getProcessList({ commit, state }, params) {
            return await Api().get(
                `process-lists${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postProcessList({ commit, state }, params) {
            return await Api().post(
                `process-lists`, params
            ).then((res) => {
                return res.data
            })
        },
        async editProcessList({ commit, state }, params) {
            return await Api().put(
                `process-lists/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyProcessList({ commit, state }, params) {
            return await Api().delete(
                `process-lists/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getProcessList(state) {
            return state.processList;
        },
        getProcessListStatus(state) {
            return state.processListStatus;
        },
    }
}