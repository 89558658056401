<template>
  <div>
    <h2 class="content-block">Kode Unit Bisnis</h2>
    <tableCode :role="'suAdmin'"></tableCode>
  </div>
</template>
        
  <script>
import tableCode from "@/components/master/tableCode.vue";

export default {
  data() {
    return {};
  },
  mounted() {},
  props: {},
  methods: {},
  components: {
    tableCode,
  },
};
</script>