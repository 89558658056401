import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            improvementEvaluation: null,
            improvementEvaluationStatus: null,
        }
    },
    mutations: {
        setImprovementEvaluation(state, payload) {
            state.improvementEvaluation = payload
        },
        setImprovementEvaluationStatus(state, payload) {
            state.improvementEvaluationStatus = payload
        },
    },
    actions: {
        setImprovementEvaluation({ commit }, params) {
            commit('setImprovementEvaluation', params)
        },
        setImprovementEvaluationStatus({ commit }, params) {
            commit('setImprovementEvaluationStatus', params)
        },
        async getImprovementEvaluation({ commit, state }, params) {
            return await Api().get(
                `improvement-evaluations${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postImprovementEvaluation({ commit, state }, params) {
            return await Api().post(
                `improvement-evaluations`, params, { headers: { "Content-Type": "multipart/form-data" }, }
            ).then((res) => {
                return res.data
            })
        },
        async editImprovementEvaluation({ commit, state }, params) {
            return await Api().post(
                `improvement-evaluations/${params.id}`, params, { headers: { "Content-Type": "multipart/form-data" }, }
            ).then((res) => {
                return res.data
            })
        },
        async destroyImprovementEvaluation({ commit, state }, params) {
            return await Api().delete(
                `improvement-evaluations/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getImprovementEvaluation(state) {
            return state.improvementEvaluation;
        },
        getImprovementEvaluationStatus(state) {
            return state.improvementEvaluationStatus;
        },
    }
}