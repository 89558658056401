import axios from "axios";
import Cookie from 'js-cookie'
import Api from "@/services/Api";
import { resetDynamicRoutes } from "@/router";
import CryptoJS from 'crypto-js';

export default {
    namespaced: true,
    state() {
        return {
            appSecret: Cookie.get('clientSecret') ? Cookie.get('clientSecret') : null,
            user: Cookie.get('clientLog') ? JSON.parse(Cookie.get('clientLog')) : null,
            detailUser: Cookie.get('clientSession') ? JSON.parse(Cookie.get('clientSession')) : null,
            popUpHome: null
        }
    },
    mutations: {
        setSecret(state, payload) {
            state.appSecret = payload
        },
        clearToken(state) {
            state.appSecret = null
            state.userId = null
            state.user = {}
            state.detailUser = {}
        },
        setUser(state, payload) {
            state.user = payload
        },
        setDetailUser(state, payload) {
            state.detailUser = payload
        },
        setPopUpHome(state, payload) {
            state.popUpHome = payload
        }
    },
    actions: {
        setPopUpHome({ commit }, params) {
            commit('setPopUpHome', params)
        },
        login({ commit, dispatch }, user) {
            return Api()
                .post("login", user)
                .then(async (result) => {
                    // set Cookie
                    Cookie.set('clientLog', JSON.stringify(result.data.data.user), {
                        path: '/',
                        sameSite: 'Lax'
                    })
                    Cookie.set('clientSession', JSON.stringify(result.data.data.user_detail), {
                        path: '/',
                        sameSite: 'Lax'
                    })
                    Cookie.set('clientSecret', result.data.data.access_token, {
                        path: '/',
                        sameSite: 'Lax'
                    })
                    Cookie.set('rl', result.data.data.rl)

                    // set State Vuex
                    commit('setUser', result.data.data.user)
                    commit('setDetailUser', result.data.data.user_detail)
                    commit('setSecret', result.data.data.access_token)

                    const menus = await Api().get(
                        `user-access?group=${result.data.data.user.user_group}&is_active=true&limit=9999`
                    );

                    const dashboards = await Api().get(
                        `dashboard-access?limit=9999&group=${result.data.data.user.user_group}`
                    );

                    localStorage.setItem('per_manage', CryptoJS.AES.encrypt(JSON.stringify(menus.data.data), 'secretKey').toString());

                    localStorage.setItem('per_dash', CryptoJS.AES.encrypt(JSON.stringify(dashboards.data.data), 'secretKey').toString());

                    return result
                })
        },
        logout({ commit, state }) {
            return Api().post('logout', {}).then(async (res) => {
                commit('clearToken')
                Cookie.remove('clientSecret')
                Cookie.remove('clientSession')
                Cookie.remove('rl')
                Cookie.remove('clientLog')
                localStorage.clear()
                // reset dynamic routes
                await resetDynamicRoutes()
                return res
            }).catch((err) => {
                console.log(err.response);
            })
        },
        // async user({ commit, state, dispatch }, params) {
        //     return await Api().get(`user/${params}`).then(res => {
        //         commit('setUser', res.data.data)
        //         dispatch('detailUser', { id: res.data.data.user_detail_id })
        //     }).catch(e => {
        //         console.log('silahkan login kembali');
        //         dispatch('logout');
        //     })
        // const token = Cookie.get("token");
        // let tokenData = token.split(".")[1];
        // const decode = JSON.parse(atob(tokenData));

        // const user = await Api().get(`user/${decode.sub}`);
        // const detail = await Api().get(`employees/${user.data.data.user_detail_id}`);
        // return {
        //     user: user.data.data,
        //     user_detail: detail.data.data
        // }
        // },
        // async detailUser({ commit, dispatch }, params) {
        //     return await Api().get(`employees/${params.id}`).then(res => {
        //         // console.log(res.data.data, 'detail user')
        //         commit('setDetailUser', res.data.data)
        //         // return res.data.data
        //     })
        //     // return Api().get(`employees/${params.id}`).then((res) => {
        //     //     return res.data
        //     // }).catch((err) => {
        //     //     console.log(err.response);
        //     // })
        // },
        getAccounts({ }, params) {
            return Api().get(`accounts${params}`).then((res) => {
                return res.data
            })
        },
        async getEmployees({ }, params) {
            return await Api().get(`employees${params}`).then((res) => {
                // console.log(res.data);
                return res.data
            }).catch((err) => {
                console.log(err.response, 'ini adalah ');
            })
        },
        async register({ }, params) {
            return await Api().post(`register`, params).then((res) => {
                return res.data
            })
        },
        async updateAccounts({ }, params) {
            return await Api().put(`accounts/${params.id}`, params).then((res) => {
                return res.data
            })
        },
        async destroyAccount({ }, params) {
            return await Api().delete(`accounts/${params.id}`, params).then((res) => {
                return res.data
            })
        },
    },
    getters: {
        getToken(state) {
            return state.appSecret;
        },
        getUser(state) {
            return state.user;
        },
        getUserDetail(state) {
            return state.detailUser;
        },
        getPopUpHome(state) {
            return state.popUpHome;
        }
    }
}