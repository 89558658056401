import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditPrevective: null,
            auditPrevectiveStatus: null,
        }
    },
    mutations: {
        setAuditPrevective(state, payload) {
            state.auditPrevective = payload
        },
        setAuditPrevectiveStatus(state, payload) {
            state.auditPrevectiveStatus = payload
        },
    },
    actions: {
        setAuditPrevective({ commit }, params) {
            commit('setAuditPrevective', params)
        },
        setAuditPrevectiveStatus({ commit }, params) {
            commit('setAuditPrevectiveStatus', params)
        },
        async getAuditPrevective({ commit, state }, params) {
            return await Api().get(
                `audit-prevectives${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditPrevective({ commit, state }, params) {
            return await Api().post(
                `audit-prevectives`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAuditPrevective({ commit, state }, params) {
            return await Api().put(
                `audit-prevectives/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditPrevective({ commit, state }, params) {
            return await Api().delete(
                `audit-prevectives/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditPrevective(state) {
            return state.auditPrevective;
        },
        getAuditPrevectiveStatus(state) {
            return state.auditPrevectiveStatus;
        },
    }
}