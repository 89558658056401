import axios from 'axios';
import Cookie from 'js-cookie'
import Api from '@/services/Api';
import CryptoJS from 'crypto-js';

export default {
    namespaced: true,
    state() {
        return {
            codeUnit: null,
            unitBisnis: localStorage.getItem('m_unt') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('m_unt'), 'secretKey').toString(CryptoJS.enc.Utf8)) : null,
            codeUnitStatus: null,
        }
    },
    mutations: {
        setCodeUnit(state, payload) {
            state.codeUnit = payload
        },
        setUnit(state, payload) {
            state.unitBisnis = payload
        },
        setCodeUnitStatus(state, payload) {
            state.codeUnitStatus = payload
        },
    },
    actions: {
        setCodeUnit({ commit }, params) {
            commit('setCodeUnit', params)
        },
        setCodeUnitStatus({ commit }, params) {
            commit('setCodeUnitStatus', params)
        },
        async getAllUnitBisnis({ commit, state }, params) {
            return await Api().get(
                `units${params}`,
            ).then((res) => {
                localStorage.setItem('m_unt', CryptoJS.AES.encrypt(JSON.stringify(res.data.data), 'secretKey').toString());

                commit('setUnit', res.data.data)
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getUnits({ commit, state }, params) {
            return await Api().get(
                `units${params}`,
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getCodeUnit({ commit, state }, params) {
            return await Api().get(
                `/units/code${params}`,
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postUnits({ }, params) {
            return await Api().post(
                "units", params
            )
        },
        async editUnits({ }, params) {
            return await Api().put(
                `units/${params.id}`, params
            )
        },
        async destroyUnits({ }, params) {
            return await Api().delete(
                `units/${params.id}`
            )
        },
    },
    getters: {
        getCodeUnit(state) {
            return state.codeUnit;
        },
        getAllUnitBisnis(state) {
            return state.unitBisnis;
        },
        getCodeUnitStatus(state) {
            return state.codeUnitStatus;
        },
    }
}