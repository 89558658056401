import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            requestDocument: null,
            requestDocumentStatus: null
        }
    },
    mutations: {
        setRequestDocument(state, payload) {
            state.requestDocument = payload
        },
        setRequestDocumentStatus(state, payload) {
            state.requestDocumentStatus = payload
        },
        cleanRequestDocument(state, payload) {
            state.requestDocument = null
        },
    },
    actions: {
        setRequestDocument({ commit }, params) {
            commit('setRequestDocument', params)
        },
        setRequestDocumentStatus({ commit }, params) {
            commit('setRequestDocumentStatus', params)
        },
        cleanRequestDocument({ commit }) {
            commit('cleanRequestDocument')
        },
        async getRequestDocument({ commit, state }, params) {
            return await Api()
                .get(`request-documents/${params}`).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async getRequestDocuments({ commit, state }, params) {
            return await Api()
                .get(`request-documents${params}`).then((res) => {
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async postRequestDocument({ }, params) {
            return await Api().post("request-documents", params, { headers: { "Content-Type": "multipart/form-data" }, });
        },
        async updateRequestDocument({ }, params) {
            return await Api()
                .post(`request-documents/${params.id}`,
                    params
                    , { headers: { "Content-Type": "multipart/form-data" }, });
        },
        async destroyRequestDocument({ }, params) {
            return await Api()
                .delete(`request-documents/${params.id}`
                );
        },
    },
    getters: {
        getRequestDocument(state) {
            return state.requestDocument;
        },
        getRequestDocumentStatus(state) {
            return state.requestDocumentStatus;
        },
    }
}