<template>
  <div>
    <div>
      <tableCapa :roles="'user'" :history="false"></tableCapa>
    </div>
  </div>
</template>
    
<script>
import tableCapa from "@/components/capa/tableCapa.vue";
export default {
  components: {
    tableCapa,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>