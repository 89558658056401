<template>
  <div>
    <div v-if="permissions.canCreate && auditeeProps">
      <DxButton
        v-if="!loading"
        class="customBtn"
        text="Kirim Perbaikan"
        styling-mode="outlined"
        type="success"
        icon="fa fa-paper-plane"
        @click="updatePopup"
      />
      <DxLoadIndicator v-else />
    </div>
    <DxTabPanel>
      <DxItem title="Analisa Akar Masalah" template="root-cause" />
      <template #root-cause>
        <tableAuditRootCause
          :roles="roles"
          :history="history"
          :auditee="auditeeProps"
          :auditReport="auditReport"
        ></tableAuditRootCause>
      </template>
      <DxItem
        title="Tindakan Perbaikan Sementara"
        template="audit-prevective"
      />
      <template #audit-prevective>
        <tableAuditPrevective
          :roles="roles"
          :history="history"
          :auditee="auditeeProps"
          :auditReport="auditReport"
        ></tableAuditPrevective>
      </template>
      <DxItem title="Tindakan Perbaikan Permanen" template="audit-correction" />
      <template #audit-correction>
        <tableAuditCorrection
          :roles="roles"
          :history="history"
          :auditee="auditeeProps"
          :auditReport="auditReport"
        ></tableAuditCorrection>
      </template>
      <DxItem title="Bukti Implementasi" template="audit-implementation" />
      <template #audit-implementation>
        <tableAudiitImpl
          :roles="roles"
          :history="history"
          :auditee="auditeeProps"
          :auditReport="auditReport"
        ></tableAudiitImpl>
      </template>
    </DxTabPanel>
  </div>

  <!-- popup send update audit report -->
  <DxPopup
    :width="300"
    height="auto"
    :show-title="true"
    title="Konfirmasi"
    :drag-enabled="false"
    :hide-on-outside-click="true"
    v-model:visible="popupUpdate"
  >
    <template #content="{}">
      <div class="popup-property-details">
        <b>Pastikan Perbaikan Sudah Lengkap ?</b>
        <div class="justify-center">
          <DxButton
            class="customBtn"
            text="ya"
            icon="check"
            type="success"
            @click="confirmUpdate()"
          />
          <DxButton
            class="customBtn"
            text="batal"
            icon="close"
            type="danger"
            @click="cancle()"
          />
        </div>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import DxButton from "devextreme-vue/button";
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import DxPopup from "devextreme-vue/popup";
import notify from "devextreme/ui/notify";

import tableAuditRootCause from "@/components/audit/tableAuditRootCause.vue";
import tableAuditPrevective from "@/components/audit/tableAuditPrevective.vue";
import tableAuditCorrection from "@/components/audit/tableAuditCorrection.vue";
import tableAudiitImpl from "@/components/audit/tableAuditImplementation.vue";

export default {
  components: {
    DxTabPanel,
    DxItem,
    tableAuditRootCause,
    tableAuditPrevective,
    tableAuditCorrection,
    tableAudiitImpl,
    DxButton,
    DxLoadIndicator,
    DxPopup,
  },
  data() {
    return {
      loading: false,
      popupUpdate: false,
      updateData: {},
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
      checking: null,
    };
  },
  mounted() {
    const permission = this.$route.meta.permission;
    // check permission
    this.permissions.canCreate = (permission & 2) > 0;
    this.permissions.canUpdate = (permission & 4) > 0;
    this.permissions.canVerified = (permission & 8) > 0;
    this.permissions.canDelete = (permission & 16) > 0;
    this.permissions.unfilteredData = (permission & 32) > 0;

    this.checkAuditee();
  },
  methods: {
    async checkAuditee() {
      const getAuditRootCause = await this.$store.dispatch(
        "auditRootCause/getAuditRootCause",
        `?report=${this.auditReport.id}`
      );
      const TRootCause = await getAuditRootCause.paginate.total;

      const getAuditPrevective = await this.$store.dispatch(
        "auditPrevective/getAuditPrevective",
        `?report=${this.auditReport.id}`
      );
      const TPrevective = await getAuditPrevective.paginate.total;

      const getAuditCorrection = await this.$store.dispatch(
        "auditCorrection/getAuditCorrection",
        `?report=${this.auditReport.id}`
      );
      const TCorrection = await getAuditCorrection.paginate.total;

      const getAuditImpl = await this.$store.dispatch(
        "auditImplementation/getAuditImplementation",
        `?report=${this.auditReport.id}`
      );
      const TImpl = await getAuditImpl.paginate.total;

      // check verification send data  null
      if (
        TRootCause == 0 ||
        TPrevective == 0 ||
        TCorrection == 0 ||
        TImpl == 0
      ) {
        return false;
      }
      return true;
    },
    async confirmUpdate() {
      this.popupUpdate = false;
      const checking = await this.checkAuditee();
      if (!checking) {
        notify("pengisian perbaikan belum diisi", "error", 2000);
        this.loading = false;
        return;
      }

      const vThis = this;
      this.updateData.status = "Pending";
      this.$store
        .dispatch("auditReport/editAuditReport", this.updateData)
        .then((res) => {
          notify("pengajuan audit success", "success", 2000);
          this.loading = false;
          vThis.$emit("refresh-audit", res.data);
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupUpdate = false;
    },
    async cancle() {
      this.popupUpdate = false;
      this.loading = false;
      this.updateData = {};
    },
    async updatePopup() {
      this.popupUpdate = true;
      this.updateData = this.auditReport;
      this.loading = true;
    },
  },
  emits: ["refresh-audit"],
  props: {
    roles: {
      type: String,
    },
    history: {
      type: Boolean,
    },
    auditeeProps: {
      type: Boolean,
      default: false,
    },
    auditReport: {
      type: Object,
    },
  },
};
</script>
<style scoped>
.customBtn {
  margin: 5px;
}
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
</style>