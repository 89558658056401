<template>
  <div class="widget-container">
    <h2 class="content-block">
      {{
        getDocumentStatus == "Add" || getDocumentStatus == "Redirect"
          ? "Membuat"
          : "Edit"
      }}
      Dokumen
    </h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="this.$router.back()"
    />
    <div class="content-block dx-card responsive-paddings">
      <form-document @get-document="handleSubmit"></form-document>
    </div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import { mapGetters, mapActions } from "vuex";

import notify from "devextreme/ui/notify";
import router from "@/router";

import formDocument from "@/components/documentManagement/formDocument.vue";

export default {
  components: {
    DxButton,
    formDocument,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("document", ["getDocumentStatus"]),
  },
  methods: {
    async handleSubmit(value) {
      if (
        this.getDocumentStatus == "Add" ||
        this.getDocumentStatus == "Redirect"
      ) {
        this.$store
          .dispatch("document/postDocument", value)
          .then((res) => {
            this.$store.dispatch("docFile/setDocumentFileStatus", "Add");
            this.$store.dispatch("document/setDocument", res.data.data);
            notify("berhasil", "success", 2000);
            router.push(`/admin/doc-file/add`);
          })
          .catch((e) => {
            if (e.response.status == 404) {
              notify(
                "Kode unit bisnis / department tidak ditemukan",
                "error",
                2000
              );
              router.push(`/admin/code/info`);
              return;
            }
            console.log(e);
          });
      } else if (this.getDocumentStatus == "Edit") {
        this.$store
          .dispatch("document/updateDocument", value)
          .then((res) => {
            notify("berhasil", "success", 2000);
            router.push(
              `/admin/document/${value.document_type.replace(/\s+/g, "-")}`
            );
          })
          .catch((e) => {
            notify("berhasil", "error", 2000);
            console.log(e);
            return;
          });
      } else {
        console.log("error");
        return;
      }
    },
  },
};
</script>